import React from 'react'
import { Tooltip, Box, IconButton } from '@material-ui/core'
import DownArrow from '@material-ui/icons/KeyboardArrowDownRounded'
import UpArrow from '@material-ui/icons/KeyboardArrowUpRounded'

export const HeaderGridCell = props => {
  const tableCellStyles = { ...props.cellStyles, overflow: 'hidden' }
  return (
    <Box key={props.column.id} sx={tableCellStyles}>
      <div
        style={{
          ...props.column.styles,
          display: 'flex',
          alignItems: 'center'
        }}
        onClick={props.handleSort}
        data-cy={`header-${props['data-cy']}`}
      >
        <Tooltip title={props.column.label}>
          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '1rem' }}>
            {props.column.label}
          </div>
        </Tooltip>{' '}
        {props.columnToSort === props.column.id ? (
          <Tooltip title="Sort">
            <IconButton size="small">{props.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />}</IconButton>
          </Tooltip>
        ) : null}
      </div>
    </Box>
  )
}
