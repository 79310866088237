import React, { useState } from 'react'
import NewsletterArticles from '../create/lists/newsletter-articles.component'

const ArticleCard = ({
  currentSection,
  articles,
  setArticles,
  setDraggingArticle,
  setSections,
  setUnsavedChanges,
  isReadOnly
}) => {
  const [draggedArticle, setDraggedArticle] = useState()

  const removeArticleFromNewsletter = article => {
    const articleList = articles
    articleList.splice(articleList.indexOf(article), 1)
    setArticles(articleList)
    setSections([...new Set(articleList.map(a => a.section))])
    setUnsavedChanges(true)
  }

  const articleDragStart = (articleDivToDrag, index) => {
    setDraggingArticle(true)
    setDraggedArticle(articles[index])
    articleDivToDrag.dataTransfer.effectAllowed = 'move'
    articleDivToDrag.dataTransfer.setData('text/html', articleDivToDrag.target.parentNode)
    articleDivToDrag.dataTransfer.setDragImage(articleDivToDrag.target.parentNode, 20, 20)
  }

  const articleDragOver = index => {
    const draggedOverArticle = articles[index]
    let articlesArray = articles

    if (
      draggedArticle !== undefined &&
      draggedArticle !== draggedOverArticle &&
      draggedArticle.section === draggedOverArticle.section
    ) {
      articlesArray = articlesArray.filter(currentArticle => currentArticle._id !== draggedArticle._id)
      articlesArray.splice(index, 0, draggedArticle)
      setArticles(articlesArray)
      setUnsavedChanges(true)
    }
  }

  const articleDragEnd = () => {
    setDraggedArticle()
    setDraggingArticle(false)
  }

  return articles.map((currentArticle, index) => {
    if (currentArticle) {
      if (currentArticle.section === currentSection) {
        return (
          <div key={currentArticle._id} onDragOver={() => articleDragOver(index)}>
            <div
              className="drag-article"
              draggable
              onDragStart={articleDivToDrag => articleDragStart(articleDivToDrag, index)}
              onDragEnd={articleDragEnd}
            >
              {/* if the isReadOnly prop does not exist, pass it to NewsletterArticles. otherwise, pass it */}
              <div>
              {!isReadOnly ? (
                <NewsletterArticles
                  index={index}
                  eventKey={`article${index}`}
                  article={currentArticle}
                  removeArticleFromNewsletter={removeArticleFromNewsletter}
                />
              ) : (
                <NewsletterArticles
                  index={index}
                  eventKey={`article${index}`}
                  article={currentArticle}
                  removeArticleFromNewsletter={removeArticleFromNewsletter}
                  isReadOnly = {isReadOnly}
                />
              )}
              </div>
            </div>
          </div>
        )
      }
    }
    return null
  })
}

export default ArticleCard
