import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Paper, { PaperProps } from '@material-ui/core/Paper'
import styled from 'styled-components'
import Draggable from 'react-draggable'
import { useConfirmDialog } from 'context/confirm-dialog-context'

const Title = styled(DialogTitle)`
  cursor: move;
`

const PaperComponent = (props: PaperProps) => (
  <Draggable handle="#dialog-title" cancel="[class*='MuiDialogContent-root']">
    <Paper {...props} />
  </Draggable>
)

const ConfirmDialog = () => {
  const { openConfirmDialog, confirmDialogSettings } = useConfirmDialog()

  return (
    <Dialog
      open={openConfirmDialog}
      onClose={confirmDialogSettings.handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="dialog-title"
      data-cy="confirmDialog"
    >
      <Title id="dialog-title">{confirmDialogSettings.title}</Title>
      <DialogContent>
        <DialogContentText>{confirmDialogSettings.text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={confirmDialogSettings.handleClose} data-cy="confirmDialogCancel">
          {confirmDialogSettings.cancelText || 'Cancel'}
        </Button>
        <Button onClick={confirmDialogSettings.handleConfirm} data-cy="confirmDialogSubmit">
          {confirmDialogSettings.submitText || 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
