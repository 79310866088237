import React from 'react'
import { Jumbotron, Subtitle, Title } from './CoverImageStyles'

export const CoverImage = () => {
  return (
    <Jumbotron>
      <Title>
        What's <em> your </em> DNA?
      </Title>
      <Subtitle>Explore a variety of articles and newsletters.</Subtitle>
    </Jumbotron>
  )
}
