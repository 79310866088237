import React from 'react'
import { ButtonProps } from '@material-ui/core/Button'
import {
  Arrow,
  ArrowIcon,
  ButtonBase,
  ButtonContent,
  ButtonText,
  ButtonIcon,
  ButtonSubtitle,
  ButtonTitle
} from './CTAButtonStyles'

interface ActionButtonProps extends ButtonProps {
  subtitle?: string
  icon?: JSX.Element
}

export const CTAButton = ({ icon, subtitle, title, size, ...rest }: ActionButtonProps): JSX.Element => (
  <ButtonBase className="cta-btn" {...{ ...rest, size }}>
    <ButtonContent className="cta-btn-content">
      <ButtonIcon className="cta-btn-icon">{icon}</ButtonIcon>
      <ButtonText className="cta-btn-text" hasIcon={!!icon}>
        <ButtonTitle size={size}>{title}</ButtonTitle>
        <ButtonSubtitle size={size}>{subtitle}</ButtonSubtitle>
      </ButtonText>
    </ButtonContent>
    <ArrowIcon>
      <Arrow />
    </ArrowIcon>
  </ButtonBase>
)
