import React, { useState, useEffect, useCallback, useRef, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../FontStyle.css'
import { UserContext } from 'context/user-context'
import {
  AppBar,
  Toolbar,
  makeStyles,
  useTheme,
  IconButton,
  Typography,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem
} from '@material-ui/core'
import { AccountCircle, ChevronLeft, ChevronRight } from '@material-ui/icons'
import MenuIcon from '@material-ui/icons/Menu'
import clsx from 'clsx'
import { ROLES } from '../../consts'

require('dotenv').config()

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  qaRoot: {
    backgroundColor: '#e73e1c',
    display: 'flex'
  },
  testRoot: {
    backgroundColor: '#b41ce7',
    display: 'flex'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    backgroundColor: '#538b3f'
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: 'white'
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  leftGroup: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0
  },
  accountButton: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end'
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}))

export const NavbarComponent = () => {
  const { user, isAuthenticated, logout } = useContext(UserContext)
  const history = useHistory()
  // eslint-disable-next-line no-unused-vars
  const [{ isAdmin, isUser }, setRole] = useState({
    isAdmin: false,
    isUser: false
  })

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [anchorElement, setAnchorElement] = useState(null)
  const classes = useStyles()
  const theme = useTheme()
  const isMenuOpen = Boolean(anchorElement)

  const handleProfileMenuOpen = event => {
    setAnchorElement(event.currentTarget)
  }

  const handleProfileMenuClose = () => {
    setAnchorElement(null)
  }

  const checkRole = useCallback(() => {
    setRole({
      isAdmin: user?.role === ROLES.ADMIN,
      isUser: user?.role === ROLES.USER
    })
  }, [user])

  useEffect(() => {
    checkRole()
  }, [checkRole])

  const ref = useRef()

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (drawerOpen && ref.current && !ref.current.contains(e.target)) {
        setDrawerOpen(false)
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [drawerOpen])

  let navBarEnv = ''

  if (process.env.REACT_APP_ENV === 'qa') {
    navBarEnv = 'QA Environment'
  }

  if (process.env.REACT_APP_ENV === 'dev') {
    navBarEnv = 'DEV Environment'
  }

  const determineNavBarStyle = () => {
    if (navBarEnv === 'QA Environment') {
      return classes.qaRoot
    }
    if (navBarEnv === 'DEV Environment') {
      return classes.testRoot
    }
    return classes.appBar
  }

  const renderMenu = (
    <Menu
      anchorEl={anchorElement}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleProfileMenuClose}
    >
      <MenuItem disabled>
        <Typography variant="inherit" noWrap>
          User: {user.name}
        </Typography>
      </MenuItem>
      <MenuItem
        onClick={() => {
          logout()
          handleProfileMenuClose()
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  )

  return (
    <div className={classes.root} ref={ref}>
      <AppBar
        position="fixed"
        className={clsx(determineNavBarStyle(), {
          [classes.appBarShift]: drawerOpen
        })}
      >
        <Toolbar>
          <div className={classes.leftGroup}>
            {isAuthenticated ? (
              <IconButton
                data-cy="navbar-menu"
                onClick={() => setDrawerOpen(oldState => !oldState)}
                edge="start"
                className={clsx(classes.menuButton, drawerOpen && classes.hide)}
              >
                <MenuIcon />
              </IconButton>
            ) : null}
            <Link className="navbar-brand" to="/">
              <img src={require('../../Daugherty_Newsletter_Logo-02.png')} id="logo" alt="Daugherty Newsletter Logo" />
            </Link>
            <Typography variant="h5">{navBarEnv}</Typography>
          </div>
          <div className={classes.accountButton}>
            {isAuthenticated ? (
              <IconButton onClick={handleProfileMenuOpen} style={{ color: 'white' }} edge="end">
                <AccountCircle />
              </IconButton>
            ) : null}
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        data-cy="nav-drawer"
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={drawerOpen}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton data-cy="drawer-button" onClick={() => setDrawerOpen(oldState => !oldState)}>
            {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </div>
        <Divider />
        <List component="nav">
          <ListItem
            button
            component="a"
            onClick={() => {
              history.push('/')
              setDrawerOpen(oldState => !oldState)
            }}
          >
            <ListItemText style={{ color: 'black' }}>Home</ListItemText>
          </ListItem>
          <ListItem style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
            <ListItemText>Articles</ListItemText>
            <List component="nav" style={{ width: '100%' }}>
              <ListItem
                style={{ color: 'rgba(0, 0, 0, 0.87)' }}
                className={classes.nested}
                button
                component="a"
                onClick={() => {
                  history.push('/myarticles')
                  setDrawerOpen(oldState => !oldState)
                }}
              >
                <Typography variant="body1">My Articles</Typography>
              </ListItem>
              {isUser || isAdmin ? (
                <ListItem
                  style={{ color: 'rgba(0, 0, 0, 0.87)' }}
                  className={classes.nested}
                  button
                  component="a"
                  onClick={() => {
                    history.push('/allarticles')
                    setDrawerOpen(oldState => !oldState)
                  }}
                >
                  <Typography variant="body1">All Articles</Typography>
                </ListItem>
              ) : null}
              <ListItem
                style={{ color: 'rgba(0, 0, 0, 0.87)' }}
                className={classes.nested}
                button
                component="a"
                onClick={() => {
                  history.push('/create')
                  setDrawerOpen(oldState => !oldState)
                }}
              >
                <Typography variant="body1">Create Article</Typography>
              </ListItem>
            </List>
          </ListItem>
          {isUser || isAdmin ? (
            <ListItem style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
              <ListItemText>Newsletters</ListItemText>
              <List component="nav" style={{ width: '100%' }}>
                <ListItem
                  style={{ color: 'rgba(0, 0, 0, 0.87)' }}
                  className={classes.nested}
                  button
                  component="a"
                  onClick={() => {
                    history.push('/mynewsletters')
                    setDrawerOpen(oldState => !oldState)
                  }}
                >
                  <Typography variant="body1">My Newsletters</Typography>
                </ListItem>
                <ListItem
                  style={{ color: 'rgba(0, 0, 0, 0.87)' }}
                  className={classes.nested}
                  button
                  component="a"
                  onClick={() => {
                    history.push('/allnewsletters')
                    setDrawerOpen(oldState => !oldState)
                  }}
                >
                  <Typography variant="body1">All Newsletters</Typography>
                </ListItem>
                <ListItem
                  style={{ color: 'rgba(0, 0, 0, 0.87)' }}
                  className={classes.nested}
                  button
                  component="a"
                  onClick={() => {
                    history.push('/createnewsletter')
                    setDrawerOpen(oldState => !oldState)
                  }}
                >
                  <Typography variant="body1">Create Newsletter</Typography>
                </ListItem>
                <ListItem
                  style={{ color: 'rgba(0, 0, 0, 0.87)' }}
                  className={classes.nested}
                  button
                  component="a"
                  onClick={() => {
                    history.push('/headerandfooter')
                    setDrawerOpen(oldState => !oldState)
                  }}
                >
                  <Typography variant="body1">Header and Footer</Typography>
                </ListItem>
              </List>
            </ListItem>
          ) : null}
          <ListItem
            button
            component="a"
            href="https://daugherty.atlassian.net/wiki/spaces/NDT/pages/3111256114/User+Guide"
            target="_blank"
            rel="noopener"
            onClick={() => {
              setDrawerOpen(oldState => !oldState)
            }}
          >
            <ListItemText style={{ color: 'black' }}>User Guide</ListItemText>
          </ListItem>
          {isAdmin ? (
            <ListItem
              button
              component="a"
              onClick={() => {
                history.push('/admin')
                setDrawerOpen(oldState => !oldState)
              }}
            >
              <ListItemText style={{ color: 'black' }}>Admin</ListItemText>
            </ListItem>
          ) : null}
          <ListItem
            button
            component="a"
            href="mailto:newsletter.feedback@daugherty.com?subject=Newsletter Feedback"
            onClick={() => {
              setDrawerOpen(oldState => !oldState)
            }}
          >
            <ListItemText style={{ color: 'black' }}>Feedback</ListItemText>
          </ListItem>
        </List>
      </Drawer>
      {renderMenu}
    </div>
  )
}

export default NavbarComponent
