import { ARTICLE_STATUSES } from 'consts'
import React from 'react'
import { Button } from 'react-bootstrap'

type SaveControlsProps = {
  isSubmitting: boolean
  togglePreviewModal: () => void
  autoSave: boolean
  status: string
  handleDelete: () => void
  handleCancel: () => void
}

const SaveControls = ({
  isSubmitting,
  togglePreviewModal,
  status,
  autoSave,
  handleCancel,
  handleDelete
}: SaveControlsProps): JSX.Element => {
  return (
    <div className="button-container justify-content-end">
      <Button
        disabled={isSubmitting}
        onClick={togglePreviewModal}
        className="btn article-preview-display"
        id="preview-article"
      >
        Preview
      </Button>
      {status !== ARTICLE_STATUSES.PUBLISHED && (
        <Button id="save-update-btn" disabled={isSubmitting} type="submit" className="btn-success">
          {autoSave ? 'Save' : 'Update'}
        </Button>
      )}
      {handleDelete && (
        <Button disabled={isSubmitting} type="button" onClick={handleDelete} className="btn-danger">
          Delete
        </Button>
      )}
      <Button disabled={isSubmitting} onClick={handleCancel}>
        Cancel
      </Button>
    </div>
  )
}

export default SaveControls
