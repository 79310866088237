import React, { useState, useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import { ThemeProvider } from '@material-ui/core'
import TablePagination from '@material-ui/core/TablePagination'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Row } from 'react-bootstrap'
import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon from '@material-ui/icons/Delete'

import { GridBody, GridCell, GridRow, HeaderGridCell, HeaderGridRow } from 'components/Grid'
import { theme } from '../../../utils/themeSettings'
import { getTagList, createTag, updateTagReferences } from 'apis/tag.service'
import TagDialog from '../create/tag-dialog.component'
import { Tag } from '../../../models/tag'
import toaster from 'components/toast/toast.component'
import { useError } from 'context/error-context'
import { deleteTagById, updateTag } from './../../../apis/tag.service'
import { useConfirmDialog } from 'context/confirm-dialog-context'
import { TAGS } from 'consts'

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: 440
  },
  header: {
    textAlign: 'center',
    paddingTop: '1%',
    color: '#000'
  },
  arrow: {
    fontSize: '17px',
    color: 'gray'
  }
})
/**
 * Copmonent to represent grid of all tags from database
 * @param {Object[]} - array of tags
 * @param {String} columnToSort - column name to order
 * @param {String} sortDirection - 'asc' or 'desk'
 * @param {Number} page - current page to render
 * @param {Number} rowsPerPage   - quantity of rows per page
 * @param {Number} count - total count of tags
 * @param {string} filter - string to filter in format '[column name]:[sort direction]'
 * @param {Boolean} openTagDialog - indicates if dialog to add tag is open
 * @param {Boolean} refresh - forces table of tags to render
 * @param {Boolean} currentTag - the tag we are going to edit
 */
const TagsGrid = () => {
  const classes = useStyles()
  const { setError } = useError()
  /** Definition of columns to be rendered in the tags table */
  const [sortedTags, setSortedTags] = useState([])
  const [columnToSort, setColumnToSort] = useState('name')
  const [sortDirection, setSortDirection] = useState('asc')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [count, setCount] = useState(0)
  const [filter, setFilter] = useState('')
  const [openTagDialog, setOpenTagDialog] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [currentTag, setCurrentTag] = useState(null)
  const { setConfirmDialogSettings, setOpenConfirmDialog } = useConfirmDialog()
  /** Delete tag action handler  */
  const verifyDelete = async tag => {
    try {
      await deleteTagById(tag._id)
      setRefresh(!refresh)
      setFilter('')
    } catch (err) {
      toaster('ERROR', `${err && err.response && err.response.data ? err.response.data : 'Something went wrong!'}`)
    }
  }
  /** Update tag with refences action handler  */
  const verifyUpdate = async tag => {
    try {
      await updateTagReferences(tag)
      setRefresh(!refresh)
    } catch (err) {
      setError(err)
    }
  }
  /** To run confirm dialog before delete  */
  const deleteTag = tag => {
    setConfirmDialogSettings({
      handleClose: () => {
        setOpenConfirmDialog(false)
      },
      handleConfirm: async () => {
        await verifyDelete(tag)
        setOpenConfirmDialog(false)
      },
      text: 'You are going to delete tag! Are you sure?',
      title: 'Delete tag'
    })
    setOpenConfirmDialog(true)
  }
  /** Open dialog to edit tag */
  const startEdit = tag => {
    setCurrentTag(tag)
    setOpenTagDialog(true)
  }
  const columns = [
    {
      id: 'name',
      label: 'Name',
      styles: { textAlign: 'left' },
      content: tag => tag.name,
      tooltip: tag => tag.name
    },
    {
      id: 'type',
      label: 'Type',
      styles: { textAlign: 'left' },
      content: tag => TAGS[tag.type] || '',
      tooltip: tag => (tag.type ? tag.type : '')
    },
    {
      id: 'edit',
      label: '',
      styles: { textAlign: 'right' },
      icon: () => <CreateIcon />,
      tooltip: 'Edit this tag',
      clickHandler: startEdit
    },
    {
      id: 'delete',
      label: '',
      styles: { textAlign: 'right' },
      icon: () => <DeleteIcon />,
      tooltip: 'Delete this tag',
      clickHandler: deleteTag,
      format: value => value.toFixed(2)
    }
  ]

  const tableRowStyles = {
    flexGrow: 1,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr) repeat(2, 50px)',
    minHeight: 73,
    alignItems: 'center',
    fontSize: '0.875em',
    fontWeight: 500
  }

  /** Style of selected row */
  const tableSelectedRowStyles = {
    ...tableRowStyles,
    backgroundColor: '#ddf1d6',
    '&:hover': {
      backgroundColor: '#d3f0c8'
    }
  }

  const tableCellStyles = { padding: 16 }

  const invertDirection = {
    asc: 'desc',
    desc: 'asc'
  }

  useEffect(() => {
    /**Gets all tags from database  */
    async function getTags() {
      try {
        const response = await getTagList({
          page,
          limit: rowsPerPage,
          sort: `${columnToSort}:${sortDirection}`,
          filter
        })
        setCount(response.data.totalCount)
        setSortedTags(response.data.tagList)
      } catch (err) {
        setError(err)
      }
    }
    getTags()
  }, [page, rowsPerPage, columnToSort, filter, sortDirection, refresh, setError])

  /**Change page handler for pagination */
  const handleChangePage = (_, newPage) => {
    setPage(newPage)
  }

  /**Change row count per page handler for pagination */
  const handleChangeRowsPerPage = e => {
    setRowsPerPage(e.target.value)
    setPage(0)
  }

  /**Handler for click on column to sort */
  const handleTagsSort = column => {
    const columnName = column.id
    setColumnToSort(columnName)
    const newSortDirection = columnToSort === columnName ? invertDirection[sortDirection] : 'asc'
    setSortDirection(newSortDirection)
  }

  /**Handler to add new/update tag  */
  const handleTag = async (tag, dialogType) => {
    if (!tag.name) {
      toaster('ERROR', 'Name cannot be empty')
      return
    }
    if (!tag.type) {
      toaster('ERROR', 'Type cannot be empty')
      return
    }
    try {
      if (dialogType === 'add') {
        await createTag(new Tag({ name: tag.name, type: tag.type }))
        setRefresh(!refresh)
      } else {
        const { status, message } = (await updateTag(new Tag(tag))).data
        if (status === 'confirm') {
          setConfirmDialogSettings({
            handleClose: () => {
              setOpenConfirmDialog(false)
            },
            handleConfirm: async () => {
              try {
                await verifyUpdate(tag)
              } catch (err) {
                setError(err)
              }
              setOpenConfirmDialog(false)
            },
            text: message,
            title: 'Confirm tag update'
          })
          setOpenConfirmDialog(true)
        } else {
          setRefresh(!refresh)
        }
      }
      setOpenTagDialog(false)
    } catch (err) {
      setError(err)
    }
  }

  return (
    <div className="admin-table" data-cy="tagsTable">
      <h1 className={classes.header}>Tag List</h1>
      <Row>
        <div className="col-auto mb-2">
          <h5>Filter</h5>
          <input
            value={filter}
            data-cy="tagsFilterInput"
            onChange={e => setFilter(e.target.value)}
            className="form-control"
          />
        </div>
      </Row>
      <hr />
      <ThemeProvider theme={theme}>
        <Paper>
          <HeaderGridRow tableRowStyles={tableRowStyles}>
            {columns.map(column => (
              <HeaderGridCell
                column={column}
                key={column.id}
                handleSort={() => handleTagsSort(column)}
                columnToSort={columnToSort}
                sortDirection={sortDirection}
                cellStyles={tableCellStyles}
              />
            ))}
          </HeaderGridRow>
          <GridBody data-cy="tagsTable">
            {sortedTags.map(tag => {
              return (
                <GridRow
                  data-cy="tagsRow"
                  tableRowStyles={tag.selected ? tableSelectedRowStyles : tableRowStyles}
                  key={tag._id}
                  id={tag.title}
                >
                  {columns.map(column => {
                    return (
                      <GridCell
                        key={column.id}
                        column={column}
                        clickHandler={column.clickHandler ? () => column.clickHandler(tag) : null}
                        content={column.content ? () => column.content(tag) : () => null}
                        tooltip={typeof column.tooltip === 'function' ? column.tooltip(tag) : column.tooltip}
                        cellStyles={tableCellStyles}
                        iconSize="medium"
                      />
                    )
                  })}
                </GridRow>
              )
            })}
          </GridBody>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            id="tagsTablePagination"
            labelRowsPerPage="Rows per page:"
          />
        </Paper>
        <div className="add-user-button">
          <Button
            value="button"
            variant="primary"
            onClick={() => {
              setCurrentTag(null)
              setOpenTagDialog(true)
            }}
          >
            Add New Tag
          </Button>
        </div>
        {openTagDialog && (
          <TagDialog
            open={openTagDialog}
            handleClose={(event, reason) => {
              if (reason && reason === 'backdropClick') return
              setOpenTagDialog(false)
            }}
            handleTag={handleTag}
            currentTag={currentTag}
          />
        )}
      </ThemeProvider>
    </div>
  )
}

export default TagsGrid
