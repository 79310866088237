import React, { useEffect, useState, useRef } from 'react'
import { Modal } from 'react-bootstrap'
import ArticleForm from '../form/article-form.component'
import './update-article-modal.component.css'
import '../../FontStyle.css'
import { deleteArticle, getArticle } from 'apis/article.service'
import { useConfirmDialog } from 'context/confirm-dialog-context'
import { useError } from 'context/error-context'
import { Backdrop } from '../../backdrop'
import { Article } from 'models/article'
/**
 * Displays create/edit article form in a popup
 *
 * Uses components:
 *    Modal - to create a modal
 *    ArticleForm - to provide functionality for editing article
 *
 * Its state variables are:
 *    @property {Boolean} isSubmitting - whether data is being submitted to the backend. If true, buttons in ArticleForm are readonly
 */

type UpdateArticleProps = {
  showAll: boolean
  articleId: string
  handleUpdate: () => void
  handleCancel: () => void
  setActionOnArticle: (args: any) => void
}

const UpdateArticleModal = ({
  articleId,
  showAll,
  handleUpdate,
  setActionOnArticle
}: UpdateArticleProps): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { setConfirmDialogSettings, setOpenConfirmDialog } = useConfirmDialog()
  const [article, setArticle] = useState(null)
  const [loading, setLoading] = useState(false)
  const { setError } = useError()
  const defaultArticle = useRef<Article>()

  useEffect(() => {
    let isMounted = true
    setLoading(true)
    getArticle(articleId)
      .then(({ data }) => {
        if (isMounted) {
          setArticle(data)
          defaultArticle.current = data
        }
      })
      .catch(err => {
        setError(err)
        setLoading(false)
      })
      .finally(() => {
        if (isMounted) {
          setLoading(false)
        }
      })
    return () => {
      isMounted = false
    }
  }, [articleId, setError])

  const handleSubmit = () => {
    handleUpdate()
    setIsSubmitting(false)
  }

  const cancelInterceptor = () => {
    if (!isSubmitting) {
      setActionOnArticle(null)
    }
  }

  const handleDelete = () => {
    setConfirmDialogSettings({
      handleClose: () => {
        setOpenConfirmDialog(false)
      },
      handleConfirm: async () => {
        setOpenConfirmDialog(false)
        await deleteArticle(articleId)
        handleUpdate()
      },
      title: 'Delete article',
      text: 'Are you sure you want to delete this article'
    })
    setOpenConfirmDialog(true)
  }
  if (loading) return <Backdrop open={loading} />

  return (
    <Modal
      show={!!article}
      onHide={cancelInterceptor}
      enforceFocus={false}
      dialogClassName="modal-90w"
      backdrop="static"
    >
      <Modal.Header>Edit Article</Modal.Header>
      <Modal.Body>
        <ArticleForm
          autoSave={false}
          handleDelete={handleDelete}
          defaultArticle={defaultArticle.current}
          handleSubmit={handleSubmit}
          handleCancel={cancelInterceptor}
          isSubmitting={isSubmitting}
          article={article}
          setArticle={setArticle}
          showAll={showAll}
          setNewArticleCreated={null}
        />
      </Modal.Body>
    </Modal>
  )
}

export default UpdateArticleModal
