import styled from 'styled-components'

export const PageContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  background-color: whitesmoke;
  padding-bottom: 40px;
`
