export interface ArticleProps {
  _id: string
  tags: string[]
  imgs: string[]
  section: string
  title: string
  content: string
  start: string
  end: string
  position: number
  owner: string
  videoObj: Video[]
  status: string
  createdOn: Date
  modifiedBy: string
  modifiedOn: Date
}

export interface Video {
  videoId: string
  videoLink: string
  videoThumbnail: string
  videoTitle: string
}

export class Article implements ArticleProps {
  _id: string
  tags: string[]
  imgs: string[]
  section: string
  title: string
  content: string
  start: string
  end: string
  position: number
  owner: string
  videoObj: Video[]
  status: string
  createdOn: Date
  modifiedBy: string
  modifiedOn: Date

  constructor(props: Partial<ArticleProps>) {
    this._id = props?._id ?? undefined
    this.tags = props?.tags ?? []
    this.imgs = props?.imgs ?? []
    this.section = props?.section ?? ''
    this.title = props?.title ?? ''
    this.content = props?.content ?? ''
    this.start = props?.start ?? ''
    this.end = props?.end ?? ''
    this.position = props?.position ?? 0
    this.owner = props?.owner ?? ''
    this.videoObj = props?.videoObj ?? []
    this.status = props?.status ?? ''
    this.createdOn = props?.createdOn ?? new Date()
    this.modifiedBy = props?.modifiedBy ?? ''
    this.modifiedOn = props?.modifiedOn ?? new Date(Date.now())
  }
}
