import map from 'lodash/map'
import React from 'react'
import AsyncSelect from 'react-select/async'
import PropTypes from 'prop-types'

import { filterAndMapToSelectOptions } from 'utils/mapStringsToSelectOptions'
import { getTags } from 'apis/article.service'
import { useError } from 'context/error-context'

type TagProps = {
  handleChange: (args: any) => void
  tags: { value: any; label: any }[]
}

/** Represents tag section */
const Tag = ({ handleChange, tags }: TagProps): JSX.Element => {
  const { setError } = useError()
  /** Function is asynchronously getting tags from database */
  const loadTags = async inputValue => {
    try {
      const response = await getTags()
      const options = filterAndMapToSelectOptions(response.data, inputValue)
      return options
    } catch (err) {
      setError(err)
      return null
    }
  }

  return (
    <div className="form-group">
      <label>Tags:</label>
      <AsyncSelect
        cacheOptions
        defaultOptions
        loadOptions={loadTags}
        isMulti
        onChange={(e: any) => handleChange((prevState: any) => ({ ...prevState, tags: map(e, _ => _.value) }))}
        value={tags}
        id="tags"
      />
    </div>
  )
}

Tag.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.object)
}

export default Tag
