import styled from 'styled-components'

export const ArticleEditorWrapper = styled.div`
  background-color: #fff;
  margin: 20px;
  border-radius: 14px;
  overflow: auto;
  && [data-type='text'] {
    position: relative;
  }
  && [data-type='text']:hover {
    outline: 2px solid #ccc;
    cursor: text;
  }
  && [data-type='section'] {
    cursor: pointer;
    position: relative;
  }
  && [data-type='section']:hover:after {
    content: 'Click to edit this section';
    position: absolute;
    text-align: center;
    font-size: 17px;
    font-family: 'Montserrat';
    padding: 3px;
    bottom: 0px;
    left: -2px;
    background-color: #dfdfdf;
    color: #548c40;
    cursor: pointer;
  }
  && [data-type='section']:hover:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: 2px solid #b3e06e;
  }
`

export const EditorHeader = styled.div`
  font-family: Montserrat;
  background-color: #b3e06e;
  font-size: 16px;
  line-height: 20px;
  color: #404f54;
  padding: 16px 24px;
`

export const EditorContent = styled.object`
  display: flex;
  flex-direction: row;
`

export const Editor = styled.div`
  flex: 3;
  display: flex;
  justify-content: center;
  min-height: 1000px;
  background: radial-gradient(#b0b0b0, white);
`

export const Preview = styled.div`
  min-width: 600px;
  max-width: 600px;
  overflow-x: hidden;
  background-color: #fff;
`

export const ToolSetWrapper = styled.div`
  flex: 1;
  background-color: #eee;
`