export const dateFormat = initialDate => {
  const date = new Date(initialDate)
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  const year = String(date.getFullYear()).substr(2, 2)
  const hour24 = date.getHours()
  const hour = hour24 === 12 || hour24 === 0 ? String(12) : String(hour24 % 12).padStart(2, '0')
  const minute = String(date.getMinutes()).padStart(2, '0')
  const dateStr = `${month}/${day}/${year} ${hour}:${minute}${hour24 >= 12 ? 'pm' : 'am'}`
  return dateStr
}

function convertArrayOfObjectsToCSV(args) {
  let result, ctr

  const data = args.data || null
  if (data === null || !data.length) {
    return null
  }

  const columnDelimiter = args.columnDelimiter || ','
  const lineDelimiter = args.lineDelimiter || '\n'

  const keys = Object.keys(data[0])

  result = ''
  result += keys.join(columnDelimiter)
  result += lineDelimiter

  data.forEach(item => {
    ctr = 0
    keys.forEach(key => {
      if (ctr > 0) result += columnDelimiter

      result += item[key]
      ctr++
    })
    result += lineDelimiter
  })

  return result
}

export function downloadCSV(args) {
  let csv = convertArrayOfObjectsToCSV({
    data: args.data
  })
  if (csv === null) return

  const filename = args.filename || 'export.csv'

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`
  }
  const data = encodeURI(csv)
  const link = document.createElement('a')
  link.setAttribute('href', data)
  link.setAttribute('download', filename)
  link.click()
}

export const capitalizeEveryWord = (text = '') => text.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
