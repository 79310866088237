import React, { useState } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import CustomToggle from 'components/newsletter/edit/custom-toggle.component'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import ArticleComponent from 'components/article/article.component'

const NewsletterArticles = ({ eventKey, article, removeArticleFromNewsletter, index, isReadOnly }) => {
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false)

  return (
    <>
      <br />
      <Accordion data-cy="article-card-accordion">
        <Card className="draftArticles-card">
          <CustomToggle
            className="text-center"
            eventKey={eventKey}
            onClickHandler={() => setIsAccordionExpanded(prevDirection => !prevDirection)}
          >
            <h5 className="d-inline-block" style={{ fontWeight: 'bold' }} title="Expand/Collapse Article">
              {article.title !== undefined ? article.title : ''}
              {isAccordionExpanded ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
            </h5>
            <div>
              {!isReadOnly && (
                <span className="d-inline-block float-right" tabIndex="0" data-toggle="tooltip" title="Remove Article">
                  <RemoveCircleIcon className="edit-buttons" onClick={() => removeArticleFromNewsletter(article)} />
                </span>
              )}
            </div>
          </CustomToggle>

          <Accordion.Collapse eventKey={eventKey}>
            <Card.Body>
              <ArticleComponent article={article} type="no-header" index={index} />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  )
}

export default NewsletterArticles
