import React from 'react'
import './Footer.css'
import { Link } from '@material-ui/core/'
import { ROLES, MENU_LIST_ITEMS } from '../../../consts'

const { ARTICLES, NEWSLETTERS, ADMIN, GUIDE } = MENU_LIST_ITEMS

export const Footer = ({ user }) => {
  const isPrivilegedUser = user.role !== ROLES.USER
  return (
    <div className="footer-container">
      <div className="footer-content">
        <div className="top-links">
          {isPrivilegedUser && (
            <Link className="footer-link" href={NEWSLETTERS.MY.PATH} color="inherit" underline="hover">
              {NEWSLETTERS.MY.LABEL_NAME}
            </Link>
          )}
          <Link className="footer-link" href={ARTICLES.MY.PATH} color="inherit" underline="hover">
            {ARTICLES.MY.LABEL_NAME}
          </Link>
          <Link className="footer-link" href={GUIDE.PATH} target='_blank' color="inherit" underline="hover">
            {GUIDE.LABEL_NAME}
          </Link>
          {user.role === ROLES.ADMIN && (
            <Link className="footer-link" href={ADMIN.PATH} color="inherit" underline="hover">
              {ADMIN.LABEL_NAME}
            </Link>
          )}
        </div>
        <div className="bottom-links">
          <div>© 2022 All Rights Reserved</div>
        </div>
      </div>
    </div>
  )
}
