import { AxiosPromise } from 'axios'
import { Tag } from 'models/tag'
import { AxiosWithToken } from './axios-helpers'

const TagAxios = AxiosWithToken(`${process.env.REACT_APP_API_URL}/tags`)

export const getTagList = (params: {
  page: number
  limit: number
  sort: string
  filter: string
}): AxiosPromise<Object> =>
  TagAxios.get(`/tagList`, {
    params: {
      page: params && params.page ? params.page : 0,
      limit: params && params.limit ? params.limit : 10,
      sort: params && params.sort ? params.sort : 'name:desc',
      filter: params && params.filter ? params.filter : ''
    }
  })

export const createTag = (tag: Tag): AxiosPromise<Tag> => TagAxios.post('/add', tag)
export const deleteTagById = (tagId: string): AxiosPromise<string> => TagAxios.delete(`/delete/${tagId}`)
export const updateTag = (tag: Tag): AxiosPromise<string | Tag> => TagAxios.patch(`/update/${tag._id}`, tag)
export const updateTagReferences = (tag: Tag): AxiosPromise<string> => TagAxios.patch(`/updateRef/${tag._id}`, tag)
