import { AxiosPromise } from 'axios'
import { Audience } from 'models/audience'
import User from 'models/user'
import { AxiosWithToken } from './axios-helpers'

const AdminAxios = AxiosWithToken(`${process.env.REACT_APP_API_URL}/admin`)

export const getUsers = (ids: string[] = []): AxiosPromise<User[]> => AdminAxios.get(`/users?ids=${ids.join(',')}`)

export const getUserRole = (): AxiosPromise<User> => AdminAxios.get('/userRole')

export const createUser = (user: User): AxiosPromise<string> => AdminAxios.post('/users/add', user)

export const deleteUser = (userId: string): AxiosPromise<string> => AdminAxios.delete(`delete/${userId}`)

export const patchUser = (userId, user): AxiosPromise<User> => AdminAxios.patch(`/update-user/${userId}`, user)

export const getUserByAudienceId = (audienceId: string): AxiosPromise<User[]> =>
  AdminAxios.get(`/audience/${audienceId}`)

export const updateUserAudience = (userId: string, audiences: Audience[]): AxiosPromise<User> =>
  AdminAxios.patch(`/update-user-audience/${userId}`, audiences)
