import React, { useEffect } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import tinymce from 'tinymce/tinymce'
import silver from 'tinymce/themes/silver'
import oxide from 'tinymce/skins/ui/oxide/skin.css'
import { getVideoId } from 'apis/vimeo.service'
import PropTypes from 'prop-types'

/**
 * Component used to display HTML content and allow to edit it with various functionalities like format text, upload images and videos, etc.
 * It uses external dependency - TinyMCE
 * Used to represent content of Articles, Headers and Footers
 */
const RtfEditor = ({ onBlur, onChange, value, height, componentId }) => {
  /** Initialize TinyMCE dependency */
  useEffect(() => {
    tinymce.init({
      selector: '#textarea',
      skin: oxide,
      theme: silver
    })
  }, [])

  /**
   * Used when editor content has changed
   * @param {String} content - content of the editor
   */
  const handleEditorChange = content => {
    onChange(content)
  }

  const handlePrivateVideos = uri => uri.replace('/videos/', '').replace(':', '?h=')

  /**
   * Triggered when object is selected or right after it's added to the content
   * Used to set margins to added images to keep old functionality related to images
   * @param {?} event - DOM object selected in the editor
   */
  const handleObjectSelected = event => {
    const object = event.target

    if (object.tagName === 'IMG') {
      addMarginsToObject(object)
    }
  }

  /**
   * Adds margins to the passed object
   * @param {?} object - DOM object to set margins to
   */
  const addMarginsToObject = object => {
    if (!object.style.marginRight || !object.style.marginBottom) {
      object.style.marginRight = '20px'
      object.style.marginBottom = '20px'
    }
  }

  const disabledUploadMediaMessage = `Uploading videos from your computer is currently disabled. Source must be a direct link to a Vimeo or Youtube video.`

  const mediaPluginConfig = {
    media_live_embeds: true,
    media_alt_source: false,
    media_poster: false
  }

  return (
    <div className="editor">
      <Editor
        init={{
          height: height ? height : 500,
          selector: 'textarea',
          browser_spellcheck: true,
          link_assume_external_targets: 'http',
          menubar: false,
          content_style: 'body { font-family: Calibri; }',
          fontsize_formats: '6pt 8pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 24pt 30pt 36pt',
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'image',
            'charmap',
            'print',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'table',
            'paste',
            'help',
            'wordcount',
            'link',
            'media',
            'table'
          ],
          ...mediaPluginConfig,
          toolbar:
            'undo redo | formatselect | fontsizeselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | link | image | media | help',
          file_picker_callback: function (cb, value, meta) {
            //https://www.tiny.cloud/docs/configure/file-image-upload/#

            const input = document.createElement('input')
            input.setAttribute('type', 'file')

            if (meta.filetype === 'image') {
              input.setAttribute('accept', 'image/*')
            } else {
              input.setAttribute('accept', '*')
            }

            input.onchange = function () {
              const file = this.files[0]
              const reader = new FileReader()
              reader.onload = function () {
                const id = `blobid${new Date().getTime()}`
                const blobCache = tinymce.activeEditor.editorUpload.blobCache
                const base64 = reader.result.split(',')[1]
                const blobInfo = blobCache.create(id, file, base64)

                blobCache.add(blobInfo)

                cb(blobInfo.blobUri(), { title: file.name })
              }
              reader.readAsDataURL(file)
            }

            input.click()
          },
          media_url_resolver: async function (data, resolve, reject) {
            try {
              if (
                data.url.substring(0, 24) === 'https://player.vimeo.com' ||
                data.url.substring(0, 17) === 'https://vimeo.com'
              ) {
                const videoInfo = await getVideoId(data.url)
                const { width, height, title, uri } = videoInfo.data
                const embedHtml = `<iframe src="https://player.vimeo.com/video/${handlePrivateVideos(
                  uri
                )}" width="${width}" height="${height}" frameborder="0" title="${title}" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`
                resolve({ html: embedHtml })
              } else if (/(youtu)/.test(data.url)) {
                const url = new URL(data.url)
                let videoId = url.searchParams.get('v')
                const height = 360
                const width = 640
                if (!videoId) {
                  videoId = data.url.split('/').slice(-1)[0]
                }
                const embedHtml = `<iframe src="https://www.youtube.com/embed/${videoId}" width="${width}" height="${height}" allowfullscreen></iframe>`
                resolve({ html: embedHtml })
              }
              // ELSE -> the file is not a direct link to a Vimeo or Youtube video do the following
              else {
                // IS DEVELOPMENT or QA
                if (process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'qa') {
                  tinymce.activeEditor.notificationManager.open({
                    text: disabledUploadMediaMessage,
                    type: 'error'
                  })
                  // Define the resolver logic and return its payload as html
                  resolve({
                    html: 'This message should be visible only in DEV and QA environments, when failing to upload videos from your computer'
                  })
                } else {
                  // IS PRODUCTION
                  tinymce.activeEditor.notificationManager.open({
                    text: disabledUploadMediaMessage,
                    type: 'error'
                  })
                  resolve({ html: '' })
                }
              }
            } catch (error) {
              reject(error)
            }
          }
        }}
        id={componentId ? `content-${componentId}` : 'content'}
        onEditorChange={handleEditorChange}
        onObjectSelected={handleObjectSelected}
        data={value}
        value={value}
        onBlur={onBlur}
      />
    </div>
  )
}

RtfEditor.propTypes = {
  onBlur: PropTypes.any,
  onChange: PropTypes.func,
  value: PropTypes.string,
  height: PropTypes.number,
  componentId: PropTypes.string
}

export default RtfEditor
