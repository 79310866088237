import { getUserByAudienceId, updateUserAudience } from 'apis/admin.service'
import { getNewslettersByOwnerId, updateNewsletter } from 'apis/newsletter.service'

// will take 'updatedAudience' find any users who own this audience and update user obejct with new audience data.
export const checkForUserDataConsistency = (updatedAudience, callback) => {
  getUserByAudienceId(updatedAudience._id).then(response => {
    response.data.forEach(user => {
      updateUserAudience(user._id, {
        audience: updatedAudience
      }).then(response => {
        checkForNewsletterDataConsistency(response.data)
        if (callback) {
          callback()
        }
      })
    })
  })
}

// will use 'updatedUser' and find any NL with this user as an owner and update NL with data contained in 'updatedUser'
export const checkForNewsletterDataConsistency = updatedUser => {
  const params = {
    ownerId: updatedUser._id
  }

  getNewslettersByOwnerId(params).then(response => {
    const { data } = response.data

    data.forEach(newsletter => {
      updateNewsletter(newsletter._id, {
        owner: updatedUser
      })
    })
  })
}
