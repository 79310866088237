export const SIZE_CONSTANTS = {
  MAX_SIZE_KILOBYTES: 15000,
  MESSAGES: {
    VALID: 'OK',
    NOT_VALID:
      'This article is too big to process. You must delete some images before being able to save it. TIP: Try uploading smaller size images.'
  }
}

export const validateArticleSize = article => {
  const articleSize = new TextEncoder().encode(JSON.stringify(article)).length

  // Calculate size in kilobytes
  const kiloBytes = articleSize / 1024

  if (kiloBytes <= SIZE_CONSTANTS.MAX_SIZE_KILOBYTES) {
    return SIZE_CONSTANTS.MESSAGES.VALID
  }

  // article is too big
  return SIZE_CONSTANTS.MESSAGES.NOT_VALID
}

export const objectsAreEqual = (...objects) =>
  objects.every(element => JSON.stringify(element) === JSON.stringify(objects[0]))
