import React from 'react'
import { Box } from '@material-ui/core'

export const HeaderGridRow = props => {
  return (
    <Box
      sx={{
        ...props.tableRowStyles,
        backgroundColor: '#fafafa',
        borderTop: '1px solid rgba(128, 128, 128, 0.2)'
      }}
    >
      {props.children}
    </Box>
  )
}
