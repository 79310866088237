import React from 'react';
import './ProfileButton.css'
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { UserContext } from 'context/user-context'
import { capitalizeEveryWord } from 'utils/helpers';
import { USER_ROLE_DESCRIPTIONS} from '../../../consts'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        paper: {
            marginRight: theme.spacing(1),
        },
    }),
);

export const ProfileButton = () => {
    // @ts-ignore
    const { user, logout } = React.useContext(UserContext)

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return
        }
        setOpen(false);
    };

    const handleLogout = () => {
        logout()
    }

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div className={classes.root}>
            <div>
                <Button
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    endIcon={<ExpandMoreIcon />}
                >
                    {user ? user.name : 'Loading..'}
                </Button>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement='bottom-end' transition disablePortal className='profile-menu'>
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList autoFocusItem={open} id='menu-list-grow' onKeyDown={handleListKeyDown}>
                                {user ? <MenuItem key='user-role-key'>
                                    <span title={USER_ROLE_DESCRIPTIONS[user.role.toUpperCase()]}>Role: {capitalizeEveryWord(user.role)}</span>
                                </MenuItem> : "Loading.."}
                                <MenuItem key='logout-key' onClick={handleLogout}>Logout</MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Popper>
            </div>
        </div>
    );
}
