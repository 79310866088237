import React from 'react'

const Title = props => {
  return (
    <div className="form-group">
      <label>Title: (Required)</label>
      <br />
      <input
        className="form-control"
        type="text"
        name="title"
        id="title"
        value={props.title}
        required
        onChange={e => {
          e.persist()
          props.handleChange(prevState => ({ ...prevState, title: e.target.value }))
        }}
      />
    </div>
  )
}

export default Title
