import React, { useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import InfoIcon from '@material-ui/icons/Info'
import Tooltip from '@material-ui/core/Tooltip'
import ActiveSwitch from './active-switch.component'
import './../adminComponent.css'

const columns = [
  { id: 'name', label: 'Audience Name', minWidth: 40, align: 'left' },
  { id: 'tags', label: 'Audience Tags', minWidth: 40, align: 'left' },
  {
    id: 'switch',
    label: '',
    align: 'right'
  }
]

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 15
  }
}))(Tooltip)

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: 440
  },
  header: {
    textAlign: 'center',
    paddingTop: '1%'
  }
})

export default function ActiveAudienceTable(props) {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const formatTags = tags => {
    return tags.join('/')
  }

  return (
    <>
      <Paper className={classes.root}>
        <h3 className={classes.header}>
          Inactive Audiences
          <LightTooltip
            title="Previously deactivated audiences. You may reactivate multiple audiences at a time."
            place="right"
            data-multiline="true"
          >
            <InfoIcon fontSize="small" />
          </LightTooltip>
        </h3>

        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.audiences.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(audience => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={audience.name}>
                  {columns.map(column => {
                    const value = audience[column.id]
                    if (column.id === 'switch') {
                      return (
                        <TableCell key={column.id} align={column.align} style={{ width: '20px' }}>
                          <ActiveSwitch
                            value={audience._id}
                            handleSwitchChange={props.handleSwitchChange}
                          ></ActiveSwitch>
                        </TableCell>
                      )
                    } else if (column.id === 'tags') {
                      const tagList = formatTags(value)
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {tagList}
                        </TableCell>
                      )
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={props.audiences.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  )
}
