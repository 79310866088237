import React from 'react'
import { Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import DateFromToFilter from './date-from-to-filter.component'

/**
 * Component used in Newsletters to display filtering section
 * Consist of date type combobox, date from Date field and date to Date field
 */
const DateFilter = props => {
  const styles = {
    filterDateInput: {
      width: '208px'
    }
  }
  return (
    <div className="row align-items-center">
      <Col xs="auto mb-2">
        <h5 className="text-align-center">Filter date</h5>
        <select
          id="date-type"
          data-cy="select-date-type"
          value={props.values.dateType}
          onChange={props.onDateTypeChange}
          style={styles.filterDateInput}
          className="form-control shadow-none"
        >
          <option value=""></option>
          <option value="datePublished">Publish date</option>
          <option value="dateCreated">Date Created</option>
        </select>
      </Col>
      {props.values.dateType !== '' && (
        <DateFromToFilter
          values={props.values}
          onDateFromChange={props.onDateFromChange}
          onDateToChange={props.onDateToChange}
        />
      )}
    </div>
  )
}

/**
 * Prop-types description
 *    values - object containing filter values
 */
DateFilter.propTypes = {
  values: PropTypes.shape({
    dateType: PropTypes.string,
    dateFrom: PropTypes.object,
    dateTo: PropTypes.object
  }),
  onDateTypeChange: PropTypes.func,
  onDateFromChange: PropTypes.func,
  onDateToChange: PropTypes.func
}

export default DateFilter
