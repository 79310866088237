import { AxiosPromise } from 'axios'
import { Audience } from 'models/audience'
import { AxiosWithToken } from './axios-helpers'

const AudienceAxios = AxiosWithToken(`${process.env.REACT_APP_API_URL}/audience`)

export const getAudienceByName = (audienceName: string): AxiosPromise<Audience> =>
  AudienceAxios.get(`/?audienceName=${audienceName}`)

export const getAudienceById = (audienceId: string): AxiosPromise<Audience> => AudienceAxios.get(`/?id=${audienceId}`)

export const deleteAudience = (audienceId: string): AxiosPromise<string> => AudienceAxios.delete(`delete/${audienceId}`)

export const patchAudienceFromAudienceTable = (
  audienceId: string,
  audience: Partial<Audience>
): AxiosPromise<Audience> => AudienceAxios.patch(`/audiencetable/update/${audienceId}`, audience)

export const patchAudience = (audienceId: string, audience: Partial<Audience>): AxiosPromise<Audience> =>
  AudienceAxios.patch(`update/${audienceId}`, audience)

export const getInactiveAudiences = (sort?: { sortBy: string; orderBy: 'asc' | 'desc' }): AxiosPromise<Audience[]> =>
  getAudiences(sort, false)

export const getActiveAudiences = (sort?: { sortBy: string; orderBy: 'asc' | 'desc' }): AxiosPromise<Audience[]> =>
  getAudiences(sort, true)

export const getAudiences = (
  sort?: { sortBy: string; orderBy: 'asc' | 'desc' },
  active?: boolean
): AxiosPromise<Audience[]> => {
  const { sortBy, orderBy } = sort || {}
  const params = {
    active,
    sortBy,
    orderBy
  }
  return AudienceAxios.get('/', { params })
}

export const getActiveAudiencesForNewsletterGrid = (): AxiosPromise<Audience[]> =>
  AudienceAxios.get('/active-audiences')

export const createAudience = (audience: Audience): AxiosPromise<string> => AudienceAxios.post('/add', audience)
