import React, { useCallback, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import './../update-usermodal.component.css'
import { Chip } from '@material-ui/core'
import '../../FontStyle.css'
import { Button } from 'react-bootstrap'
import Checkbox from '@material-ui/core/Checkbox'
import { duplicateName, duplicateTags } from 'utils/audience-validation.js'
import InfoIcon from '@material-ui/icons/Info'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import { getBizTags, getClientTags, getLosTags } from 'apis/article.service'
import { useError } from 'context/error-context'
import { TAGS } from 'consts'

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 15
  }
}))(Tooltip)

const EditAudienceModal = ({ show, setShowModal, audience, handleCloseOnSubmit, audiences }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [selectedAudienceList, setSelectedAudienceList] = useState([])
  const [currentAudienceTags, setCurrentAudienceTags] = useState([])
  const [clientChecked, setClientChecked] = useState([])
  const [bizChecked, setBizChecked] = useState([])
  const [losChecked, setLosChecked] = useState([])

  const { setError } = useError()

  const checkIfLoading = useCallback(() => {
    if (audience.tags === undefined) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [audience.tags])

  const grabCurrentAudience = useCallback(() => {
    if (isLoading) {
      return checkIfLoading()
    }

    if (audience.tags.length > 0) {
      setCurrentAudienceTags(audience.tags)
    }
  }, [isLoading, checkIfLoading, audience.tags])

  useEffect(() => {
    grabCurrentAudience()
    getClientTags().then(response => {
      try {
        const clientOptions = response.data
        setClientChecked(
          clientOptions.map(client => {
            return {
              client: client,
              select: false
            }
          })
        )
      } catch (error) {
        setError(error)
      }
    })
    getBizTags().then(response => {
      try {
        const bizOptions = response.data
        setBizChecked(
          bizOptions.map(bizUnit => {
            return {
              bizUnit: bizUnit,
              select: false
            }
          })
        )
      } catch (error) {
        setError(error)
      }
    })
    getLosTags().then(response => {
      try {
        const losOptions = response.data
        setLosChecked(
          losOptions.map(losTag => {
            return {
              losTag: losTag,
              select: false
            }
          })
        )
      } catch (error) {
        setError(error)
      }
    })
  }, [grabCurrentAudience, setError])

  const handleTagChange = (tagType, tag, e) => {
    if (duplicateTags(tag, currentAudienceTags)) {
      return alert('Can not have duplicate tags')
    }
    if (tagType === 'client') {
      if (e.currentTarget.checked) {
        setSelectedAudienceList(selectedAudienceList.concat(tag.tagList.client))
      } else {
        selectedAudienceList.splice(selectedAudienceList.indexOf(tag.tagList.client), 1)
      }
    } else if (tagType === 'biz') {
      if (e.currentTarget.checked) {
        setSelectedAudienceList(selectedAudienceList.concat(tag.tagList.bizUnit))
      } else {
        selectedAudienceList.splice(selectedAudienceList.indexOf(tag.tagList.bizUnit), 1)
      }
    } else if (tagType === 'los') {
      if (e.currentTarget.checked) {
        setSelectedAudienceList(selectedAudienceList.concat(tag.tagList.losTag))
      } else {
        selectedAudienceList.splice(selectedAudienceList.indexOf(tag.tagList.losTag), 1)
      }
    }
    flipCheckboxSelect(tag)
  }

  const flipCheckboxSelect = tag => {
    if (tag.tagList.client !== undefined) {
      setClientChecked(
        clientChecked.map(client => {
          if (client.client === tag.tagList.client) {
            client.select = !tag.tagList.select
          }
          return client
        })
      )
    } else if (tag.tagList.bizUnit !== undefined) {
      setBizChecked(
        bizChecked.map(bizUnit => {
          if (bizUnit.bizUnit === tag.tagList.bizUnit) {
            bizUnit.select = !tag.tagList.select
          }
          return bizUnit
        })
      )
    } else if (tag.tagList.losTag !== undefined) {
      setLosChecked(
        losChecked.map(losTag => {
          if (losTag.losTag === tag.tagList.losTag) {
            losTag.select = !tag.tagList.select
          }
          return losTag
        })
      )
    }
  }

  const cancelInterceptor = () => {
    setShowModal(false)
    setIsLoading(true)
    setCurrentAudienceTags([])
    setSelectedAudienceList([])
    flipAllCheckboxSelect()
  }

  const flipAllCheckboxSelect = () => {
    setClientChecked(
      clientChecked.map(client => {
        if (client.select) {
          client.select = false
        }
        return client
      })
    )
    setBizChecked(
      bizChecked.map(bizUnit => {
        if (bizUnit.select) {
          bizUnit.select = false
        }
        return bizUnit
      })
    )
    setLosChecked(
      losChecked.map(losTag => {
        if (losTag.select) {
          losTag.select = false
        }
        return losTag
      })
    )
  }

  const renderCurrentUserAudience = () => {
    if (currentAudienceTags.length > 0) {
      return currentAudienceTags.map((tag, index) => (
        <React.Fragment key={index}>
          <Chip className="audience-chip mx-1" label={tag} onDelete={() => handleDelete({ tag })} value={tag} />
        </React.Fragment>
      ))
    }
    return (
      <React.Fragment>
        <p className="audience">No Tags associated to Audience</p>
      </React.Fragment>
    )
  }

  const handleDelete = tags => {
    setCurrentAudienceTags(currentAudienceTags.filter(audienceTag => tags.tag !== audienceTag))
  }

  const handleClose = () => {
    const audienceName = document.getElementById('audienceName').value
    if (currentAudienceTags.length === 0 && selectedAudienceList.length === 0) {
      alert('An audience must have tags. Please select tags.')
    } else if (duplicateName(audienceName, audiences)) {
      alert('Audience name already exists. Please select unique name.')
    } else {
      if (selectedAudienceList.length > 0) {
        setCurrentAudienceTags([...currentAudienceTags, selectedAudienceList])
        handleCloseOnSubmit(audienceName, [...currentAudienceTags, ...selectedAudienceList])
      } else {
        handleCloseOnSubmit(audienceName, currentAudienceTags)
      }
      setSelectedAudienceList([])
      setCurrentAudienceTags([])
    }
  }

  return (
    <Modal show={show} onHide={cancelInterceptor} enforceFocus={false} backdrop="static">
      <Modal.Header>Edit Audience</Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <h3>{audience.name}</h3>
        </div>
        <div className="form-group">
          <label>Name:</label>
          <br />
          <input className="form-control" type="text" name="title" id="audienceName" />
        </div>
        <div>
          <label className="label">Audience's Tags:</label>
          <LightTooltip
            title="Compile selection of tags to create targeted audience "
            place="right"
            data-multiline="true"
          >
            <InfoIcon fontSize="small" />
          </LightTooltip>
          <form id="checkList">
            <table className="d-inline mr-5" key="tag-table-edit">
              <tbody>
                <tr key="Client">
                  <th>{TAGS.CLIENT}</th>
                </tr>
                {clientChecked.map(tagList => (
                  <tr key={tagList.client}>
                    <td>
                      <Checkbox
                        key={tagList.client}
                        checked={tagList.select}
                        value={tagList.client}
                        onChange={e => handleTagChange('client', { tagList }, e)}
                      />
                      {tagList.client}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <table className="d-inline mr-5">
              <tbody>
                <tr key="bizUnit">
                  <th>{TAGS.BIZ}</th>
                </tr>

                {bizChecked.map(tagList => (
                  <tr key={tagList.bizUnit}>
                    <td>
                      <Checkbox
                        key={tagList.bizUnit}
                        checked={tagList.select}
                        value={tagList.bizUnit}
                        onChange={e => handleTagChange('biz', { tagList }, e)}
                      />
                      {tagList.bizUnit}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <table className="d-inline mr-5">
              <tbody>
                <tr key="LOS">
                  <th>{TAGS.LOS}</th>
                </tr>
                {losChecked.map(tagList => (
                  <tr key={tagList.losTag}>
                    <td>
                      <Checkbox
                        key={tagList.losTag}
                        checked={tagList.select}
                        value={tagList.losTag}
                        onChange={e => handleTagChange('los', { tagList }, e)}
                      />
                      {tagList.losTag}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </form>
        </div>
        <div className="audience-list">
          <h2>Current Audience Tags (click to delete):</h2>
          <div className="chip">{renderCurrentUserAudience()}</div>
        </div>
        <div className="button-container">
          <Button variant="primary" onClick={handleClose}>
            Submit Edit
          </Button>
          <Button variant="secondary" onClick={cancelInterceptor}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default EditAudienceModal
