import React from 'react'
import RtfEditor from '../../../richTextFormat/rtfEditor.component'

const ContentEditor = props => {
  return (
    <div className="form-group" id="editor">
      <label>Article Content:</label>
      <RtfEditor
        images={props.images}
        value={props.content}
        onChange={e => props.handleChange(prevState => ({ ...prevState, content: e }))}
        style={{ zIndex: 0 }}
      />
    </div>
  )
}

export default React.memo(ContentEditor)
