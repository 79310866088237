import styled from 'styled-components'
import Button, { ButtonProps } from '@material-ui/core/Button'
import ArrowForward from '@material-ui/icons/ArrowForward'

export const ButtonBase = styled(Button)<ButtonProps>`
  border: 1.5px solid #56ae35;
  border-radius: 8px;
  padding: ${({ size }) => (size === 'small' ? '12px 16px 12px 12px' : '16px 20px 16px 16px')};
  background-color: #fff;
  min-width: 235px;
  text-transform: none;
  transition: box-shadow 0.15s ease-in;
  &.Mui-disabled {
    border: 1.5px solid #acacac;
    & span {
      border-color: rgba(160, 160, 160, 0.4);
      & div:first-child,
      div:nth-child(2) {
        color: #a0a0a0;
      }
    }
    && svg {
      color: #a0a0a0;
      opacity: 0.6;
    }
  }
  &:hover {
    background-color: #f3f9f0;
    box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.16);
  }
  &:focus {
    border: 1px solid rgba(86, 174, 53, 0.8);
    & .cta-btn-content {
      & div:first-child {
        color: rgba(64, 79, 84, 0.8);
      }
      & div:nth-child(2) {
        color: rgba(64, 79, 84, 0.72);
      }
    }
    & svg {
      opacity: 0.7;
    }
  }
`
export const ButtonContent = styled.div`
  display: flex;
  flex: 10;
`

export const ButtonText = styled.span<{ hasIcon: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex: 10;
  text-align: left;
  ${({ hasIcon }) =>
    hasIcon &&
    `
     padding-left: 16px;
     border-left: 2px solid rgba(86, 174, 53, 0.4);
  `}
`

export const ButtonTitle = styled.div<{ size: string }>`
  font-size: ${({ size }) => (size === 'small' ? '20px' : '22px')};
  line-height: 26px;
  font-weight: 800;
  letter-spacing: 0.2px;
  color: #404f54;
  margin-bottom: 3px;
`

export const ButtonSubtitle = styled.div<{ size: string }>`
  font-size: ${({ size }) => (size === 'small' ? '16px' : '18px')};
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 21px;
  color: rgba(64, 79, 84, 0.92);
`

export const ButtonIcon = styled.span`
  flex: 1;
  text-align: right;
  align-self: center;
  && .MuiSvgIcon-root {
    width: 26px;
    color: #404f54;
    height: auto;
    margin-right: 16px;
  }
`

export const Arrow = styled(ArrowForward)`
  color: #56ae35;
`
export const ArrowIcon = styled.div`
  flex: 1;
  margin-left: 6px;
  text-align: right;
`
