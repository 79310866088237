import React from 'react'
import { useError } from 'context/error-context'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}))

const ErrorAlert = () => {
  const { error, setError, errorMes } = useError()
  const classes = useStyles()
  const [open, setOpen] = React.useState(true)

  const handleClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setError(null)
    setOpen(false)
  }

  return error ? (
    <div className={classes.root}>
      <Snackbar open={open} autoHideDuration={10000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal:'center' }}>
        <Alert onClose={handleClose} severity="error">
          {errorMes.title ?? 'Oh snap! You got an error!'} - {errorMes.description ?? errorMes}
        </Alert>
      </Snackbar>
    </div>
  ) : null
}
export default ErrorAlert
