import { Button } from '@material-ui/core'
import styled from 'styled-components'

export const ToolSetWrapper = styled.div`
  padding: 14px;
  min-width: 500px;
`
export const TextEditor = styled.div`
  outline: 1px solid #404f54;
  background-color: #fff;
  border-radius: 8px;
  height: 100px;
  padding: 14px;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: content-box;
  margin-bottom: 14px;
`

export const Header = styled.div`
  padding: 20px;
  font-size: 14px;
  margin: 14px -15px 14px -15px;
  background: rgba(92, 92, 92, 0.1);
  color: rgba(39, 49, 53, 0.4);
  font-family: Montserrat;
  text-align: center;
  border: 1px solid rgba(64, 79, 84, 0.36);
  border-left: none;
  border-right: none;
`
Header.defaultProps = {
  className: 'header'
}

export const Tools = styled.div`
  border: 1px solid rgba(64, 79, 84, 0.36);
  padding: 14px;
  border-radius: 8px;
  background-color: #eee;
  overflow: hidden;
  && .header:first-child {
    border-top: none;
    margin-top: -15px;
  }
`

export const EditorButton = styled(Button)`
  background-color: #56ae35;
  color: #fff;
  margin: 2.5px 5px 2.5px 0px;
  &&:hover {
    background-color: #296c11;
  }
`

EditorButton.defaultProps = {
  variant: 'contained',
  disableElevation: true
}

export const SelectedSection = styled.div<{ disabled: boolean }>`
  background-color: ${props => (props.disabled ? 'rgba(92,92,92,0.1)' : 'white')};
  display: flex;
  align-items: center;
  border: 1px solid #959a9b;
  border-radius: 8px;
  padding: 8px;
  margin-top: 5px;
  color: ${props => (props.disabled ? 'rgba(39,49,53,0.4)' : ' #548c40')};
  font-family: 'Montserrat';
`

export const SectionButtons = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`
