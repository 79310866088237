import { Box, Button } from '@material-ui/core'
import React from 'react'
import { ThemeProvider } from '@material-ui/core'
import { theme } from 'utils/themeSettings'

const Error = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: '100%',
          height: 'calc(100vh - 80px - 1rem)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <Box sx={{ color: '#ffffff', fontSize: 150 }}>404</Box>
        <Box sx={{ color: '#ffffff', fontSize: 70 }}>OOPS! PAGE NOT FOUND!</Box>
        <Button color="primary" variant="contained" href="/">
          Home
        </Button>
      </Box>
    </ThemeProvider>
  )
}
export default Error
