export const LOS = {
  SAE: 'SAE',
  DA: 'DA',
  DL: 'DL',
  BA: 'BA',
  PL: 'PL',
  PEI: 'PEI'
} as const

export const ROLES = {
  USER: 'user',
  ADMIN: 'admin',
} as const

export const Sections = {
  NONE: '',
  NEWS: 'Client News',
  KUDOS: 'Consultant Kudos',
  CONSULTANTS: 'New Consultants',
  TRANSITIONS: 'Consultant Transitions',
  UPCOMING: 'Upcoming Event',
  DAUGHERTYNEWS: 'Daugherty News'
} as const

export const NEWSLETTER_STATUSES = {
  PUBLISHED: 'Published',
  EDITED: 'Edited',
  DRAFT: 'Draft'
} as const

export const ARTICLE_STATUSES = {
  PUBLISHED: 'Published',
  EDITED: 'Edited',
  DRAFT: 'Draft'
} as const

export const MENU_LIST_ITEMS = {
  ARTICLES: {
    CREATE: { LABEL_NAME: 'Create Article', PATH: '/create' },
    MY: { LABEL_NAME: 'My Articles', PATH: '/myarticles' },
    ALL: { LABEL_NAME: 'All Articles', PATH: '/allarticles' }
  },
  NEWSLETTERS: {
    CREATE: { LABEL_NAME: 'Create Newsletter', PATH: '/createnewsletter' },
    MY: { LABEL_NAME: 'My Newsletters', PATH: '/mynewsletters' },
    ALL: { LABEL_NAME: 'All Newsletters', PATH: '/allnewsletters' }
  },
  HEADER_FOOTER: { LABEL_NAME: 'Header and Footer', PATH: '/headerandfooter' },
  ADMIN: { LABEL_NAME: 'Admin Panel', PATH: '/admin' },
  GUIDE: {
    LABEL_NAME: 'User Guide ↗',
    PATH: 'https://daugherty.atlassian.net/wiki/spaces/NDT/pages/3111256114/User+Guide'
  }
} as const

export const TAGS = {
  CLIENT: 'Client',
  LOS: 'Line of Service (LoS)',
  BIZ: 'Business'
} as const

export const USER_ROLE_DESCRIPTIONS = {
  ADMIN: `
  - View, create, edit, delete ANY article
  - View, create, edit, delete ANY newsletter
  - Publish newsletters and related articles
  - Edit newsletter’s headers and footers for assigned audience
  - Manage ANY user (i.e. add/delete users, change user audiences and user permissions)
  - Manage ANY tag
  - Manage ANY audience
  `,
  USER: `
  - View, create, edit, delete ANY article
  - View, create, edit, delete ANY newsletter
  - Publish newsletters and related articles
  - Edit newsletter’s headers and footers for assigned audience
  `
} as const
