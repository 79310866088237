import React from 'react'
import { Newsletters } from 'components'

/**
 * Used to display newsletters for user audiences.
 */
const MyNewsletters = () => {
  return <Newsletters />
}

export default MyNewsletters
