import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import ArticleForm from '../form/article-form.component'
import '../../FontStyle.css'
import '../form/article-form.component.css'
import { ARTICLE_STATUSES } from 'consts'
import { Article } from 'models/article'
import { useConfirmDialog } from 'context/confirm-dialog-context'
import { useError } from 'context/error-context'

type CreateArticleFormProps = {
  isFromNewsletter: boolean,
  toogleShowCreateArticleModal: () => void
  setNewArticleCreated: (args : any) => void
}

const CreateArticle = ({  
    isFromNewsletter,
    toogleShowCreateArticleModal,
    setNewArticleCreated = ()=>{}
  }: CreateArticleFormProps): JSX.Element => {
  const history = useHistory()
  const location = useLocation()
  const [article, setArticle] = useState<Article>({
    tags: [],
    imgs: [],
    section: '',
    title: '',
    content: '',
    owner: '',
    videoObj: [],
    status: ARTICLE_STATUSES.DRAFT,
    createdOn: new Date(Date.now()),
    modifiedBy: '',
    modifiedOn: new Date(Date.now())
  } as Article)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [resetForm, setResetForm] = useState(0)
  const { setConfirmDialogSettings, setOpenConfirmDialog } = useConfirmDialog()
  const { setError } = useError()
  const onSubmit = () => {
    setIsSubmitting(false)
  }

  const cancelCreate = () => {
    try{
      if(isFromNewsletter){
        toogleShowCreateArticleModal()
      } else {
        setConfirmDialogSettings({
          handleClose: () => {
            setOpenConfirmDialog(false)
          },
          handleConfirm: async () => {
            setOpenConfirmDialog(false)
            setResetForm(prevResetForm => prevResetForm + 1)
            history.push('/')
          },
          title: 'Discard changes',
          text: 'Discard these changes and return to the home page?'
        })
        setOpenConfirmDialog(true)
      }
    } catch (err){
      setError(err)
    }
   
  }

  useEffect(() => {
    return () => {
      setArticle({} as Article)
    }
  }, [location])

  return (
    <div className="container rounded newsletter">
      <div className="text-center p-3 px-5">
        <h1 className="p-3 text-dark">Create New Article</h1>
        <ArticleForm
          article={article}
          setArticle={setArticle}
          key={resetForm}
          isSubmitting={isSubmitting}
          handleSubmit={onSubmit}
          handleCancel={cancelCreate}
          autoSave={true}
          isCreating={true}
          isFromNewsletter={isFromNewsletter}
          toogleShowCreateArticleModal={toogleShowCreateArticleModal}
          setNewArticleCreated={setNewArticleCreated}
        />
      </div>
    </div>
  )
}

export default CreateArticle
