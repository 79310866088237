import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import NewsletterGrid from './grid/newsletter-grid.component'
import DateFilter from '../../filter/date-filter.component'
import Loader from '../../loader/loader.component'
import toaster from 'components/toast/toast.component'
import {
  deleteNewsletterById,
  getAllNewsletters,
  getNewsLettersByTag,
  getNewslettersByOwnerId
} from 'apis/newsletter.service'
import { isEmpty } from 'lodash'
import { UserContext } from 'context/user-context'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { useError } from 'context/error-context'
import { useConfirmDialog } from 'context/confirm-dialog-context'
import { ROLES } from 'consts'
const invertDirection = {
  asc: 'desc',
  desc: 'asc'
}

/**
 * Main component for displaying Newsletters to the user.
 *
 * What newsletters are displayed is depending on what is sent via props:
 *    When no parameter is passed, it is displaying newsletters which audience is one of logged in user audiences
 *    When parameter @showAll is passed via props, it is displaying all newsletters
 *    When parameter @tagName is passed via props, it is displaying all newsletters for the passed tag
 *
 * It consist of the following main components:
 *    MyNewslettersFilter - Filtering section
 *    NewsletterGrid - The whole table with newsletters
 *
 * MyNewslettersFilter is not available when @tagName is provided, as it would require more refactor of backend functionalities
 *
 * Its state variables are:
 *    @property {Newsletter[]} newsletters - newsletters to be displayed currently according to pagination settings
 *    @property {Number} totalNewsletters - amount of all found newsletters
 *    @property {Boolean} isLoading - is the component currently in loading state
 *    @property {String} columnToSort - column by which sorting should be done
 *    @property {String} sortDirection - sorting direction - asc/desc
 *    @property {Number} page - current page in pagination
 *    @property {Number} rowsPerPage - current rows per page settings
 *    @property {Boolean} didGrabNewsletters - old variable, perhaps for refactor
 *    @property {String} dateType - value in Date Type provided in filtering section
 *    @property {Date} dateFrom - value in Date From provided in filtering section
 *    @property {Date} dateTo - value in Date To provided in filtering section
 */
const Newsletters = ({ showAll, tagName }) => {
  const { user } = useContext(UserContext)
  const { setConfirmDialogSettings, setOpenConfirmDialog } = useConfirmDialog()
  const [newsletters, setNewsLetters] = useState([])
  const [totalNewsletters, setTotalNewsletters] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [columnToSort, setColumnToSort] = useState('datePublished')
  const [sortDirection, setSortDirection] = useState('desc')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [didGrabNewsletters, setDidGrabNewsletters] = useState(false)
  const [dateType, setDateType] = useState('')
  const [dateFrom, setDateFrom] = useState(null)
  const [dateTo, setDateTo] = useState(null)
  const { setError } = useError()
  const history = useHistory()

  /**
   * Type of param parameter used for retrieving newsletters from backend
   * @typedef {Object} Param
   * @property {Number} page - desired page in pagination
   * @property {Number} limit - desired rows per page
   * @property {String} sort - sorting definition in format `${columnToSort}:${sortDirection}`
   * @property {Object} filter - defines newsletters filtering
   *    @property {String} filter.dateType - value in Date Type provided in filtering section
   *    @property {Date} filter.dateFrom - value in Date From provided in filtering section
   *    @property {Date} filter.dateTo - value in Date To provided in filtering section
   */

  /**
   * Takes newsletters from the database and set values to newsletters and totalNewsletters state variables
   * @param {Param} params - instructions to backend for newsletters searching
   */
  const grabNewsletters = useCallback(
    async params => {
      if (!params || !params.sort) {
        const sort = {
          sortBy: `${columnToSort}`,
          orderBy: `${sortDirection}`
        }
        params = { ...params, sort }
      }

      const formattedFilter = formatFilterParameter(params.filter)
      params.filter = { ...formattedFilter }

      const { data, totalNewsletters } = await getNewslettersFromBackend(params)

      setNewsLetters(data)
      setIsLoading(false)
      setTotalNewsletters(totalNewsletters)
      setDidGrabNewsletters(true)

      //if pagination was clicked, scroll to the bottom of the screen
      if (params.pagination) {
        window.scrollTo(0, document.body.scrollHeight)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  )

  /**
   * Invokes retrieveNewslettersDependingOnProps() function retrieving newsletters depending on props
   * Before returning, it does additional checks of the data returned from backend
   * @param {Param} params - instructions to backend for newsletters searching
   * @returns {{data: Newsletter[], totalNewsletters: Number}} newsletters and totalNewsletters information from backend
   */
  const getNewslettersFromBackend = async params => {
    try {
      const { data, totalNewsletters } = await retrieveNewslettersDependingOnProps(params)
      if (data !== 'Insufficient Permissions') {
        return { data, totalNewsletters }
      }
    } catch (error) {
      setError(error)
      history.push('/')
      // We're destructuring the response --> const { data, totalNewsletters } = await getNewslettersFromBackend(params)
      // Need to return an object matching shape of the expected response from the API or the app throws a type error and crashes
      return { data: [], totalNewsletters: 0 }
    }
  }

  /**
   * Invokes proper method retrieving newsletters from backend basing on props passed to the component
   * @param {Param} params - instructions to backend for newsletters searching
   * @returns {{data: Newsletter[], totalNewsletters:Number}} newsletters and totalNewsletters informatino from backend
   */
  const retrieveNewslettersDependingOnProps = async params => {
    if (showAll) {
      return await retrieveAllNewsletters(params)
    } else if (tagName) {
      return await retrieveNewslettersByTag(params)
    }
    return await retrieveNewslettersByOwner(params)
  }

  /**
   * Retrieves newsletters for audiences to which user belong from backend
   * @param {Param} params - instructions to backend for newsletters searching
   * @returns {{data: Newsletter[], totalNewsletters: Number}} newsletters and totalNewsletters information from backend
   */
  const retrieveNewslettersByOwner = async params => {
    params.ownerId = user._id

    if (!params || !params.sort) {
      const sort = {
        sortBy: `${columnToSort}`,
        orderBy: `${sortDirection}`
      }
      params = { ...params, sort, limit: 0, ownerId: user._id }
    }
    try {
      const newsletterByOwner = await getNewslettersByOwnerId(params)
      const { data, totalNewsletters } = newsletterByOwner.data
      return { data, totalNewsletters }
    } catch (err) {
      setError(err)
      history.push('/')
      // We're destructuring the response --> const { data, totalNewsletters } = await getNewslettersFromBackend(params)
      // Need to return an object matching shape of the expected response from the API or the app throws a type error and crashes
      return { data: [], totalNewsletters: 0 }
    }
  }

  /**
   * Retrieves all newsletters from backend
   * @param {Param} params - instructions to backend for newsletters searching
   * @returns {{data: Newsletter[], totalNewsletters: Number}} newsletters and totalNewsletters information from backend
   */
  const retrieveAllNewsletters = async params => {
    if (!params || !params.sort) {
      const sort = {
        sortBy: `${columnToSort}`,
        orderBy: `${sortDirection}`
      }
      params = { ...params, sort, limit: 0 }
    }
    const { data, totalNewsletters } = (await getAllNewsletters({ ...params })).data
    if (data.success === false) {
      toaster('ERROR', 'Your token is expired, invalid, or missing. Please log in again.')
      history.push('/')
    }
    return { data, totalNewsletters }
  }

  /**
   * Retrieves  newsletters by tag from backend
   * @tagName parameter is received by a component from props
   * @param {Param} params - instructions to backend for newsletters searching
   * @returns {{data: Newsletter[], totalNewsletters: Number}} newsletters and totalNewsletters information from backend
   */
  const retrieveNewslettersByTag = async params => {
    if (!params || !params.sort) {
      const sort = {
        sortBy: `${columnToSort}`,
        orderBy: `${sortDirection}`
      }
      params = { ...params, sort, limit: 0 }
    }
    try {
      const { newsletters, totalNewsletters } = (await getNewsLettersByTag({ tagList: tagName, ...params })).data
      const data = newsletters
      return { data, totalNewsletters }
    } catch (err) {
      setError(err)
      history.push('/')
      // We're destructuring the response --> const { data, totalNewsletters } = await getNewslettersFromBackend(params)
      // Need to return an object matching shape of the expected response from the API or the app throws a type error and crashes
      return { data: [], totalNewsletters: 0 }
    }
  }

  /**
   * Retrieved each and every newsletter regardless of pagination
   * Using either getNewsletters() or getNewslettersForUserAudience() function
   * @returns {{data: Newsletter[], totalNewsletters: Number}} newsletters and totalNewsletters information from backend
   */
  const getNewslettersForCsv = () => {
    const params = {
      limit: totalNewsletters,
      sort: {
        sortBy: `${columnToSort}`,
        orderBy: `${sortDirection}`
      },
      filter: formatFilterParameter()
    }
    return retrieveNewslettersDependingOnProps(params)
  }

  /**
   * Retrieves only active audiences for currently logged in user
   * @param {User} user - currently logged in user
   * @returns {User.audience[]} active audiences of currently logged in users
   */
  const getActiveAudiencesForUser = user => {
    return user.audience.filter(audience => audience.currentAudience)
  }

  const hasNoAudience = useCallback(() => {
    setError({title: 'No audiences have been assigned to you', description: 'Redirecting you to the homepage'})
    history.push('/')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkAccess = useCallback(() => {
    if (user.role === ROLES.ADMIN || user.role === ROLES.USER) {
      if (getActiveAudiencesForUser(user).length === 0) {
        setIsLoading(false)
        hasNoAudience()
      }
    } else {
      toaster('ERROR', 'You do not have permissions to view this page. Redirecting to home page.')
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNoAudience, user.role, user.audience])

  /** Executed when component is initialized, grabs newsletters and checks if user has any audience */
  useEffect(() => {
    if (!isEmpty(user)) {
      grabNewsletters()
      checkAccess()
      return () => {
        setIsLoading(false)
        setDidGrabNewsletters(false)
      }
    }
  }, [user, grabNewsletters, checkAccess])

  const deleteNewsletter = useCallback(
    newsletter => {
      setConfirmDialogSettings({
        handleClose: () => {
          setOpenConfirmDialog(false)
        },
        handleConfirm: async () => {
          setOpenConfirmDialog(false)
          try {
            await deleteNewsletterById(newsletter._id)
            await grabNewsletters()
          } catch (error) {
            setError(error)
          }
        },
        title: 'Delete newsletter',
        text: 'Are you sure you would like to delete the selected newsletter?'
      })
      setOpenConfirmDialog(true)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [grabNewsletters, setError]
  )

  const handleNewsletterSort = useCallback(
    params => {
      const { column, page, rowsPerPage } = params

      if (column.id === 'audience') return

      if (column.id !== 'edit' || column.id !== 'delete') {
        setIsLoading(true)
        const order = sortDirection === invertDirection.desc ? invertDirection.asc : invertDirection.desc
        setColumnToSort(column.id)
        setSortDirection(sortDirection === invertDirection.desc ? invertDirection.asc : invertDirection.desc)
        grabNewsletters({
          page: page,
          limit: rowsPerPage,
          sort: {
            sortBy: `${column.id}`,
            orderBy: `${order}`
          },
          filter: formatFilterParameter()
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnToSort, newsletters, sortDirection]
  )

  const tablePaginationChange = props => {
    setIsLoading(true)
    grabNewsletters({ ...props, filter: formatFilterParameter(), pagination: true })
  }

  /** Responsible for rendering newsletters table, or validation messages */
  const myNewslettersForm = useMemo(() => {
    if (didGrabNewsletters) {
      if (isEmpty(user)) {
        return <h1>You have no audiences</h1>
      }
      if (totalNewsletters) {
        return (
          <NewsletterGrid
            newsletters={newsletters}
            totalNewsletters={totalNewsletters}
            id="table"
            delete={deleteNewsletter}
            handleNewsletterSort={handleNewsletterSort}
            columnToSort={columnToSort}
            sortDirection={sortDirection}
            user={user}
            isShowingAllNewsletters={showAll}
            onTablePaginationChange={tablePaginationChange}
            getAllNewsletters={getNewslettersForCsv}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        )
      }
      return (
        <>
          <h1 id="noNewsletters">No newsletters to display</h1>
          <Button color="primary" variant="contained" onClick={() => history.push('/createnewsletter')}>
            Create a newsletter
          </Button>
        </>
      )
    }
    return <p id="failed">Newsletters failed to load</p>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    didGrabNewsletters,
    deleteNewsletter,
    handleNewsletterSort,
    columnToSort,
    sortDirection,
    user,
    showAll,
    newsletters,
    totalNewsletters
  ])

  /**
   * Triggered when date type combobox selection was changed
   * Either shows DateFrom and DateTo or hides them
   * @param {?} e - context of Date Type combobox
   */
  const changeDateTypeHandler = e => {
    e.preventDefault()
    const value = e.target.value
    setDateType(value)

    const params = {
      page: page,
      limit: rowsPerPage,
      sort: {
        orderBy: `${columnToSort}`,
        sortBy: `${sortDirection}`
      }
    }
    const filter = { dateType: value, dateFrom: dateFrom, dateTo: dateTo }

    if (value === '') {
      setDateFrom(null)
      setDateTo(null)
      grabNewsletters({ ...params, filter: {} })
      setIsLoading(false)
    } else {
      grabNewsletters({ ...params, filter: filter })
      setIsLoading(false)
    }
  }

  /**
   * Triggered when date from field was changed
   * @param {Date} date - date passed into Date From field
   */
  const changeDateFromHandler = date => {
    const params = {
      page: page,
      limit: rowsPerPage,
      sort: {
        orderBy: `${columnToSort}`,
        sortBy: `${sortDirection}`
      }
    }
    const filter = { dateType: dateType, dateFrom: date, dateTo: dateTo }
    setDateFrom(date)
    grabNewsletters({ ...params, filter: filter })
    setIsLoading(false)
  }

  /**
   * Triggered when date to field was changed
   * @param {Date} date - date passed into Date To field
   */
  const changeDateToHandler = date => {
    const params = {
      page: page,
      limit: rowsPerPage,
      sort: {
        orderBy: `${columnToSort}`,
        sortBy: `${sortDirection}`
      }
    }
    const filter = { dateType: dateType, dateFrom: dateFrom, dateTo: date }
    setDateTo(date)
    grabNewsletters({ ...params, filter: filter })
    setIsLoading(false)
  }

  /**
   * Makes sure filter field in param parameter has correct format
   * @param {Param.filter} filter - filter to be formatted
   * @returns {Params.filter} filter object with required fields
   */
  const formatFilterParameter = filter => {
    if (!filter) {
      if (dateType === '') {
        return {
          dateType,
          dateFrom: '',
          dateTo: ''
        }
      }

      let isDateFromOrDateToSelected = true
      if ((dateTo === null || dateTo === undefined) && (dateFrom === null || dateFrom === undefined)) {
        isDateFromOrDateToSelected = false
      }

      return {
        dateType: isDateFromOrDateToSelected ? dateType : '',
        dateFrom: dateFrom ?? '',
        dateTo: dateTo ?? ''
      }
    }

    return {
      dateType: filter.dateType,
      dateFrom: filter.dateFrom ? filter.dateFrom : '',
      dateTo: filter.dateTo ? filter.dateTo : ''
    }
  }

  const renderTitle = () => {
    if (showAll) {
      return 'All Newsletters'
    } else if (tagName) {
      return `All Newsletters - ${tagName}`
    }
    return 'My Newsletters'
  }

  return (
    <div className="newsletter p-4 mx-0 my-auto" id="myNewslettersOuterWrapper" data-cy="header">
      <h1 className="text-center text-dark p-4" id="myNewslettersTitle">
        {renderTitle()}
      </h1>
      {!tagName && (
        <DateFilter
          values={{ dateType: dateType, dateFrom: dateFrom, dateTo: dateTo }}
          onDateTypeChange={e => changeDateTypeHandler(e)}
          onDateFromChange={e => changeDateFromHandler(e)}
          onDateToChange={e => changeDateToHandler(e)}
        />
      )}
      <hr />
      {isLoading ? <Loader id="pageLoadingBar" /> : myNewslettersForm}
    </div>
  )
}

/**
 * Prop-types description
 *    showAll - if true, all newsletters are shown, if false only those directed to user audiences
 *    tagName - name of the tag when in Newsletters by category view
 */
Newsletters.propTypes = {
  showAll: PropTypes.bool,
  tagName: PropTypes.string
}

export default Newsletters
