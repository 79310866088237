export interface TagProps {
  _id: string
  name: string
  type: string
}

export class Tag implements TagProps {
  _id: string
  name: string
  type: string

  constructor(props: Partial<TagProps>) {
    this._id = props?._id ?? undefined
    this.name = props?.name ?? ''
    this.type = props?.type ?? ''
  }
}
