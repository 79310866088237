import Auth from '@aws-amplify/auth'

/** The instance to keep auth data of current user */

const oauth = {
  domain: process.env.REACT_APP_COGNITO_POOL_DOMAIN ?? '',
  scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  redirectSignIn: process.env.REACT_APP_URL,
  redirectSignOut: `${process.env.REACT_APP_URL}login/`,
  responseType: 'code'
}
Auth.configure({
  oauth: oauth,
  region: process.env.REACT_APP_AMPLIFY_COGNITO_CONF_REGION ?? '',
  userPoolId: process.env.REACT_APP_COGNITO_POOL_ID ?? '',
  userPoolWebClientId: process.env.REACT_APP_COGNITO_POOL_CLIENT_ID ?? ''
})

export default Auth
