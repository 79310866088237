export const fromNewsletterCellStyles = {
  tablet_sm: {
    '&:nth-child(1)': {
      gridColumn: '1 / span 1',
      order: 1
    },
    '&:nth-child(2)': {
      gridColumn: '2 / span 7',
      order: 2
    },
    '&:nth-child(9)': {
      gridColumn: '9/ span 1',
      order: 3
    },
    '&:nth-child(10)': {
      gridColumn: '10/ span 1',
      order: 4
    },
    '&:nth-child(11)': {
      gridColumn: '11 / span 1',
      order: 5
    },
    '&:nth-child(3)': {
      gridColumn: '1 / span 4',
      order: 6
    },
    '&:nth-child(4)': {
      gridColumn: '5 /span 3',
      order: 7
    },
    '&:nth-child(5)': {
      gridColumn: '8 / span 3',
      order: 8
    },
    '&:nth-child(6)': {
      gridColumn: '1/ span 3',
      order: 9
    },
    '&:nth-child(7)': {
      gridColumn: '4/ span 3',
      order: 10
    },
    '&:nth-child(8)': {
      gridColumn: '7/ span 5',
      order: 11
    }
  },
  tablet: {
    '&:nth-child(4)': {
      gridColumn: '1',
      gridRow: 'second-line'
    },
    '&:nth-child(5)': {
      gridColumn: '2',
      gridRow: 'second-line'
    },
    '&:nth-child(6)': {
      gridColumn: '3',
      gridRow: 'second-line'
    },
    '&:nth-child(7)': {
      gridColumn: '4/ span 4',
      gridRow: 'second-line'
    }
  }
}
export const articlesCellStyles = {
  tablet_sm: {
    '&:nth-child(1)': {
      gridColumn: '1 / span 7',
      order: 1
    },
    '&:nth-child(8)': {
      gridColumn: '8/ span 1',
      order: 2
    },
    '&:nth-child(9)': {
      gridColumn: '9/ span 1',
      order: 3
    },
    '&:nth-child(10)': {
      gridColumn: '10 / span 1',
      order: 4
    },
    '&:nth-child(2)': {
      gridColumn: '1 / span 4',
      order: 5
    },
    '&:nth-child(3)': {
      gridColumn: '5 /span 3',
      order: 6
    },
    '&:nth-child(4)': {
      gridColumn: '8 / span 3',
      order: 7
    },
    '&:nth-child(5)': {
      gridColumn: '1/ span 3',
      order: 8
    },
    '&:nth-child(6)': {
      gridColumn: '4/ span 3',
      order: 9
    },
    '&:nth-child(7)': {
      gridColumn: '7/ span 4',
      order: 10
    }
  },
  tablet: {
    '&:nth-child(3)': {
      gridColumn: '1',
      gridRow: 'second-line'
    },
    '&:nth-child(4)': {
      gridColumn: '2',
      gridRow: 'second-line'
    },
    '&:nth-child(5)': {
      gridColumn: '3',
      gridRow: 'second-line'
    },
    '&:nth-child(6)': {
      gridColumn: '4/ span 4',
      gridRow: 'second-line'
    }
  }
}
export const fromNewsletterRowTemplate = {
  tablet_sm: {
    gridTemplateColumns: '50px repeat(7, 1fr)  repeat(3, 50px)',
    gridTemplateRows: '1fr'
  },
  tablet: {
    gridTemplateColumns: '50px 3fr  2fr 2fr  repeat(3, 50px)',
    gridTemplateRows: '1fr'
  }
}
export const articlesRowTemplate = {
  tablet_sm: {
    gridTemplateColumns: 'repeat(7, 1fr)  repeat(3, 50px)',
    gridTemplateRows: '1fr'
  },
  tablet: {
    gridTemplateColumns: '4fr  2fr 2fr  repeat(3, 50px)',
    gridTemplateRows: '1fr'
  }
}
