import React from 'react'
import parse from 'html-react-parser'

const ContentItem = props => {
  const renderImages = () => {
    return (
      <>
        {props.article.imgs
          .slice()
          .map((img, index) =>
            img === undefined ? null : (
              <img
                key={index}
                height="auto"
                width="auto"
                src={img}
                alt=""
                style={{ marginRight: '20px', marginBottom: '20px' }}
              />
            )
          )}
      </>
    )
  }

  const renderThumbnails = () => {
    if (!props.article.videoObj || props.article.videoObj.length === 0) {
      return null
    }

    return (
      <>
        {props.article.videoObj.slice().map((video, index) =>
          video === undefined ? null : (
            <a key={index} href={video.videoLink} target="_blank" rel="noopener noreferrer">
              <img
                height="auto"
                width="auto"
                src={video.videoThumbnail}
                alt=""
                style={{ marginRight: '20px', marginBottom: '20px' }}
              />
            </a>
          )
        )}
      </>
    )
  }
  return (
    <>
      <div className="article-content-nl">
        <div>
          <h2>{props.article.title !== undefined ? props.article.title : ''}</h2>
          <br />
        </div>
        {renderImages()}
        <br />
        {renderThumbnails()}
        {props.article.content ? parse(props.article.content) : null}
        <br />
      </div>
    </>
  )
}
export default ContentItem
