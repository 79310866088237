export const newsletterCellStyles = {
  tablet_sm: {
    '&:nth-child(1)': {
      gridColumn: '1 / span 3',
      order: 1
    },

    '&:nth-child(6)': {
      gridColumn: '4/ span 1',
      order: 2
    },
    '&:nth-child(7)': {
      gridColumn: '5/ span 1',
      order: 3
    },
    '&:nth-child(2)': {
      gridColumn: '1 / span 5',
      order: 4
    },
    '&:nth-child(3)': {
      gridColumn: '1 / span 3',
      order: 5
    },
    '&:nth-child(4)': {
      gridColumn: '4 / span 1',
      order: 6
    },
    '&:nth-child(5)': {
      gridColumn: '5 /span 1',
      order: 7
    }
  },
  tablet: {
    '&:nth-child(4)': {
      gridColumn: '1',
      gridRow: 'second-line'
    },
    '&:nth-child(5)': {
      gridColumn: '2',
      gridRow: 'second-line'
    }
  }
}

export const newsletterRowTemplate = {
  tablet_sm: {
    gridTemplateColumns: 'repeat(3, 1fr)  repeat(2, 60px)',
    gridTemplateRows: '1fr'
  },
  tablet: {
    gridTemplateColumns: 'repeat(3, 1fr)  repeat(2, 60px)',
    gridTemplateRows: '1fr'
  }
}
