import React from 'react'
import { Box } from '@material-ui/core'

export const GridRow = props => {
  /** Adds default hover background property if not passed through props */
  const hoveredBackgroundColor = () => {
    const defaultBackgroundColor = 'rgba(0,0,0,0.04)'

    return props.tableRowStyles['&:hover']
      ? {}
      : {
          '&:hover': {
            backgroundColor: defaultBackgroundColor
          }
        }
  }

  return (
    <Box
      data-cy={props['data-cy']}
      sx={{
        ...props.tableRowStyles,
        ...hoveredBackgroundColor(),

        borderTop: '1px solid rgba(128, 128, 128, 0.2)'
      }}
    >
      {props.children}
    </Box>
  )
}
