import React, { useState } from 'react'
import Content from 'components/content/content.component'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  DialogTitle,
  Tooltip,
  TextField
} from '@material-ui/core'
import './preview.component.css'
import { handleDownload } from 'utils/downloadContent'

const Preview = props => {
  const [subjectLine, setSubjectLine] = useState('')
  const handleChange = event => {
    setSubjectLine(event.target.value)
  }

  return (
      <Dialog open={props.show} onClose={props.handleCancel} maxWidth="lg">
      <DialogTitle style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        {props.buttons
          ? props.buttons.map((button, index) => {
              return (
                <Tooltip key={index} title={button.name}>
                  <IconButton
                    variant="outlined"
                    onClick={e => button.handler(e)}
                    style={{ fontWeight: 600, color: '#538b3f', borderColor: '#538b3f' }}
                  >
                    {button.icon}
                  </IconButton>
                </Tooltip>
              )
            })
          : null}
      </DialogTitle>

      <DialogContent className="content">
        <Content sections={props.sections} articles={props.articles} />
      </DialogContent>
      <DialogActions>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            style={{ marginRight: '1rem', width: '30.5rem' }}
            variant="outlined"
            label="Subject for Email"
            size="small"
            value={subjectLine}
            onChange={handleChange}
          />
          <Tooltip title="Download Outlook File">
            <Button
              variant="outlined"
              style={{ fontWeight: 600, color: '#538b3f', borderColor: '#538b3f' }}
              onClick={() => handleDownload(subjectLine)}
            >
              Download
            </Button>
          </Tooltip>
        </div>
        <Button
          data-cy="close-preview-btn"
          variant="contained"
          onClick={props.handleCancel}
          style={{
            fontWeight: 600,
            color: 'rgba(255, 255, 255, 0.87)',
            backgroundColor: '#538b3f'
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Preview
