import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TablePagination from '@material-ui/core/TablePagination'
import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon from '@material-ui/icons/Delete'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import FileCopy from '@material-ui/icons/FileCopy'
import Paper from '@material-ui/core/Paper'
import { useMediaQuery } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { GridBody, GridCell, GridRow, HeaderGridCell, HeaderGridRow, GridToolbar } from 'components/Grid'
import { dateFormat } from '../../../utils/helpers'
import {
  fromNewsletterCellStyles,
  articlesCellStyles,
  fromNewsletterRowTemplate,
  articlesRowTemplate
} from './articleMediaQueryStyles'
import { theme } from '../../../utils/themeSettings'
import toaster from 'components/toast/toast.component'
import PropTypes from 'prop-types'
import { useConfirmDialog } from 'context/confirm-dialog-context'
import { ARTICLE_STATUSES } from 'consts'
import { StatusChip } from 'components/statusChip/StatusChip'

const useStyles = makeStyles({
  root: {
    width: '100%',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
  },
  arrow: {
    fontSize: '22px',
    color: 'gray'
  }
})

/** Component used to represent articles on View Articles page */
const ArticlesGrid = ({
  addArticlesToNewsletter,
  calledFromEditNewsletter,
  columnToSort,
  handleDelete,
  handleSort,
  sortedArticles,
  sortDirection,
  startCopy,
  totalArticles,
  handleSelectArticle,
  tablePaginationHandle,
  getFilteredArticles,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  selectedArticlesIds,
  showAll,
  user,
  setActionOnArticle
}) => {
  const classes = useStyles()
  const { setConfirmDialogSettings, setOpenConfirmDialog } = useConfirmDialog()
  const mediaQueries = {
    tablet: useMediaQuery('(max-width:991px)'),
    tablet_sm: useMediaQuery('(max-width:600px)')
  }

  let cellStyles = {}
  if (calledFromEditNewsletter) {
    cellStyles = fromNewsletterCellStyles
  } else {
    cellStyles = articlesCellStyles
  }
  const gridTemplate = [
    {
      query: mediaQueries.tablet_sm,
      template: calledFromEditNewsletter ? fromNewsletterRowTemplate.tablet_sm : articlesRowTemplate.tablet_sm,
      cell: {
        padding: '10px',
        ...cellStyles.tablet_sm
      }
    },
    {
      query: mediaQueries.tablet,
      template: calledFromEditNewsletter ? fromNewsletterRowTemplate.tablet : articlesRowTemplate.tablet,
      cell: {
        padding: '10px',
        ...cellStyles.tablet
      }
    }
  ]

  let tableRowStyles = {
    flexGrow: 1,
    display: 'grid',
    gridTemplateColumns: calledFromEditNewsletter
      ? '1fr  2fr 1fr repeat(4, 1fr)  repeat(4, 50px)'
      : '3fr  2fr 2fr 1fr 2fr 2fr repeat(4, 50px)',
    alignItems: 'center',
    fontSize: '0.875em',
    fontWeight: 500
  }

  /** Style of selected row */
  const tableSelectedRowStyles = {
    ...tableRowStyles,
    backgroundColor: '#ddf1d6',
    '&:hover': {
      backgroundColor: '#d3f0c8'
    }
  }

  let tableCellStyles = { padding: 16 }

  for (let index = 0; index < gridTemplate.length; index++) {
    if (gridTemplate[index].query) {
      tableRowStyles = { ...tableRowStyles, ...gridTemplate[index].template }
      tableCellStyles = { ...tableCellStyles, ...gridTemplate[index].cell }
      break
    }
  }

  const verifyCopy = article => {
    setConfirmDialogSettings({
      handleClose: () => {
        setOpenConfirmDialog(false)
      },
      handleConfirm: () => {
        setOpenConfirmDialog(false)
        startCopy(article._id)
      },
      title: 'Copy article',
      text: `Are you sure you want to copy:
      "${article.title}"`
    })
    setOpenConfirmDialog(true)
  }

  const verifyDelete = article => {
    setConfirmDialogSettings({
      handleClose: () => {
        setOpenConfirmDialog(false)
      },
      handleConfirm: () => {
        setOpenConfirmDialog(false)
        handleDelete(article)
      },
      title: 'Delete article',
      text: `Are you sure you want to delete:
      "${article.title}"`
    })
    setOpenConfirmDialog(true)
  }

  const handleSelect = article => {
    handleSelectArticle(article)
  }

  /** Definition of columns to be rendered in the articles table */
  const articleColumns = [
    {
      id: 'title',
      label: 'Title',
      styles: { textAlign: 'left' },
      content: article => article.title,
      tooltip: article => article.title
    },
    {
      id: 'section',
      label: 'Section',
      styles: { textAlign: 'left' },
      content: article => (article.section ? article.section : ''),
      tooltip: article => (article.section ? article.section : '')
    },
    {
      id: 'modifiedOn',
      label: 'Last Modified',
      styles: { textAlign: 'left' },
      content: article => dateFormat(article.modifiedOn ? article.modifiedOn : article.createdOn),
      tooltip: article => dateFormat(article.modifiedOn ? article.modifiedOn : article.createdOn)
    },
    {
      id: 'status',
      label: 'Status',
      styles: { textAlign: 'left' },
      content: article => <StatusChip status={article.status} />,
      tooltip: article => article.status
    },
    {
      id: 'owner',
      label: 'Owner',
      styles: { textAlign: 'left' },
      content: article => (article.owner ? article.owner.split('@')[0].replace('.', ' ') : 'No assigned owner'),
      tooltip: article => (article.owner ? article.owner.split('@')[0].replace('.', ' ') : 'No assigned owner')
    },
    {
      id: 'tags',
      label: 'Tags',
      styles: { textAlign: 'left' },
      format: value => value.toLocaleString('en-US'),
      content: article => article.tags.join(', '),
      tooltip: article => article.tags.join(', ')
    },
    {
      id: 'preview',
      label: '',
      styles: { textAlign: 'right' },
      icon: () => <VisibilityIcon />,
      tooltip: 'Preview this article',
      clickHandler: ({ _id }) => setActionOnArticle({ _id, type: 'preview' })
    },
    {
      id: 'copy',
      label: '',
      styles: { textAlign: 'right' },
      icon: () => <FileCopy />,
      tooltip: 'Copy the content of this article into a new one',
      clickHandler: verifyCopy
    }
  ]

  if (!(user.role === 'author' && showAll)) {
    articleColumns.push(
      {
        id: 'edit',
        label: '',
        styles: { textAlign: 'right' },
        icon: () => <CreateIcon />,
        tooltip: 'Edit the content of this article',
        clickHandler: ({ _id }) => setActionOnArticle({ _id, type: 'edit' })
      },
      {
        id: 'delete',
        label: '',
        styles: { textAlign: 'right' },
        icon: () => <DeleteIcon />,
        tooltip: 'Delete this article',
        clickHandler: verifyDelete,
        format: value => value.toFixed(2)
      }
    )
  }

  /** Columns shown when on Add article to newsletter view */
  const newsletterColumns = [
    {
      id: 'select',
      label: 'Select to add',
      styles: { textAlign: 'left' },
      tooltip: 'Click to add',
      content: article => (
        <span className="d-inline-block float-left">
          <input
            type="checkbox"
            onChange={() => handleSelect(article)}
            checked={!!selectedArticlesIds.find(id => id === article._id)}
          />
        </span>
      )
    },
    ...articleColumns.filter(el => el.id !== 'delete'),
    {
      id: 'add',
      label: '',
      styles: { textAlign: 'right' },
      icon: () => <AddCircleIcon />,
      tooltip: 'Add',
      clickHandler: ({ _id }) => addArticlesToNewsletter([_id]),
      format: value => value.toFixed(2)
    }
  ]

  /** Contains columns to be rendered, either articleColumns or newsletterColumns depending on context */
  let columns = []

  if (calledFromEditNewsletter) {
    columns = newsletterColumns
  } else {
    columns = articleColumns
  }

  const handleChangePage = (_, newPage) => {
    setPage(newPage)
    tablePaginationHandle(newPage, rowsPerPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
    setPage(0)
    tablePaginationHandle(page, event.target.value)
  }

  const getArrayForCSV = async () => {
    let result
    try {
      result = await getFilteredArticles();
      const newRes = result.map(el => {
        const newEl = {}
        columns.forEach(col => {
          if (
            col.id !== 'copy' &&
            col.id !== 'edit' &&
            col.id !== 'delete' &&
            col.id !== 'preview' &&
            col.id !== 'add'
          ) {
            if(col.label === 'Status')
              newEl[col.label] = ('' + el.status)
            else
              newEl[col.label] = ('' + col.content(el)).replace(/[,]+/g, ';').replace(/[#]+/g, ' ')
          }
        })
        return newEl
      })
      return newRes
    } catch (e) {
      toaster('ERROR', 'Something went wrong! ')
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Paper className={classes.root}>
        <GridToolbar getArrayForCSV={getArrayForCSV} />
        <HeaderGridRow tableRowStyles={tableRowStyles}>
          {columns.map(column => (
            <HeaderGridCell
              column={column}
              key={column.id}
              handleSort={() => handleSort({ column, page, rowsPerPage })}
              columnToSort={columnToSort}
              sortDirection={sortDirection}
              cellStyles={tableCellStyles}
            />
          ))}
        </HeaderGridRow>
        <GridBody>
          {sortedArticles.map(article => {
            // If the article list has been generated for the component which allows users to add articles to a newsletter
            // Filter out any article in published status (should not be selectable as it's already used by another newsletter)
            if (calledFromEditNewsletter && article.status.toLowerCase() === ARTICLE_STATUSES.PUBLISHED.toLowerCase()) {
              return null
            }
            return (
              <GridRow
                data-cy="article-row"
                tableRowStyles={
                  selectedArticlesIds.find(el => el === article._id) ? tableSelectedRowStyles : tableRowStyles
                }
                key={article._id}
                id={article.title}
              >
                {columns.map(column => {
                  // Do not show edit and delete icons for articles in published status
                  if (article?.status.toLocaleLowerCase() === ARTICLE_STATUSES.PUBLISHED.toLocaleLowerCase()) {
                    if (column?.id === 'edit' || column?.id === 'delete') {
                      return null
                    }
                  }
                  return (
                    <GridCell
                      key={column.id}
                      column={column}
                      clickHandler={column.clickHandler ? () => column.clickHandler(article) : null}
                      content={column.content ? () => column.content(article) : () => null}
                      tooltip={typeof column.tooltip === 'function' ? column.tooltip(article) : column.tooltip}
                      cellStyles={tableCellStyles}
                      iconSize={mediaQueries.tablet ? 'small' : 'medium'}
                    />
                  )
                })}
              </GridRow>
            )
          })}
        </GridBody>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalArticles}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          id="articlesTablePagination"
          labelRowsPerPage={mediaQueries.tablet_sm ? null : 'Rows per page:'}
        />
      </Paper>
    </ThemeProvider>
  )
}

/**
 * Prop-types description
 *    sortedArticles - articles to display
 *    totalArticles - number of total articles to display in pagination
 *    page - current page in the pagination
 *    rowsPerPage - how many rows are displayed at once
 *    columnToSort - by which column sorting is currently done
 *    sortDirection - determines sorting direction, asc or desc
 *    getFilteredArticles - used for exporting to CSV
 */
ArticlesGrid.propTypes = {
  sortedArticles: PropTypes.arrayOf(PropTypes.object),
  totalArticles: PropTypes.number,
  page: PropTypes.number,
  setPage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  setRowsPerPage: PropTypes.func,
  columnToSort: PropTypes.string,
  sortDirection: PropTypes.string,
  getFilteredArticles: PropTypes.func,
  startEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  handleSort: PropTypes.func,
  startCopy: PropTypes.func,
  selectArticle: PropTypes.func,
  deselectArticle: PropTypes.func,
  tablePaginationHandle: PropTypes.func
}

export default ArticlesGrid
