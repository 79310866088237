import { AxiosPromise } from 'axios'
import { Newsletter, NewsletterListItem } from 'models/newsletter'

import { AxiosWithToken } from './axios-helpers'
/**
 * Should hold all axios logic dealing with /newsletter
 * - all crud for news letter covered here
 */
const NewsletterAxios = AxiosWithToken(`${process.env.REACT_APP_API_URL}/newsletter`)

export const getAllNewsletters = (params: {
  page: number
  limit: number
  sort?: { sortBy: string; orderBy: string }
  filter: { dateType: string; dateFrom: Date; dateTo: Date }
  // TODO: Types for statuses need to be changed after migration it should be 'draft', 'published'...
  statuses?: string[]
}): AxiosPromise<{ data: NewsletterListItem[]; totalNewsletters: number }> =>
  NewsletterAxios.get(`/`, {
    params: {
      page: params.page ? params.page : 0,
      limit: params.limit ? params.limit : 10,
      sortBy: params.sort && params.sort.sortBy,
      orderBy: params.sort && params.sort.orderBy,
      dateType: params.filter.dateType,
      dateFrom: params.filter.dateFrom,
      dateTo: params.filter.dateTo,
      statuses: params.statuses
    }
  })

export const getNewsLettersByTag = (params: {
  tagList: string
  limit: number
  page: number
  sort: string
}): AxiosPromise<Newsletter[]> => NewsletterAxios.get(`/tag`, { params })

export const getNewsletterById = (newsletterId: string): AxiosPromise<Newsletter> =>
  NewsletterAxios.get(`${newsletterId}`)

export const getNewsletters = (params: {
  audience: string[]
  statuses?: string[]
}): AxiosPromise<Newsletter[] | string> => {
  return NewsletterAxios.get('', { params })
}
export const getNewslettersValid = (params: {
  audience: string[]
  statuses?: string[]
}): AxiosPromise<Newsletter[] | string> => {
  return NewsletterAxios.get('/valid/list', { params })
}
export const getNewslettersByOwnerId = (params: {
  ownerId: string
  limit?: number
  page?: number
  sort?: { sortBy: string; orderBy: string }
  filter?: { dateType: string; dateFrom: Date; dateTo: Date }
}): AxiosPromise<{ data: NewsletterListItem[]; totalNewsletters: number }> =>
  NewsletterAxios.get(`/owner/${params.ownerId}`, {
    params: {
      page: params.page ? params.page : 0,
      limit: params.limit ? params.limit : 10,
      sortBy: params.sort && params.sort.sortBy ? params.sort.sortBy : 'datePublished',
      orderBy: params.sort && params.sort.orderBy ? params.sort.orderBy : 'desc',
      dateType: params.filter && params.filter.dateType ? params.filter.dateType : '',
      dateFrom: params.filter && params.filter.dateFrom ? params.filter.dateFrom : '',
      dateTo: params.filter && params.filter.dateTo ? params.filter.dateTo : ''
    }
  })

export const getNewslettersByArticleId = (articleId: string): AxiosPromise<Newsletter[]> =>
  NewsletterAxios.get(`/article/${articleId}`)

export const createNewsletter = (newsletter: Newsletter): AxiosPromise<Newsletter> =>
  NewsletterAxios.post('/add', newsletter)

export const updateNewsletter = (newsletterId: string, patch: Partial<Newsletter>): AxiosPromise<string> =>
  NewsletterAxios.patch(`/update/${newsletterId}`, patch)

export const deleteNewsletterById = (newsletterId: string): AxiosPromise<string> =>
  NewsletterAxios.delete(`delete/${newsletterId}`)
