import { toast, ToastId } from 'react-toastify'

type ToastType = 'SUCCESS' | 'ERROR' | 'WARNING' | 'LOGGED_OUT'

const toaster = (type: ToastType, content): ToastId | null => {
  switch (type) {
    case 'SUCCESS':
      return toast.success(content, {
        position: toast.POSITION.TOP_CENTER,
        className: 'toast-success',
        hideProgressBar: true
      })
    case 'ERROR':
      return toast.error(content, {
        position: toast.POSITION.TOP_CENTER,
        className: 'toast-error',
        hideProgressBar: true
      })
    case 'WARNING':
      return toast.warn(content, {
        position: toast.POSITION.TOP_CENTER,
        className: 'toast-error',
        hideProgressBar: true
      })
    case 'LOGGED_OUT':
      return toast(content, {
        position: toast.POSITION.TOP_CENTER,
        className: 'toast-error',
        autoClose: false
      })
    default:
      return null
  }
}

export default toaster
