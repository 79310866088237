import * as React from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { TAGS } from 'consts'
//**Dialog component to add or edit tag */
const TagDialog = props => {
  const [name, setName] = React.useState('')
  const [type, setType] = React.useState('CLIENT')
  const [_id, set_Id] = React.useState('')
  const [dialogType, setDialogType] = React.useState('add')

  React.useEffect(() => {
    if (props.currentTag) {
      setDialogType('update')
      set_Id(props.currentTag._id)
      setName(props.currentTag.name)
      setType(props.currentTag.type)
    } else {
      setDialogType('add')
      set_Id('')
      setName('')
      setType('')
    }
  }, [props.currentTag])

  return (
    <Dialog fullWidth={true} open={props.open} onClose={props.handleClose} data-cy="tagDialog">
      <DialogTitle>{dialogType === 'add' ? 'Add New Tag' : 'Edit Tag'}</DialogTitle>
      <DialogContent>
        <Box
          noValidate
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            m: 'auto',
            width: 'fit-content'
          }}
        >
          <FormControl sx={{ mt: 2, minWidth: 120 }}>
            <InputLabel htmlFor="tag-type">Type</InputLabel>
            <Select
              label="Type"
              value={type}
              onChange={e => setType(e.target.value)}
              inputProps={{
                name: 'tag-type',
                id: 'tag-type'
              }}
            >
              <MenuItem value="CLIENT">{TAGS.CLIENT}</MenuItem>
              <MenuItem value="LOS">{TAGS.LOS}</MenuItem>
              <MenuItem value="BIZ">{TAGS.BIZ}</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="standard">
            <InputLabel htmlFor="name">Name</InputLabel>
            <Input id="name" value={name} onChange={e => setName(e.target.value)} />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={dialogType === 'update' && props.currentTag.name === name && props.currentTag.type === type}
          onClick={() => props.handleTag({ _id, name, type }, dialogType)}
        >
          Submit
        </Button>
        <Button onClick={props.handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
export default TagDialog
