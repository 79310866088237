import Paper from '@material-ui/core/Paper'
import styled, { css } from 'styled-components'
import { ArticleParagraph, Newsletter, NextRounded } from '../Icons'

export const CTA = styled.div`
  margin-top: -64px;
  background-color: transparent;
  background: linear-gradient(to bottom, transparent 20%, whitesmoke 20% 100%);
  @media (max-width: 729px) {
    background: linear-gradient(to bottom, transparent 20%, whitesmoke 20% 100%);
  }
`

export const CTAPaper = styled(Paper)`
  border: 2px solid rgba(71, 136, 46, 0.36);
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  position: relative;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.4) 3.42%,
    rgba(179, 224, 110, 0.32) 76.36%,
    rgba(84, 140, 63, 0.32) 112.17%
  );
  margin: 0px 24px;
  display: flex;
  align-items: stretch;
  justify-content: center;
  padding: 28px;
  gap: 28px;
  && .cta-btn,
  .cta-info {
    flex: 1 1 0px;
    max-width: 416px;
  }
  @media (max-width: 799px) {
    margin: 0px 14px;
    padding:14px;
    && .cta-arrow {
      display: none;
    }
    gap: 16px;
    && .cta-title {
      font-size: 21px;
    }
    && .cta-subtitle {
      font-size: 16px;
      line-height: 20px;
    }
    && .cta-btn-icon {
      flex: 0;
    }
  }
  @media (max-width: 729px) {
    flex-direction: column;
    padding: 20px;
    gap: 12px;
    && .cta-btn,
    .cta-info {
      flex: 1 1 0px;
      max-width: 100%;
      align-self: stretch;
    }
  }
`

export const Info = styled.div`
  padding: 5px 0px 0px 10px;
`

export const Title = styled.p`
  color: #344348;
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 4px;
  line-height: 29px;
`

export const Subtitle = styled.p`
  font-weight: 400;
  font-size: 19px;
  line-height: 22px;
  letter-spacing: 0.16px;
  color: #404f54;
  margin-bottom: 0px;
`

export const Arrow = styled(NextRounded)`
  width: 24px;
  margin-left: 10px;
  cursor: default;
`

const IconStyle = css`
  width: 32px;
  height: 32px;
  margin-right: 14px;
`

export const NewsletterIcon = styled(Newsletter)`
  ${IconStyle}
`

export const ArticleIcon = styled(ArticleParagraph)`
  ${IconStyle}
`
