import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon from '@material-ui/icons/Delete'
import { Button, Row } from 'react-bootstrap'
import DownArrow from '@material-ui/icons/KeyboardArrowDownRounded'
import UpArrow from '@material-ui/icons/KeyboardArrowUpRounded'
import './../adminComponent.css'
import Loader from '../../loader/loader.component'
import PropTypes from 'prop-types'

/** Definition of columns displayed in users table */
const columns = [
  {
    id: '_id',
    label: 'User',
    minWidth: 40,
    maxWidth: '50px',
    overFlow: 'hidden',
    textOverflow: 'ellipsis',
    align: 'left'
  },
  {
    id: 'name',
    label: 'Name',
    minWidth: 40,
    maxWidth: '50px',
    overFlow: 'hidden',
    textOverflow: 'ellipsis',
    align: 'left'
  },
  { id: 'role', label: 'Role', minWidth: 40, align: 'left' },
  {
    id: 'audience',
    label: 'Audience',
    minWidth: 40,
    align: 'center',
    format: value => value.toLocaleString('en-US')
  },
  {
    id: 'lastLogin',
    label: 'Last Login',
    minWidth: 40,
    align: 'center'
  },
  {
    id: 'edit',
    label: '',
    align: 'right'
  },
  {
    id: 'delete',
    label: '',
    align: 'right',
    format: value => value.toFixed(2)
  }
]

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: 440
  },
  header: {
    textAlign: 'center',
    paddingTop: '1%',
    color: '#000'
  },
  arrow: {
    fontSize: '17px',
    color: 'gray'
  }
})

/** Component representing "User Management" table in the Admin section */
const AdminTable = props => {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [userslist, setUserslist] = useState([])

  useEffect(() => {
    setUserslist(props.users)
  }, [props.users])

  useEffect(() => {
    if (props.users !== null) {
      setUserslist(() =>
        props.users.filter(user => {
          return (
            user.name.toLowerCase().includes(props.filterKey.toLowerCase()) ||
            user._id.includes(props.filterKey) ||
            user.role.includes(props.filterKey)
          )
        })
      )
    }
    setPage(0)
  }, [props.filterKey, props.users])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const formatTags = audiences => {
    const audienceArray = []
    for (let x = 0; x < audiences.length; x++) {
      if (audiences[x].currentAudience === true) {
        audienceArray.push(audiences[x].name)
      }
    }
    const audienceString = audienceArray.join('; ')
    return audienceString
  }

  const handleFilterChange = e => {
    e.preventDefault()
    props.handleFilterChange(e.target.value.toLowerCase())
  }

  return (
    <>
      <div data-cy="usersTable" className="admin-table">
        <h1 className={classes.header}>User Management</h1>
        <Row>
          <div className="col-auto mb-2">
            <h5>Filter</h5>
            <input
              id="filter-articles"
              value={props.filterKey}
              onChange={e => handleFilterChange(e)}
              className="form-control"
            />
          </div>
        </Row>
        <hr />
        <Paper className={classes.root}>
          {props.isLoading ? (
            <Loader id="pageLoadingBar" />
          ) : (
            <Table stickyHeader aria-label="sticky table" style={{ margin: '0 auto' }}>
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      onClick={() => props.handleUserSort(column)}
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {props.columnToSort === column.id ? (
                        props.sortDirection === 'asc' ? (
                          <UpArrow className={classes.arrow} />
                        ) : (
                          <DownArrow className={classes.arrow} />
                        )
                      ) : null}
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {userslist &&
                  userslist.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(currentUser => {
                    return (
                      <TableRow data-cy="tableRow" hover role="checkbox" tabIndex={-1} key={currentUser._id}>
                        {columns.map(column => {
                          const value = currentUser[column.id]
                          if (column.id === 'edit') {
                            return (
                              <TableCell data-cy="edit" key={column.id} align={column.align} style={{ width: '20px' }}>
                                <span
                                  className="d-inline-block float-right"
                                  tabIndex="0"
                                  data-toggle="tooltip"
                                  title="Click edit"
                                >
                                  <CreateIcon className="edit-buttons" onClick={() => props.startEdit(currentUser)} />
                                </span>
                              </TableCell>
                            )
                          } else if (column.id === 'delete') {
                            return (
                              <TableCell
                                data-cy="delete"
                                key={column.id}
                                align={column.align}
                                style={{ width: '20px' }}
                              >
                                <Button variant="extendedFab" onClick={() => props.delete(currentUser)}>
                                  <DeleteIcon className={classes.icon} />
                                </Button>
                              </TableCell>
                            )
                          } else if (column.id === 'audience') {
                            const audienceList = formatTags(value)
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {audienceList}
                              </TableCell>
                            )
                          }
                          return (
                            <TableCell
                              key={column.id}
                              title={value}
                              align={column.align}
                              style={{
                                maxWidth: column.maxWidth,
                                overflow: column.overFlow,
                                textOverflow: column.textOverflow
                              }}
                            >
                              {column.format && typeof value === 'number' ? column.format(value) : value}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          )}

          {userslist && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={userslist.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
        <div className="add-user-button">
          <Button value="button" variant="primary" onClick={() => props.setShowModal(true)}>
            Add New User
          </Button>
        </div>
      </div>
    </>
  )
}

/**
 * Prop-types description
 *    users - users to be displayed
 *    columnToSort - id of column to sort by
 *    sortDirection - sorting direction asc or desc
 *    isLoading - whether to show loading bar (true) or data (false)
 */
AdminTable.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
  columnToSort: PropTypes.string,
  sortDirection: PropTypes.string,
  isLoading: PropTypes.bool,
  startEdit: PropTypes.func,
  delete: PropTypes.func,
  reset: PropTypes.func,
  filterKey: PropTypes.string,
  handleFilterChange: PropTypes.func,
  setShowModal: PropTypes.func,
  handleUserSort: PropTypes.func
}

export default AdminTable
