import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon from '@material-ui/icons/Delete'
import { Button, Row } from 'react-bootstrap'
import DownArrow from '@material-ui/icons/KeyboardArrowDownRounded'
import UpArrow from '@material-ui/icons/KeyboardArrowUpRounded'
import './../adminComponent.css'
import Loader from '../../loader/loader.component'
import PropTypes from 'prop-types'

/** Definition of columns displayed in users table */
const columns = [
  { id: 'name', label: 'Audience Name', minWidth: 40, align: 'left' },
  { id: 'tags', label: 'Audience Tags', minWidth: 40, align: 'left' },
  {
    id: 'edit',
    label: '',
    align: 'right'
  },
  {
    id: 'delete',
    label: '',
    align: 'right',
    format: value => value.toFixed(2)
  }
]

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: 440
  },
  header: {
    textAlign: 'center',
    paddingTop: '1%',
    color: '#000'
  },
  arrow: {
    fontSize: '17px',
    color: 'gray'
  },
  cell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
})

/** Component representing "Audiences" table in the Admin section */
const AudienceTable = props => {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [audiencelist, setAudiencelist] = useState([])

  useEffect(() => {
    setAudiencelist(props.audiences)
  }, [props.audiences])

  useEffect(() => {
    if (props.audiences !== null) {
      setAudiencelist(() =>
        props.audiences.filter(audience => {
          return (
            audience.name.toLowerCase().includes(props.filterKey.toLowerCase()) ||
            (audience.tags && audience.tags.join('').toLowerCase().includes(props.filterKey.toLowerCase()))
          )
        })
      )
    }
    setPage(0)
  }, [props.filterKey, props.audiences])

  const handleFilterChange = e => {
    e.preventDefault()
    props.handleFilterChange(e.target.value.toLowerCase())
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const formatTags = tags => {
    return tags.join('/')
  }

  return (
    <>
      <div data-cy="audiencesTable" className="admin-table">
        <h1 className={classes.header}>Audiences</h1>
        <Row>
          <div className="col-auto mb-2">
            <h5>Filter</h5>
            <input
              id="filter-articles"
              data-cy="audiencesFilterInput"
              value={props.filterKey}
              onChange={e => handleFilterChange(e)}
              className="form-control"
            />
          </div>
        </Row>
        <hr />
        <Paper className={classes.root}>
          {props.isLoading ? (
            <Loader id="pageLoadingBar" />
          ) : (
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow data-cy="tableHeader">
                  {columns.map(column => (
                    <TableCell
                      onClick={() => props.handleAudienceSort(column)}
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {' '}
                      {props.audienceSort.column === column.id ? (
                        props.audienceSort.order === 'asc' ? (
                          <UpArrow className={classes.arrow} />
                        ) : (
                          <DownArrow className={classes.arrow} />
                        )
                      ) : null}
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {audiencelist &&
                  audiencelist.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(audience => {
                    return (
                      <TableRow data-cy="tableRow" hover role="checkbox" tabIndex={-1} key={audience.name}>
                        {columns.map(column => {
                          const value = audience[column.id]
                          if (column.id === 'edit') {
                            return (
                              <TableCell data-cy="edit" key={column.id} align={column.align} style={{ width: '20px' }}>
                                <span
                                  className="d-inline-block float-right"
                                  tabIndex="0"
                                  data-toggle="tooltip"
                                  title="Click edit"
                                >
                                  <CreateIcon className="edit-buttons" onClick={() => props.startEdit(audience)} />
                                </span>
                              </TableCell>
                            )
                          } else if (column.id === 'delete') {
                            return (
                              <TableCell
                                data-cy="delete"
                                key={column.id}
                                align={column.align}
                                style={{ width: '20px' }}
                              >
                                <Button variant="extendedFab" onClick={() => props.delete(audience)}>
                                  <DeleteIcon className={classes.icon} />
                                </Button>
                              </TableCell>
                            )
                          } else if (column.id === 'tags') {
                            const tagList = formatTags(value)
                            return (
                              <TableCell className={classes.cell} key={column.id} align={column.align}>
                                {tagList}
                              </TableCell>
                            )
                          }
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number' ? column.format(value) : value}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          )}

          {audiencelist && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={audiencelist.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
        <div className="add-user-button">
          <Button value="button" variant="primary" data-cy="addAudienceButton" onClick={() => props.setShowModal(true)}>
            Add New Audience
          </Button>
        </div>
      </div>
    </>
  )
}

/**
 * Prop-types description
 *    audiences - users to be displayed
 *    columnToSort - id of column to sort by
 *    sortDirection - sorting direction asc or desc
 *    isLoading - whether to show loading bar (true) or data (false)
 */
AudienceTable.propTypes = {
  audiences: PropTypes.arrayOf(PropTypes.object),
  audienceSort: PropTypes.shape({
    column: PropTypes.string,
    order: PropTypes.string
  }),
  isLoading: PropTypes.bool,
  setShowModal: PropTypes.func,
  startEdit: PropTypes.func,
  delete: PropTypes.func,
  filterKey: PropTypes.string,
  handleFilterChange: PropTypes.func,
  handleAudienceSort: PropTypes.func
}

export default AudienceTable
