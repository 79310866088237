import React, { useCallback, useEffect, useState } from 'react'
import { Button, Container, Row, Col } from 'react-bootstrap'
import Select from 'react-select'
import DayPicker from 'react-day-picker'
import { Newsletter } from '../../../models/newsletter'
import { useHistory } from 'react-router-dom'
import 'react-day-picker/lib/style.css'
import '../../article/form/article-form.component.css'
import './newsletter-form.component.css'
import { useError } from 'context/error-context'

const NewsletterForm = props => {
  const [saveAsDraft, setSaveAsDraft] = useState(false)
  const [isReadyToPublish, setIsReadyToPublish] = useState(false)
  const [tagOptions, setTagOptions] = useState([])
  const [audience, setAudience] = useState('User has no audiences')
  const [articles] = useState([])
  const [sections, setSections] = useState(props.sections)
  const [dateCreated] = useState(props.newsletter ? props.newsletter.dateCreated : new Date())
  const [datePublished, setDatePublished] = useState(props.newsletter ? props.newsletter.datePublished : new Date())
  const [calendarSelectedDate, setCalendarSelectedDate] = useState(new Date())
  const [title, setTitle] = useState('')
  const [owner, setOwner] = useState(props.owner)
  const [publishStatus, setPublishStatus] = useState(props.newsletter ? props.newsletter.publishStatus : '')
  const [isSaved, setIsSaved] = useState(false)
  const history = useHistory()
  const { setError } = useError()

  const mapAudiencesToSelectOptions = useCallback(
    audienceList => {
      const finalList = []
      audienceList.map(t => {
        if (t.currentAudience === true) {
          finalList.push({
            name: t.name,
            value: t.name,
            label: t.name,
            _id: t._id,
            tags: t.tags
          })
        }
        return undefined
      })
      if (finalList.length > 0) {
        setAudience(finalList[0])
        setTitle(finalList[0].name, datePublished)
        return finalList
      }
      setError({title: 'No audiences have been assigned to you', description: 'Redirecting you to the homepage'})
      history.push('/')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [datePublished]
  )

  const generateAudienceList = useCallback(() => {
    const audienceList = []

    for (let x = 0; x < props.owner.audience.length; x++) {
      audienceList.push(props.owner.audience[x])
    }

    const options = mapAudiencesToSelectOptions(audienceList)
    setTagOptions(options)
  }, [props.owner.audience, mapAudiencesToSelectOptions])

  useEffect(() => {
    generateAudienceList()
    setOwner(props.owner)
  }, [props.owner, generateAudienceList])

  const handleReturn = event => {
    event.preventDefault()

    const newsletter = getNewsletterModelFromForm()

    props.returnFunction(newsletter, saveAsDraft, isReadyToPublish)
    setIsSaved(true)
    setSaveAsDraft(false)
    setIsReadyToPublish(false)
  }

  const getNewsletterModelFromForm = () => {
    return new Newsletter({
      title,
      articles,
      sections,
      dateCreated,
      datePublished,
      audience,
      owner,
      publishStatus
    })
  }

  const setNewsletterTitle = (audience, date) => {
    setTitle(`${audience} - ${date.toLocaleDateString()}`)
  }

  const handleFormValueChange = newAudience => {
    setAudience(newAudience)
    setNewsletterTitle(newAudience.name, datePublished)
  }

  const draftANewsletter = () => {
    setSections(props.sections)
    setSaveAsDraft(true)
    setPublishStatus('Draft')
  }

  const handleCancel = () => {
    props.handleCancel()
  }

  const handleDateSelect = day => {
    setDatePublished(day)
    setCalendarSelectedDate(day)
    setNewsletterTitle(audience.name, day)
  }

  return (
    <React.Fragment>
      <form onSubmit={handleReturn} className="container text-left p-4 rounded" id="form-container">
        <Container>
          <div className="form-group title-width">
            <p>Title: {title}</p>
          </div>
          <Row>
            <Col>
              <label>Planned Publish Date:</label>
              <div className="container">
                <div className="SelectedWeekExample">
                  <DayPicker
                    value={datePublished}
                    onDayClick={e => handleDateSelect(e)}
                    selectedDays={calendarSelectedDate}
                    handleDateSelect={handleDateSelect}
                    showOutsideDays
                  />
                </div>
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label>Audience:</label>
                <Select
                  id="audience"
                  options={tagOptions}
                  onChange={handleFormValueChange}
                  onLoad={handleFormValueChange}
                  value={audience}
                />
              </div>
            </Col>
          </Row>
        </Container>
        <div className="button-container">
          <div className="space-maker" />
          {props.isDraft && (
            <Button
              onClick={() => draftANewsletter()}
              type="submit"
              className="img-btns button-color"
              disabled={isSaved}
              data-cy="save"
            >
              Save
            </Button>
          )}
          <Button disabled={props.isSubmitting} onClick={handleCancel} className="img-btns button-color">
            Cancel
          </Button>
        </div>
      </form>
    </React.Fragment>
  )
}

export default NewsletterForm
