import React, { useState, useEffect, useCallback, useContext } from 'react'
import './HeaderAndFooter.css'
import toaster from '../toast/toast.component'
import { checkForUserDataConsistency } from 'utils/dataConsistency'
import { getAudienceById, patchAudience } from 'apis/audience.service'
import { useHistory } from 'react-router-dom'
import { UserContext } from 'context/user-context'
import Footer from './footer/footer'
import Header from './header/header'
import { useError } from 'context/error-context'
import { Select, FormControl, Container, Paper, Button, Tooltip } from '@material-ui/core'
import { useConfirmDialog } from 'context/confirm-dialog-context'

/**
 * Main component visible after going to "Header and Footer" page
 * Provides functionalities to select audiences and view/edit header & footer for each of them
 *
 * Consits of the following components:
 *    Header - header edit and preview
 *    Footer - footer edit and preview
 *
 * Its state variables are:
 *    @property {User} user - currently logged in user, retrieved from context
 *    @property {String} audienceId - ID of currently selected audience
 *    @property {Boolean} isLoading - defines if component is currently in the data loading state
 *    @property {String[]} headerImgs - deprecated, to be removed here and from backend
 *    @property {String[]} footerImgs - deprecated, to be removed here and from backend
 *    @property {String} headerContent - content of the header
 *    @property {String} footerContent - content of the footer
 */
const HeaderAndFooter = () => {
  const { user, getUser } = useContext(UserContext)
  const [audienceId, setAudienceId] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [audience, setAudience] = useState({ header: { imgs: [], content: '' }, footer: { imgs: [], content: '' } })
  const [headerImgs, setHeaderImgs] = useState([])
  const [footerImgs, setFooterImgs] = useState([])
  const [headerContent, setHeaderContent] = useState()
  const [footerContent, setFooterContent] = useState()
  const { setConfirmDialogSettings, setOpenConfirmDialog } = useConfirmDialog()

  const history = useHistory()

  const { setError } = useError()

  useEffect(() => {
    if (user && user.audience) setIsLoading(false)

  // eslint-disable-next-line
  }, [user])

  useEffect(() => {
    if (audienceId) {
      getAudienceById(audienceId)
        .then(res => {
          setAudience(res.data[0])
        })
        .catch(() =>
          setError({ title: 'Could not get the selected audience details', description: 'Try logging in again' })
        )
    } else {
      setAudience({ header: { imgs: [], content: '' }, footer: { imgs: [], content: '' } })
    }
    // eslint-disable-next-line
  }, [audienceId])

  /**
   * Used to save header and footer
   * Sends header and footer to backend and shows success message if it was successful
   * @param {?} e - clicked button context
   */
  const saveHeaderAndFooter = e => {
    e.preventDefault()

    patchAudience(audienceId, {
      header: { imgs: headerImgs, content: headerContent },
      footer: { imgs: footerImgs, content: footerContent }
    }).then(res => {
      toaster('SUCCESS', 'Header and Footer Saved!')
      checkForUserDataConsistency(res.data)
      getUser()
      history.push('/')
    })
  }

  /**
   * Handles change of audience selection
   * @param {?} e - Context of the clicked button
   */
  const changeAudience = e => {
    e.preventDefault()
    setAudienceId(e.target.value)
    setAudience({ header: { imgs: [], content: '' }, footer: { imgs: [], content: '' } })
  }

  /** Restores header and footer from the database */
  const cancelEdits = useCallback(() => {
    setConfirmDialogSettings({
      handleClose: () => {
        setOpenConfirmDialog(false)
      },
      handleConfirm: async () => {
        try {
          setOpenConfirmDialog(false)
          setHeaderContent(audience.header.content)
          setFooterContent(audience.footer.content)
          setFooterImgs(audience.footer.imgs)
          setHeaderImgs(audience.header.imgs)
        } catch (error) {
          setError(error)
        }
      },
      title: 'Cancel edit',
      text: 'Are you sure you want to cancel? Any unsaved changes may be lost.'
    })
    setOpenConfirmDialog(true)
    // eslint-disable-next-line
  }, [audience])

  return (
    <>
      <Container maxWidth="lg" className="container">
        <Paper>
          <div className="paper-container p-4">
            {isLoading ? (
              <div style={{ marginTop: '5em', textAlign: 'center' }}>Loading audiences…</div>
            ) : (
              <>
                <FormControl variant="outlined" fullWidth>
                  <Select native={true} id="audience-select" value={audienceId} autoWidth onChange={changeAudience}>
                    <option disabled value="">
                      Select Audience
                    </option>
                    {user &&
                      user.audience &&
                      user.audience
                        .filter(_ => _.currentAudience)
                        .map((value, index) => (
                          <option key={index} value={value._id}>
                            {value.name}
                          </option>
                        ))}
                  </Select>
                  <Header
                    audienceId={audienceId}
                    setAudience={setAudience}
                    headerContent={headerContent}
                    setHeaderContent={setHeaderContent}
                    headerImgs={headerImgs}
                    setHeaderImgs={setHeaderImgs}
                  />
                  <Footer
                    audienceId={audienceId}
                    setAudience={setAudience}
                    footerContent={footerContent}
                    setFooterContent={setFooterContent}
                    footerImgs={footerImgs}
                    setFooterImgs={setFooterImgs}
                  />
                  <div className="button-group">
                    {audienceId ? (
                      <>
                        <Button
                          onClick={() => cancelEdits()}
                          variant="outlined"
                          style={{ fontWeight: 600, color: '#538b3f', borderColor: '#538b3f' }}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={saveHeaderAndFooter}
                          variant="contained"
                          style={{
                            fontWeight: 600,
                            color: 'rgba(255, 255, 255, 0.87)',
                            backgroundColor: '#538b3f'
                          }}
                        >
                          Save
                        </Button>
                      </>
                    ) : (
                      <>
                        <Tooltip title="Please Select an Audience">
                          <span>
                            <Button
                              disabled
                              variant="outlined"
                              style={{
                                fontWeight: 600,
                                color: 'rgba(83, 139, 63, 0.26)',
                                borderColor: 'rgba(83, 139, 63, 0.26)'
                              }}
                            >
                              Cancel
                            </Button>
                          </span>
                        </Tooltip>
                        <Tooltip title="Please Select an Audience">
                          <span>
                            <Button
                              disabled
                              variant="contained"
                              style={{
                                fontWeight: 600,
                                color: 'rgba(255, 255, 255, 0.50)',
                                backgroundColor: 'rgba(83, 139, 63, 0.26)'
                              }}
                            >
                              Save
                            </Button>
                          </span>
                        </Tooltip>
                      </>
                    )}
                  </div>
                </FormControl>
              </>
            )}
          </div>
        </Paper>
      </Container>
    </>
  )
}

export default HeaderAndFooter
