import { createTheme } from '@material-ui/core'
export const colors = {
  primary: '#538b3f'
}
export const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary
    }
  }
})
