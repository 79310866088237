import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import parse from 'html-react-parser'
import { ToolSet } from './ToolSet'
import {
  ArticleEditorWrapper,
  Editor,
  EditorHeader,
  EditorContent,
  Preview,
  ToolSetWrapper
} from './ArticleEditorStyles'
import { htmlTemplate } from './articleEditorData'

//Html to be sanitized / pending implementation
const localHtml = localStorage.getItem('html') || htmlTemplate


export const ArticleEditor = () => {
  let mainref = useRef(null)

  const [elementSelected, setSelectedElement] = useState<HTMLElement>()
  const [sectionSelected, setSelectedSection] = useState<HTMLElement>()
  //local storage commented for working without internet connection / pending implementation
  useEffect(() => {
    let editableTexts = document.querySelectorAll(`[data-type*='text']`)
    editableTexts.forEach(editableText => {
      let textElement = ReactDOM.findDOMNode(editableText) as HTMLElement

      textElement.addEventListener('click', (event: MouseEvent) => {
        let element = event.target as HTMLElement
        // localStorage.setItem('html', mainref.current?.innerHTML)
        setSelectedElement(element)
      })
    })

    let sections = document.querySelectorAll(`[data-type*='section']`)
    sections.forEach(section => {
      let sectionElement = ReactDOM.findDOMNode(section) as HTMLElement
      sectionElement.addEventListener('click', (event: MouseEvent) => {
        let element = event.target as HTMLElement
        // localStorage.setItem('html', mainref.current?.innerHTML)
        setSelectedSection(element)
      })
    })
  }, [])

  return (
    <ArticleEditorWrapper>
      <EditorHeader>ARTICLE EDITOR</EditorHeader>
      <EditorContent>
        <Editor>
          <Preview ref={mainref}>{parse(localHtml)}</Preview>
        </Editor>
        <ToolSetWrapper>
          <ToolSet elementSelected={elementSelected} sectionSelected={sectionSelected} />
        </ToolSetWrapper>
      </EditorContent>
    </ArticleEditorWrapper>
  )
}
