import React, { useState, useContext } from 'react'
import { UserContext } from 'context/user-context'
import './MenuBar.css'
import { useHistory } from 'react-router-dom'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Drawer from '@material-ui/core/Drawer'
import Link from '@material-ui/core/Link'
import { ProfileButton } from '../ProfileButton/ProfileButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import IconButton from '@material-ui/core/IconButton'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import { ROLES, MENU_LIST_ITEMS } from '../../../consts'

const { ARTICLES, NEWSLETTERS, HEADER_FOOTER, ADMIN, GUIDE } = MENU_LIST_ITEMS

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      background: 'whitesmoke'
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    title: {
      flexGrow: 1,
      color: '#2c991d'
    },
    paper: {
      width: 250
    }
  })
)

const generateMenuListItems = ({ user, history, setDrawerOpen }) => {
    const buildListItems = permittedListItems => {
      return permittedListItems.map((menuLink, i) => {
        let showItem = (window.location.pathname !==  menuLink?.PATH) ? true : false
        return (  
          <ListItem
            key={i}
            className="list-item"
            component="a"
            onClick={() => {
              if(showItem) {history.push(`${menuLink?.PATH}`)
              setDrawerOpen(false)}
            }}
          >{menuLink?.LABEL_NAME}</ListItem>
        )
      })
    }

  if (user.role === ROLES.ADMIN) {
    const permittedListItems = [
      ARTICLES.CREATE,
      ARTICLES.MY,
      ARTICLES.ALL,
      NEWSLETTERS.CREATE,
      NEWSLETTERS.MY,
      NEWSLETTERS.ALL,
      HEADER_FOOTER,
      ADMIN
    ]
    return buildListItems(permittedListItems)
  }

  if (user.role === ROLES.USER) {
    const permittedListItems = [
      ARTICLES.CREATE,
      ARTICLES.MY,
      ARTICLES.ALL,
      NEWSLETTERS.CREATE,
      NEWSLETTERS.MY,
      NEWSLETTERS.ALL,
      HEADER_FOOTER
    ]
    return buildListItems(permittedListItems)
  }
  // If for some reaon the user.role is not found return 0 links
  const permittedListItems = []
  return buildListItems(permittedListItems)
}

export const MenuBar = ({ instance = 'prod' }) => {
  const classes = useStyles()
  const history = useHistory()
  const { user } = useContext(UserContext)
  const [drawerOpen, setDrawerOpen] = useState(false)

  return (
    <div className={classes.root}>
      <AppBar position="static" className={`${classes.root} menu-bar instance-${instance}`}>
        <Toolbar className="tool-bar">
          <Drawer
            open={drawerOpen}
            className="drawer"
            variant="persistent"
            anchor="left"
            classes={{ paper: classes.paper }}
          >
            <div className="drawer-link">
              <IconButton onClick={() => setDrawerOpen(false)} style={{ borderRadius: 0 }}>
                <ChevronLeft className="chevron-left-icon" color="inherit" />
              </IconButton>
              <Divider />
              {window.location.pathname === '/' ? null : (
                <ListItem
                  className="list-item"
                  component="a"
                  onClick={() => {
                    history.push('/')
                    setDrawerOpen(false)
                  }}
                >
                  Home
                </ListItem>
              )}
              {user
                ? generateMenuListItems({ user, history, setDrawerOpen })
                : 'Could not load the menu links, try logging in again'}

              <ListItem
                key="user-guide-key"
                className="list-item"
                component="a"
                onClick={() => {
                  setDrawerOpen(false)
                  return window.open(GUIDE.PATH, '_blank').focus()
                }}
              >
                {GUIDE.LABEL_NAME}
              </ListItem>
            </div>
          </Drawer>
          <div className="left-links">
            <IconButton
              onClick={() => {
                setDrawerOpen(true)
              }}
            >
              <MenuIcon className="menu-icon" color="inherit" />
            </IconButton>
            <Link className="logo-link" onClick={() => history.push('/')}>
              <img src={require('../../../Daugherty-Logo.png')} id="logo" alt="Daugherty Newsletter Logo" />
            </Link>
          </div>
          <div className="right-links">{user ? <ProfileButton /> : 'Loading..'}</div>
        </Toolbar>
      </AppBar>
    </div>
  )
}
