import { Box, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { Newsletter } from 'models/newsletter'
import React, { useEffect } from 'react'
import { ROLES } from '../../../../consts'
import { Button, FormLabel } from 'react-bootstrap'
import Loader from '../../../loader/loader.component'

type USER_ROLES_KEYS = keyof typeof ROLES

type USER_ROLES_VALUES = typeof ROLES[USER_ROLES_KEYS]

const showIfEPA = userRole => [ROLES.USER, ROLES.ADMIN].includes(userRole)

type Props = {
  loadingNewsletters: boolean
  newsletters: Newsletter[]
  handleNewsletterChange: (selectedNewsletterId: string) => void
  defaultNewsletterId: string
  role: USER_ROLES_VALUES
}

const ArticleToNewsletter = ({
  handleNewsletterChange,
  defaultNewsletterId,
  newsletters,
  role,
  loadingNewsletters
}: Props) => {
  const [showNewsletters, setShowNewsletters] = React.useState<boolean>(false)

  useEffect(() => {
    if (defaultNewsletterId) {
      handleNewsletterChange(defaultNewsletterId)
    }
  }, [defaultNewsletterId, handleNewsletterChange])

  const showContent = () => {
    if (newsletters.length >= 1) {
      return (
        <Box className="border-box" style={{ height: '10em', overflowY: 'auto', overflowX: 'hidden' }}>
          <FormLabel id="newsletter-for-article">Available newsletters</FormLabel>
          <RadioGroup
            aria-labelledby="newsletter-for-article"
            defaultValue={defaultNewsletterId}
            name="newsletter-for-article"
          >
            {newsletters.map(({ _id: newsletterId, title, datePublished }) => (
              <FormControlLabel
                key={newsletterId}
                onChange={e => handleNewsletterChange(newsletterId)}
                value={newsletterId}
                control={<Radio />}
                label={`${title} ${datePublished.toString().split('T')[0]}`}
              />
            ))}
          </RadioGroup>
        </Box>
      )
    }

    return (
      <Box style={{ height: '2em', overflowY: 'auto', overflowX: 'hidden' }}>
        <label>No newsletters available</label>
      </Box>
    )
  }

  return (
    <>
      {showIfEPA(role) && (
        <div className="form-group">
          <label>Add To Newsletter</label>
          <br />

          {loadingNewsletters ? (
            <Loader justifyPosition="left" />
          ) : (
            <Button onClick={() => setShowNewsletters(prev => !prev)} className="img-btns button-color mb-2">
              Newsletters
            </Button>
          )}

          {showNewsletters && showContent()}
        </div>
      )}
      <br />
    </>
  )
}

export default React.memo(ArticleToNewsletter)
