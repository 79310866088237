import { AxiosPromise } from 'axios'
import User from 'models/user'
import { AxiosWithToken } from './axios-helpers'
const UserAxios = AxiosWithToken(`${process.env.REACT_APP_API_URL}/user`, 'token')

export const createUser = (user: User): AxiosPromise<string> => UserAxios.post('/signUp', user)

export const deleteUser = (user: String): AxiosPromise<string> => UserAxios.post(`/delete?userId=${user}`)

export const loginUser = (user: User): AxiosPromise<string> => UserAxios.post('/login', user)

export const changePassword = (userData: object): AxiosPromise<string> => UserAxios.post('/changePassword', userData)

export const resetUser = (userData: object): AxiosPromise<string> => UserAxios.post('/reset', userData)

export const resetConfirmUser = (userData: object): AxiosPromise<string> => UserAxios.post('/resetConfirm', userData)

export const confirmUser = (userData: object): AxiosPromise<string> => UserAxios.post('/confirm', userData)

export const verifyUser = (): AxiosPromise<User> => UserAxios.post('/verify')
