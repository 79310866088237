import React, { useCallback, useContext, useEffect, useState } from 'react'
import AddArticles from '../create/lists/add-articles-modal.component'
import CreateArticle from '../../article/create/create-article.component'
import { Button, Card, Modal } from 'react-bootstrap'
import AddIcon from '@material-ui/icons/Add'
import toaster from '../../toast/toast.component'
import EditPublishAndAudienceModal from './edit-publish-audience-modal.component'
import { Backdrop } from '../../backdrop'
import './edit-modal.css'
import { getNewsletterById, updateNewsletter, deleteNewsletterById } from 'apis/newsletter.service'
import { getArticle, getArticlesByIds, getArticlesByArticleIds, updateArticle } from 'apis/article.service'
import PreviewContainer from '../preview/preview-container.component'
import { UserContext } from 'context/user-context'
import SectionCard from './section-card.component'
import { useHistory, useLocation } from 'react-router-dom'
import { useError } from 'context/error-context'
import { useConfirmDialog } from 'context/confirm-dialog-context'
import { capitalizeEveryWord } from 'utils/helpers'
import { NEWSLETTER_STATUSES, ROLES } from 'consts'
import { Checkbox, FormControlLabel } from '@material-ui/core'


const EditNewsletter = props => {
  const { user } = useContext(UserContext)
  const { setConfirmDialogSettings, setOpenConfirmDialog } = useConfirmDialog()
  const { setError } = useError()
  const [articles, setArticles] = useState([])
  const [sections, setSections] = useState([])
  const [showArticlesModal, setShowArticlesModal] = useState(false)
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [newsletterPublishDate, setNewsletterPublishDate] = useState('')
  const [newsletterTitle, setNewsletterTitle] = useState('')
  const [newsletterAudienceId, setNewsletterAudienceId] = useState('')
  const isPrivilegedUser = user.role === ROLES.USER|| user.role === ROLES.ADMIN
  const [publishStatus, setPublishStatus] = useState('')
  const [currentNewsletterStatus, setCurrentNewsletterStatus] = useState()
  const [showCreateArticleModal, setShowCreateArticleModal] = useState(false)
  const [newArticleCreated, setNewArticleCreated] = useState(null)
  const [checked, setChecked] = useState(false)
  const history = useHistory()
  const location = useLocation()
  
  const getCurrentNewsletter = useCallback(async () => {
    try {
      const res = await getNewsletterById(props.match.params.id)
      const articlesResponse = await getArticlesByArticleIds(res.data.articles.filter(a => a))
      const newsletterStatus = capitalizeEveryWord(res.data.publishStatus)
      setIsLoading(false)
      setNewsletterPublishDate(res.data.datePublished)
      setNewsletterTitle(res.data.title)
      setNewsletterAudienceId(res.data.audience)
      setSections(res.data.sections)
      setPublishStatus(newsletterStatus)
      setCurrentNewsletterStatus(newsletterStatus)
  
      if (articlesResponse.data.length > 0) {
        setArticles(
          res.data.articles.map(a => {
            return articlesResponse.data.find(ar => ar._id === a)
          })
        )
      }
    } catch (error) {
      setError(error)
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.id])

  const saveNewsletter = () => {
    if (articles.length <= 0 && publishStatus === NEWSLETTER_STATUSES.PUBLISHED) {
      toaster('WARNING', 'This newsletter has no articles, please add one before publishing.')
      return
    }
  
    let finalStatus = "";

    if(publishStatus === NEWSLETTER_STATUSES.PUBLISHED){
      finalStatus = NEWSLETTER_STATUSES.PUBLISHED
    }
    else{
      if(location.state?.firstTimeCreated){
        finalStatus = NEWSLETTER_STATUSES.DRAFT
      }
      else{
        if(!unsavedChanges && currentNewsletterStatus === NEWSLETTER_STATUSES.DRAFT)
          finalStatus = NEWSLETTER_STATUSES.DRAFT
        else
          finalStatus = NEWSLETTER_STATUSES.EDITED
      }
    }

    articles.forEach(article => {
      article.status = finalStatus
      updateArticle(article)
    })
    updateNewsletter(props.match.params.id, {
      articles,
      sections,
      title: newsletterTitle,
      audience: newsletterAudienceId,
      datePublished: newsletterPublishDate,
      publishStatus: finalStatus
    })
      .then(() => {
        toaster('SUCCESS', 'Newsletter Saved')
        setUnsavedChanges(false)
        history.goBack()
      })
      .catch(err => setError(err))
  }

  const resetNewsletter = () => {
    getCurrentNewsletter()
    setUnsavedChanges(false)
  }

  const confirmUnsavedChanges = () => {
    setConfirmDialogSettings({
      handleClose: () => {
        setOpenConfirmDialog(false)
      },
      handleConfirm: async () => {
        setOpenConfirmDialog(false)
        try {
          await deleteNewsletterAndRedirect()
        } catch (error) {
          setError(error)
        }
      },
      title: 'Pending changes',
      text: 'Any unsaved changes will be lost. Do you want to continue?'
    })
    setOpenConfirmDialog(true)
  }

  const deleteNewsletterAndRedirect = async () => {
    await deleteNewsletterById(props.match.params.id)
    alert('Newsletter deleted. Redirecting to the My Newsletters page.')
    history.push('/mynewsletters')
  }

  const deleteNewsletter = () => {
    setConfirmDialogSettings({
      handleClose: () => {
        setOpenConfirmDialog(false)
      },
      handleConfirm: async () => {
        setOpenConfirmDialog(false)
        try {
          if (unsavedChanges) {
            confirmUnsavedChanges()
          } else {
            await deleteNewsletterAndRedirect()
          }
        } catch (error) {
          setError(error)
        }
      },
      title: 'Delete newsletter',
      text: 'Are you sure you would like to delete the opened newsletter?'
    })
    setOpenConfirmDialog(true)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const addArticlesToNewsletter = useCallback(async selectedArticlesIds => {
    // Remove nulls
    const articleList = articles.filter(a => a)
    let newArticleList = articleList
    try {
      // Remove any duplicates before adding selected articles
      const ids = articleList.map(article => article._id)
      if(ids.includes(selectedArticlesIds[0])){
        toaster("WARNING","Please check your articles, some of them has been added before")
      }else{
        if (Array.isArray(selectedArticlesIds)) {
          const articleIdsToAdd = selectedArticlesIds.filter(id => !ids.includes(id))
          const { data: articlesToAdd } = await getArticlesByIds(articleIdsToAdd)
  
          newArticleList = articleList.concat(articlesToAdd)
        } else if (!ids.includes(selectedArticlesIds)) {
          const { data: articleToAdd } = await getArticle(selectedArticlesIds)
          newArticleList.push(articleToAdd)
        }
  
        setShowArticlesModal(false)
        setArticles(newArticleList)
        setSections([...new Set(newArticleList.map(a => a.section))])
        setUnsavedChanges(true)
      }
    } catch (err) {
      setError(err)
      return
    }
  })

  const toogleShowCreateArticleModal = () => {
    setShowCreateArticleModal(showCreateArticleModal => !showCreateArticleModal)
  }

  const toggleArticleModal = () => {
    setShowArticlesModal(prevShowArticlesModal => !prevShowArticlesModal)
  }

  const togglePreviewModal = () => {
    setShowPreviewModal(prevShowPreviewModal => !prevShowPreviewModal)
  }

  const toggleEditModal = () => {
    setShowEditModal(prevShowEditModal => !prevShowEditModal)
  }
 

  const getName = audienceId => {
    let name = ''

    user.audience.forEach(audience => {
      if (JSON.stringify(audience._id) === JSON.stringify(audienceId)) {
        name = audience.name
      }
    })
    return name
  }

  const submitEditPublishAndAudience = (datePublished, audienceId) => {
    let name
    let date
    let id
    toggleEditModal()
    if (audienceId) {
      name = getName(audienceId)
      id = audienceId
    } else {
      name = getName(newsletterAudienceId)
      id = newsletterAudienceId
    }
    if (datePublished !== undefined) {
      date = datePublished.toLocaleDateString()
    } else {
      date = new Date(newsletterPublishDate)
      date = date.toLocaleDateString()
    }
    setNewsletterAudienceId(id)
    setNewsletterTitle(`${name} - ${date}`)
    setNewsletterPublishDate(datePublished)
    setUnsavedChanges(true)
  }
  const isNewsletterPublished = currentNewsletterStatus === NEWSLETTER_STATUSES.PUBLISHED
  const isReadOnly = props.isReadOnly || isNewsletterPublished

  const updatePublishStatus = (checked) => { 
    (checked) ? setPublishStatus(NEWSLETTER_STATUSES.PUBLISHED) : setPublishStatus(NEWSLETTER_STATUSES.EDITED)
  }

  const toogleChecked = () =>  { 
    setChecked(checked => !checked)
    updatePublishStatus(!checked);
    return !checked
  } 
  useEffect( ()=>{
    if(publishStatus === NEWSLETTER_STATUSES.PUBLISHED){
      
      setChecked(true)
    }
  },[publishStatus])

  useEffect(() => {
    getCurrentNewsletter();
  },[getCurrentNewsletter])

  useEffect(()=>{
    try{
      if(newArticleCreated){
        addArticlesToNewsletter(newArticleCreated._id)
        setUnsavedChanges(true)
      }
    }
    catch (err) {
      setError(err)
    }
    // eslint-disable-next-line
  },[newArticleCreated,setError])

  return isLoading ? (
    <Backdrop open />
  ) : (
    <>
      <AddArticles
        show={showArticlesModal}
        addArticlesToNewsletter={addArticlesToNewsletter}
        handleCancel={toggleArticleModal}
        handleSubmit={toggleArticleModal}
        chosenArticles={articles}
      />
      {showCreateArticleModal && (
        <Modal show={showCreateArticleModal} enforceFocus={false} className="articles-modal">
            <CreateArticle
              showModalButtons={true}
              isFromNewsletter={true}
              toogleShowCreateArticleModal={toogleShowCreateArticleModal}
              setNewArticleCreated={setNewArticleCreated}
            />
        </Modal>
      )}
      {showPreviewModal && (
        <PreviewContainer
          show={showPreviewModal}
          articles={articles}
          sections={sections}
          audienceId={newsletterAudienceId}
          handleCancel={togglePreviewModal}
        />
       
      )}

      <div className="container p-5 rounded newsletter">
        <header className="text-center">
          <h1 data-cy="newsletter-title">{newsletterTitle}</h1>
          {isPrivilegedUser && !isReadOnly && (
            <Button data-cy="edit-publish-n-audience-btn" className="change-button" onClick={toggleEditModal}>
              Change
            </Button>
          )}
        </header>
        {user.audience && (
          <EditPublishAndAudienceModal
            show={showEditModal}
            toggleEditModal={toggleEditModal}
            audiences={user.audience}
            newsletterPublishDate={newsletterPublishDate}
            handleSubmit={submitEditPublishAndAudience}
            newsletterAudienceId={newsletterAudienceId}
            handleClose={() => setShowEditModal(false)}
          />
        )}

        <Card className="rounded d-flex align-items-center sections-card">
          <div className="w-75">
            {/* if the isReadOnly prop does not exist, pass it to SectionCard. otherwise, pass it */}
            <SectionCard
              articles={articles}
              setArticles={setArticles}
              sections={sections}
              setSections={setSections}
              setUnsavedChanges={setUnsavedChanges}
              isReadOnly={isReadOnly}
            />
          </div>
          {isPrivilegedUser && !isReadOnly && (
            <div className="d-inline-flex p-4 w-75 justify-content-around">
              <Button className="badge text-white border-hover article-link new-article" onClick={toggleArticleModal}>
                <AddIcon style={{ fontSize: '3.5vw' }} className="m-3 icon" />
                <p className="link-text newsletter-copy-display">Add Existing Article</p>
              </Button>
              <Button
                className="badge text-white border-hover article-link new-article"
                onClick={toogleShowCreateArticleModal}
              >
                <AddIcon style={{ fontSize: '3.5vw' }} className="m-3 icon" />
                <p className="link-text newsletter-copy-display">Add New Article</p>
              </Button>
            </div>
          )}

          {isPrivilegedUser && (
            <FormControlLabel control={<Checkbox onChange={toogleChecked} checked={checked} />} label="Publish Newsletter"/>
          )}
        </Card>
        <br />
        {isPrivilegedUser && (
          <div>
            {!isReadOnly && (
              <div>
                <Button
                  onClick={deleteNewsletter}
                  className="btn btn-danger newsletter-preview-display"
                  id="delete-newsletter"
                >
                  Delete
                </Button>
                <Button
                  onClick={saveNewsletter}
                  className="btn-success newsletter-preview-display"
                  id="save-newsletter"
                >
                  Save
                </Button>
                <Button onClick={resetNewsletter} className="btn newsletter-preview-display" id="reset-newsletter">
                  Undo
                </Button>
              </div>
            )}
            {isReadOnly && user.role === ROLES.ADMIN && (
              <Button onClick={saveNewsletter} className="btn-success newsletter-preview-display" id="save-newsletter">
                Save
              </Button>
            )}
            <Button
              onClick={
                articles !== null && articles.length !== 0
                  ? togglePreviewModal
                  : () => toaster('WARNING', 'Please select article')
              }
              className="btn newsletter-preview-display"
              id="preview-newsletter"
            >
              Preview
            </Button>
            <br />
          </div>
        )}
      </div>
    </>
  )
}
export default EditNewsletter
