import { getVideoId } from 'apis/vimeo.service'

const handleVideoThumbnails = async newsLetterContent => {
  const newsLetterCopy = newsLetterContent.cloneNode(true)

  for await (const iframe of Array.from(newsLetterCopy.getElementsByTagName('iframe'))) {
    if (
      iframe.src.substring(0, 24) === 'https://player.vimeo.com' ||
      iframe.src.substring(0, 17) === 'https://vimeo.com'
    ) {
      const videoInfo = await getVideoId(iframe.src)
      const { width, height, thumbnail_url_with_play_button } = videoInfo.data
      const link = document.createElement('a')
      link.href = iframe.src
      const img = document.createElement('img')
      img.width = width
      img.height = height
      img.src = thumbnail_url_with_play_button
      link.appendChild(img)
      iframe.replaceWith(link)
    } else if (iframe.src.substring(0, 23) === 'https://www.youtube.com') {
      const link = document.createElement('a')
      link.href = iframe.src
      const img = document.createElement('img')
      img.src = `https://i.ytimg.com/vi/${iframe.src.replace('https://www.youtube.com/embed/', '')}/hqdefault.jpg`
      link.appendChild(img)
      iframe.replaceWith(link)
    }
  }
  return newsLetterCopy
}

export async function handleDownload(subjectLine) {
  const originalNewsLetter = document.getElementsByClassName('content')[0]

  const contentDiv = document.createElement('div')
  contentDiv.id = 'newsLetterClone'
  const newsLetterClone = await handleVideoThumbnails(originalNewsLetter)
  contentDiv.append(newsLetterClone)
  let emlContent = 'data:message/rfc822 eml;charset=utf-8,'
  emlContent += 'To: \n'
  emlContent += `Subject: ${subjectLine}\n`
  emlContent += 'X-Unsent: 1\n'
  emlContent += 'Content-Type: text/html; charset=UTF-8\n'
  emlContent += '\n'
  const content = encodeURIComponent(contentDiv.outerHTML)
  emlContent += content
  const fakeLink = document.createElement('a')
  const linkText = document.createTextNode('fileLink')
  fakeLink.appendChild(linkText)
  fakeLink.href = emlContent
  fakeLink.id = 'fileLink'
  fakeLink.download = 'NewsletterEmail.eml'
  fakeLink.style = 'display:none;'
  originalNewsLetter.lastChild.append(fakeLink)
  document.getElementById('fileLink').click()
}
