import React, { useState, useEffect } from 'react'
import { getAudienceById } from 'apis/audience.service'
import Preview from 'components/preview/preview.component'
import { Typography } from '@material-ui/core'
import ContentEditor from '../controls/content-editor.component'
import PreviewButton from '../controls/previewButton.component'
import { getOldImages, convertOldImagesToHtml } from '../utils/oldPicturesHandler'
import PropTypes from 'prop-types'
import { useError } from 'context/error-context'

/**
 * Used in HeaderAndFooter component to display header
 *
 * Its state variables are:
 *    @property {Boolean} showHeaderPreviewModal - whether to show preview modal
 */
const Footer = ({ audienceId, setAudience, footerContent, setFooterContent, footerImgs, setFooterImgs }) => {
  const [showFooterPreviewModal, setFooterShowPreviewModal] = useState(false)
  const { setError } = useError()

  /**
   * Returns content of the footer from first audience passed as input
   * @param {Audience[]} audiences - audiences retrieved from backend
   * @returns {String} content of the footer, or empty string for empty response from backend
   */
  const getFooterContent = audiences => {
    if (audiences.length > 0 && audiences[0] && audiences[0].footer.content) {
      return audiences[0].footer.content
    }
    return ''
  }

  /**
   * Retrieves images saved in the old way using imgs field and converts them to HTML
   * @param {Audience[]} audiences - audiences retrieved from backend
   * @returns {String} HTML containing old images, or empty string in case of no old images found
   */
  const getOldImagesHtml = async audiences => {
    if (audiences[0] && audiences[0].footer.imgs.length) {
      const oldImages = await getOldImages(audiences[0].footer.imgs)
      return convertOldImagesToHtml(oldImages)
    }
    return ''
  }

  /**
   * Executed when audience is changed in HeaderAndFooter parent component
   * Refreshes footer content
   */
  useEffect(() => {
    const handleNewAudience = async () => {
      if (!audienceId) {
        setFooterContent('')
        setFooterImgs([])
        return
      }
      try {
        const res = await getAudienceById(audienceId)
        setAudience(res.data[0])
        const content = await getFooterContent(res.data)
        const oldImagesHtml = await getOldImagesHtml(res.data)
        setFooterContent(oldImagesHtml + content)
        setFooterImgs([])
      } catch (err) {
        setError(err)
      }
    }
    handleNewAudience()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audienceId])

  /**
   * Function to be executed in Content component when content has changed
   * @param {String} content - new content from the Content component
   */
  const handleFooterEditorChange = content => {
    setFooterContent(content)
  }

  /** Function to be executed when Preview button is clicked */
  const toggleFooterPreviewModal = () => {
    setFooterShowPreviewModal(!showFooterPreviewModal)
  }

  /** Function to be executed when exiting preview modal */
  const handleCancel = () => {
    setFooterShowPreviewModal(false)
  }

  return (
    <>
      <Preview
        show={showFooterPreviewModal}
        handleCancel={handleCancel}
        articles={[{ imgs: footerImgs, content: footerContent }]}
      />
      <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <Typography variant="h5">Footer</Typography>
      </div>
      <ContentEditor id="FooterContent" handleChange={handleFooterEditorChange} content={footerContent} />
      <PreviewButton key="FooterPreviewButton" isActive={!!audienceId} onClick={toggleFooterPreviewModal} />
    </>
  )
}

/**
 * Prop-types description
 *    audienceId - ID of currently selected audience
 *    footerContent - content of the footer
 *    footerImgs - deprecated
 *    setAudience - redundant, already called in parent component
 */
Footer.propTypes = {
  audienceId: PropTypes.string,
  footerContent: PropTypes.string,
  setFooterContent: PropTypes.func,
  footerImgs: PropTypes.arrayOf(PropTypes.string),
  setFooterImgs: PropTypes.func,
  setAudience: PropTypes.func
}

export default Footer
