import React from 'react'
import Loader from '@material-ui/core/CircularProgress'

const LoaderFunction = ({ justifyPosition = 'center', ...rest }): JSX.Element => {
  return (
    <div {...rest} className={`d-flex justify-content-${justifyPosition} p-3 px-5`}>
      <Loader />
    </div>
  )
}

export default LoaderFunction
