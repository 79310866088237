import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { Button, Row, Col } from 'react-bootstrap'
import DayPicker from 'react-day-picker'
import './edit-modal.css'

const EditPublishAndAudienceModal = props => {
  const [selectedAudience, setSelectedAudience] = useState(null)
  const [calendarSelectedDate, setCalendarSelectedDate] = useState(new Date())
  const [calendarSelectedMonth, setCalendarSelectedMonth] = useState(new Date())
  const [datePublished, setDatePublished] = useState(new Date())
  const [title, setTitle] = useState('')

  useEffect(() => {
    if (props.newsletterPublishDate && props.newsletterPublishDate.length > 0) {
      const date = new Date(props.newsletterPublishDate)
      setCalendarSelectedDate(date)
      setCalendarSelectedMonth(new Date(date.getFullYear(), date.getMonth()))
      setTitle(`Planned Publish Date: ${date.toLocaleDateString()}`)
    }
  }, [props.newsletterPublishDate])

  const handleDateSelect = day => {
    setDatePublished(day)
    setCalendarSelectedDate(day)
    setCalendarSelectedMonth(new Date(day.getFullYear(), day.getMonth()))
    setTitle(`Planned Publish Date: ${day.toLocaleDateString()}`)
  }

  const handleChange = event => {
    setSelectedAudience(event.target.value)
  }

  const handleCloseOnSubmit = () => {
    props.handleSubmit(calendarSelectedDate, selectedAudience)
    setSelectedAudience('')
  }

  const renderSelect = () => {
    const activeAudiences = []
    props.audiences.forEach(audience => {
      if (audience.currentAudience === true) {
        activeAudiences.push(audience)
      }
    })
    return (
      <select className="form-control" width="400px" onChange={handleChange} defaultValue={props.newsletterAudienceId}>
        {
          <option key="default" value="" disabled>
            Select an Option
          </option>
        }
        {activeAudiences.map((audience, i) => (
          <option value={audience._id} key={i}>
            {audience.name}
          </option>
        ))}
      </select>
    )
  }

  return (
    <>
      <Modal show={props.show} onHide={props.toggleEditModal} enforceFocus={false} backdrop="static">
        <Modal.Header closeButton>
          <b>Edit Publish Date and Audience</b>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col>
                <label data-cy="publish-date">{title}</label>
                <div className="container">
                  <div className="SelectedWeekExample">
                    <DayPicker
                      value={datePublished}
                      onDayClick={e => handleDateSelect(e)}
                      selectedDays={calendarSelectedDate}
                      month={calendarSelectedMonth}
                      handleDateSelect={handleDateSelect}
                      showOutsideDays
                    />
                  </div>
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label>Audience:</label>
                  {renderSelect()}
                </div>
              </Col>
            </Row>
          </div>
          <div className="mr-auto submit-button">
            <Button data-cy="submit-edit" variant="primary" onClick={() => handleCloseOnSubmit()}>
              Submit Edit
            </Button>
            <Button className="ml-2" variant="primary" onClick={props.handleClose}>
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default EditPublishAndAudienceModal
