import React, { useState, useEffect } from 'react'
import { getVideoById } from 'apis/retrieve-video.service'
import { Paper } from '@material-ui/core'
import PropTypes from 'prop-types'

const RetrieveVideo = props => {
  const [retrievedVideo, setRetrievedVideo] = useState('')
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    props.setUseNavbar(false)
    const fetchVideos = () => {
      const videoIdentifier = props.match.params.id
      getVideoById(videoIdentifier)
        .then(res => setRetrievedVideo(res.data))
        .catch(() => {
          setIsError(true)
        })
    }
    if (!retrievedVideo) {
      fetchVideos()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const styles = {
    container: { display: 'flex', justifyContent: 'center', paddingTop: '3em', paddingBottom: '3em' },
    message: { fontSize: '24pt' }
  }

  return (
    <Paper style={styles.container}>
      {isError && <p style={styles.message}>The requested video was not found.</p>}
      {!isError && <video controls src={retrievedVideo} />}
    </Paper>
  )
}

/**
 * Prop-types description
 *    setUseNavbar - function to toggle usage of navbar
 *    match.params.id - id parameter sent in URL to /retrieve-video/:id
 */
RetrieveVideo.propTypes = {
  setUseNavbar: PropTypes.func,
  match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string }) })
}

export default RetrieveVideo
