import React from 'react'
import { useMediaQuery } from '@material-ui/core'
import { CTAButton } from '../CTAButton/CTAButton'
import { Arrow, ArticleIcon, CTA, CTAPaper, Info, NewsletterIcon, Subtitle, Title } from './FloatingCTAStyles'
import { MENU_LIST_ITEMS } from 'consts'

export const FloatingCTA = ({ user }) => {
  const isLargeScreen = useMediaQuery('(min-width:1099px)')
  return (
    <CTA>
      <CTAPaper>
        <Info className="cta-info">
          <Title className="cta-title">
            Get started here
            <Arrow className="cta-arrow" />
          </Title>
          <Subtitle className="cta-subtitle">
            Create a newsletter from scratch, start an article, or search for existing ones.
          </Subtitle>
        </Info>

        <CTAButton
          icon={isLargeScreen && <ArticleIcon />}
          href={MENU_LIST_ITEMS.ARTICLES.CREATE.PATH}
          size={!isLargeScreen ? 'small' : 'medium'}
          title="Start an Article"
          subtitle="Write and share a new article, to be featured in a newsletter"
        />

        <CTAButton
          icon={isLargeScreen && <NewsletterIcon />}
          size={!isLargeScreen ? 'small' : 'medium'}
          href={MENU_LIST_ITEMS.NEWSLETTERS.CREATE.PATH}
          title="Create a Newsletter"
          subtitle="Start fresh by choosing articles to feature in your newsletter"
        />
      </CTAPaper>
    </CTA>
  )
}
