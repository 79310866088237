import { getImageById } from 'apis/image.service'

// During implementing one of the task, way of keeping images for header and footer changed
// New way is to store them directly in the content as HTML, old way was to store them in backend as files
// Purpose of those functions is to handle any old images
// In the future, when there are no more old images, it should be removed

/**
 * Converts image file to HTML tag
 * @param {Blob} image - content of the old image
 * @param {Number} index - identifier of the image
 * @returns {String} HTML tag representing image
 */
const buildHtmlTagForImage = (image, index) => {
  return `<img src="${image}" key=image${index} alt="" height="auto" width="auto" style="margin-right:20px;margin-bottom:20px" />`
}

/**
 * Converts image files into array of HTML tags
 * @param {Blob[]} oldImages - array of old images content
 * @returns {String[]} array of HTML tags representing images
 */
export function convertOldImagesToHtml(oldImages) {
  return oldImages
    .slice()
    .map((image, index) => (image === undefined ? '' : buildHtmlTagForImage(image, index)))
    .join('')
}

/**
 * Returns array of old images content for passed identifiers by communicating with backend
 * @param {String[]} imageIds - identifiers of the images stored in old imgs field of header & footer
 * @returns {Blob[]} - array of old images content
 */
export async function getOldImages(imageIds) {
  try {
    const imgArray = (
      await Promise.all(
        imageIds.map(imgId => {
          return getImageById(imgId)
        })
      )
    ).map(imgRes => imgRes.data)
    return imgArray
  } catch (err) {
    console.log(err)
    return []
  }
}
