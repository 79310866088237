import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import MyArticleList from 'components/article/list/My-Article-list'
import '../newsletter-form.component.css'
import './add-articles-modal.component.css'

export default class AddArticles extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSubmitting: false,
      calledFromEditNewsletter: true,
      showAll: true
    }
  }

  addArticlesToNewsletter = selectedArticle => {
    this.props.addArticlesToNewsletter(selectedArticle)
  }

  render() {
    const cancelInterceptor = () => {
      if (!this.state.isSubmitting) {
        this.props.handleCancel()
      }
    }

    return (
      <>
        <Modal show={this.props.show} onHide={cancelInterceptor} enforceFocus={false} className="articles-modal">
          <MyArticleList
            calledFromEditNewsletter={this.state.calledFromEditNewsletter}
            addArticlesToNewsletter={this.addArticlesToNewsletter}
            showAll={this.state.showAll}
            handleCancel={this.props.handleCancel}
          />
        </Modal>
      </>
    )
  }
}
