import React from 'react'
import RtfEditor from '../../richTextFormat/rtfEditor.component'
import PropTypes from 'prop-types'

/**
 * Component used as header and footer content.
 * It uses RtfEditor component with smaller height than default.
 */
const ContentEditor = props => {
  return (
    <div id={props.id}>
      <RtfEditor
        value={props.content}
        onChange={props.handleChange}
        style={{ zIndex: 0 }}
        height={200}
        componentId={props.id}
      />
    </div>
  )
}

/**
 * Prop-types description
 *    id - unique identifier of the content, mandatory to make RtfEditor work correctly
 *    handleChange - function to execute when content of editor has changed
 *    content - used to store value in the editor
 */
ContentEditor.propTypes = {
  id: PropTypes.string,
  handleChange: PropTypes.func,
  content: PropTypes.string
}

export default ContentEditor
