import React from 'react'
import { Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import DatePicker from 'utils/datePicker'

/**
 * Component consisting of date from and date to inputs
 */
const DateFromToFilter = props => {
  return (
    <>
      <Col xs="auto mb-2">
        <h5 id="date-from" className="d-block">
          Date from
        </h5>
        <DatePicker selected={props.values.dateFrom} onChange={props.onDateFromChange} type="dateFrom" />
      </Col>
      <Col xs="auto mb-2">
        <h5 id="date-to" className="d-block">
          Date to
        </h5>
        <DatePicker selected={props.values.dateTo} onChange={props.onDateToChange} type="dateTo" />
      </Col>
    </>
  )
}

/**
 * Prop-types description
 *    values - object containing filter values
 */
DateFromToFilter.propTypes = {
  values: PropTypes.shape({
    dateFrom: PropTypes.object,
    dateTo: PropTypes.object
  }),
  onDateFromChange: PropTypes.func,
  onDateToChange: PropTypes.func
}

export default DateFromToFilter
