import { AxiosPromise } from 'axios'
import { Section } from 'models/section'

import { AxiosWithToken } from './axios-helpers'

const SectionAxios = AxiosWithToken(`${process.env.REACT_APP_API_URL}/section`)

export const getAllSections = (): AxiosPromise<Section[]> => SectionAxios.get('/')

export const addSection = (section): AxiosPromise<string> => SectionAxios.post('/add', section)

export const updateSection = (section, id: string): AxiosPromise<string> => SectionAxios.put(`/update/${id}`, section)

export const deleteSection = (id: string): AxiosPromise<string> => SectionAxios.delete(`/${id}`)
