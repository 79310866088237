import React from 'react'
import { Tooltip, Box, IconButton } from '@material-ui/core'
import { Link } from 'react-router-dom'

export const GridCell = props => {
  let cellStyles = props.cellStyles
  let cellContent
  if (props.column.icon) {
    cellStyles = { ...cellStyles, paddingLeft: '0', paddingRight: '0' }
  }
  if (props.column.icon) {
    cellContent = (
      <Tooltip title={props.tooltip}>
        <IconButton edge="end" size={props.iconSize} onClick={props.clickHandler}>
          <props.column.icon />
        </IconButton>
      </Tooltip>
    )
  } else if (props.content) {
    cellContent = (
      <Box
        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        data-toggle="tooltp"
        title={props.tooltip}
      >
        <props.content />
      </Box>
    )
  }
  if (props.link) {
    cellContent = (
      <Link
        to={{
          pathname: props.link
        }}
      >
        {cellContent}
      </Link>
    )
  }
  return (
    <Box
      key={props.column.id}
      data-cy={props.column.id}
      align={props.column.align}
      style={{ ...props.column.styles, overflow: 'hidden', display: 'flex' }}
      sx={cellStyles}
      className={props.className}
    >
      {cellContent ? cellContent : null}
    </Box>
  )
}
