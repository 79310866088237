import React from 'react'
import '../HeaderAndFooter.css'
import { Button, Tooltip } from '@material-ui/core'
import PropTypes from 'prop-types'

/**
 * Preview button used in header & footer
 * Can be either active and clickable, or disabled with tooltip
 */
const PreviewButton = props => {
  return (
    <div className="preview-button">
      {props.isActive ? (
        <>
          <Button variant="text" onClick={props.onClick} style={{ fontWeight: 600, color: '#538b3f' }}>
            Preview
          </Button>
        </>
      ) : (
        <>
          <Tooltip title="Please Select an Audience">
            <span>
              <Button disabled variant="text" style={{ fontWeight: 600, color: 'rgba(83, 139, 63, 0.26)' }}>
                Preview
              </Button>
            </span>
          </Tooltip>
        </>
      )}
    </div>
  )
}

/**
 * Prop-types description
 *    isActive - whether a button should be shown as active or disabled
 *    onClick - function to execute when active version of the button was clicked
 */
PreviewButton.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func
}

export default PreviewButton
