import React from 'react'
import { Backdrop as Wrapper, CircularProgress } from '@material-ui/core'
import styled from 'styled-components'

interface BackdropProps {
  open: boolean
}

const BackdropWrapper = styled(Wrapper)`
  z-index: 9999;
  && .MuiCircularProgress-root {
    color: #b3e06e;
  }
`

export const Backdrop = ({ open }: BackdropProps): JSX.Element => (
  <BackdropWrapper open={open}>
    <CircularProgress />
  </BackdropWrapper>
)
