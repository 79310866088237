import React, { Component } from 'react'
import ArticleComponent from 'components/article/article.component'
import UpdateArticleModal from '../../article/update/update-article-modal.component'
import { Button, Card } from 'react-bootstrap'
import './view-newsletter.component.css'
import toaster from '../../toast/toast.component'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import PreviewNewsletter from '../preview/preview-newsletter.component'
import AddIcon from '@material-ui/icons/Add'
import CreateIcon from '@material-ui/icons/Create'
import { handleGettingArticleError } from 'utils/errorHandling'
import { Article } from '../../../models/article'
import { Link } from 'react-router-dom'
import { getArticleByTagVolumeIssue, getArticleByTagUnassigned, reorderArticles } from 'apis/article.service'

export default class ViewNewsletter extends Component {
  curSection = ''

  constructor(props) {
    super(props)

    this.state = {
      articles: [],
      selectedArticle: undefined,
      showEditModal: false,
      hideButton: true,
      showPreviewModal: false,
      callReorderPost: false
    }
  }

  componentDidMount() {
    this.grabArticles()
  }

  componentDidUpdate() {
    this.curSection = ''
  }

  /**
   * grabs / refreshes articles when you expect them to change
   */
  grabArticles() {
    const { tag, startVolume, startIssue } = this.props.match.params
    const articles =
      startVolume !== undefined && startIssue !== undefined
        ? getArticleByTagVolumeIssue(tag, startVolume, startIssue)
        : getArticleByTagUnassigned(tag)

    articles
      .then(response => {
        this.setState({
          hideButton: response.data[0].hideCopyBtn,
          articles: response.data[1].article
        })
      })
      .catch(() => {
        if (this.state.articles === 'No permission!') {
          alert('You do not have permission to view the archive. Redirecting to home page.')
          window.location.href = '/'
        } else if (this.state.articles.success === false) {
          alert('Your authorization token is expired, invalid, or missing.')
          window.location.href = '/'
        } else {
          toaster('ERROR', 'Something went wrong. Redirecting to home page.')
          window.location.href = '/'
        }
      })
  }

  startEditOfArticle = article => {
    this.setState({ selectedArticle: article, showEditModal: true })
  }

  handleUpdateToExistingArticle = () => {
    this.grabArticles()
    this.closeEditArticleModal()
  }

  closeEditArticleModal = () => {
    this.setState({ showEditModal: false, selectedArticle: undefined })
  }

  handleDragStart = (e, index) => {
    this.draggedArticle = this.state.articles[index]
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('text/html', e.target.parentNode)
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20)
  }

  handleDragOver = index => {
    const draggedOverArticle = this.state.articles[index]
    let articlesArray = this.state.articles

    // if the article is dragged over itself, ignore
    if (this.draggedArticle === draggedOverArticle) {
      return
    }

    // checks if the dragged article's section is equal to the dragged over article's section
    if (this.draggedArticle.section !== draggedOverArticle.section) {
      // if it is not, filter out the entire section of articles with the same section as the dragged article
      const entireSectionToMove = articlesArray.filter(
        currentArticle => currentArticle.section === this.draggedArticle.section
      )
      articlesArray = articlesArray.filter(currentArticle => currentArticle.section !== this.draggedArticle.section)

      // and add the dragged section after the dragged over section
      articlesArray.splice(index, 0, ...entireSectionToMove)
    } else {
      // if it is, filter out the dragged article
      articlesArray = articlesArray.filter(currentArticle => currentArticle !== this.draggedArticle)

      // and add the dragged article after the dragged over article
      articlesArray.splice(index, 0, this.draggedArticle)
    }

    this.setState({
      ...this.state,
      articles: [...articlesArray],
      callReorderPost: true
    })
  }

  handleDragEnd = () => {
    if (!this.state.callReorderPost) {
      return
    }
    const rearrangedArticles = this.state.articles.map((article, index) => ({
      ...article,
      position: index
    }))
    this.setState({
      ...this.state,
      articles: [...rearrangedArticles],
      callReorderPost: false
    })
    this.draggedIndex = null

    // saves the spot in order to an article. Will no longer be needed when creating a newsletter functionality
    // is fully up and running
    reorderArticles([...rearrangedArticles])
      .then(response => {
        this.setState({ articles: response.data })
      })
      .catch(error => {
        handleGettingArticleError(error)
      })
  }

  /**
   * creates an array of the sections for the newsletter then kicks off
   * the construction of the newsletter by calling createCardsForSections
   */
  constructNewsletter = () => {
    try {
      const articles = this.state.articles
      const sectionSet = []
      articles.forEach(article => {
        if (!sectionSet.includes(article.section)) {
          sectionSet.push(article.section)
        }
      })
      return this.createSectionCards(sectionSet, articles)
    } catch (error) {
      handleGettingArticleError(error)
    }
  }

  /**
   * makes a card for every section of newsletter that has articles
   */
  createSectionCards = (sectionSet, articles) => {
    return sectionSet.map(section => (
      <React.Fragment key={section.value}>
        <Card className="sections-card">
          {this.createSectionHeader(section ? section : '')}
          {this.createArticleCardsForCurrentSection(section, articles)}
        </Card>
        <br />
      </React.Fragment>
    ))
  }

  /**
   * determines when to add another section header to the newsletter
   */
  createSectionHeader = section => {
    if (section !== this.curSection) {
      this.curSection = section
      return (
        <div style={{ textAlign: 'center' }}>
          <br />
          <h2 style={{ color: '#5480C0', fontSize: 24 }}>{section}</h2>
        </div>
      )
    }
  }

  /**
   * creates article cards for each article belonging to current section
   */
  createArticleCardsForCurrentSection = (section, articles) => {
    return articles.map((currentarticle, index) => {
      if (currentarticle.section === section) {
        return (
          <div key={currentarticle._id} onDragOver={() => this.handleDragOver(index)}>
            <div
              className="drag"
              draggable
              onDragStart={e => this.handleDragStart(e, index)}
              onDragEnd={this.handleDragEnd}
            >
              <React.Fragment key={currentarticle._id}>
                <Card className="articles-card-nl">
                  <Card.Header>
                    <span
                      className="d-inline-block"
                      tabIndex="0"
                      data-toggle="tooltip"
                      title="Click and drag to rearrange"
                    >
                      <DragIndicatorIcon data-toggle="tooltip" data-placement="top" />
                    </span>
                    <span className="d-inline-block float-right" tabIndex="0" data-toggle="tooltip" title="Click edit">
                      <CreateIcon className="edit-buttons" onClick={() => this.startEditOfArticle(currentarticle)} />
                    </span>
                  </Card.Header>
                  <ArticleComponent article={currentarticle} />
                </Card>
              </React.Fragment>
            </div>
            <hr />
          </div>
        )
      }
      return null
    })
  }

  /**
   * Copies the newsletter contents to clipboard
   */
  handleCopyNewsletterClick = () => {
    window.getSelection().removeAllRanges()
    const newsletterToBeCopied = document.getElementsByClassName('newsletter')[0]
    newsletterToBeCopied.setAttribute('style', this.addCopyCSS())

    const formattedCopy = document.createElement('div')
    formattedCopy.innerHTML = this.addCopyCSS() + newsletterToBeCopied.innerHTML
    document.body.appendChild(formattedCopy)
    const selection = window.getSelection()
    const range = document.createRange()
    range.selectNodeContents(formattedCopy)
    selection.addRange(range)
    document.execCommand('copy')
    document.body.removeChild(formattedCopy)
    toaster('SUCCESS', 'Copied Newsletter to clipboard!')
  }

  /**
   * Ensures the preview/copy buttons and newsletter header
   * are not included in the copy/paste
   */
  addCopyCSS() {
    return `<style>
    .newsletter {margin: 0 auto;}
    main{ background-color: white;}
    img[Attributes Style] {
      width: 94px;
      height: 100px;
      float: left;
      vertical-align: top;
      margin-left: 12px;
      margin-right: 12px;
  }
  .newsletter-copy-display, .newsletter-preview-display {
    display: none;
  }
    </style>
    `
  }

  togglePreviewModal = () => {
    this.setState({ showPreviewModal: !this.state.showPreviewModal })
  }

  render() {
    const issue = this.props.match.params.startissue
    const tagName = this.props.match.params.tag
    const volume = parseInt(this.props.match.params.startvolume)
    const article = new Article({
      startIssue: issue,
      endIssue: issue,
      tags: [tagName],
      startVolume: volume,
      endVolume: volume
    })

    return (
      <div className="newsletter">
        {this.state.selectedArticle && (
          <UpdateArticleModal
            show={this.state.showEditModal}
            article={this.state.selectedArticle}
            handleUpdate={this.handleUpdateToExistingArticle}
            handleCancel={this.closeEditArticleModal}
          />
        )}
        {this.state.showPreviewModal && (
          <PreviewNewsletter
            show={this.state.showPreviewModal}
            articles={this.state.articles}
            hidden={this.state.hideButton}
            handleCancel={this.togglePreviewModal}
          />
        )}
        <main>
          <div className="newsletter-header" style={{ textAlign: 'center' }}>
            <h1>Team Daugherty</h1>
            <h2>
              {' '}
              {tagName} Newsletter - Vol. {volume} No. {issue}
            </h2>
          </div>
          <br />
          {this.constructNewsletter()}
        </main>
        <Card className="rounded d-flex align-items-center sections-card">
          <div className="d-inline-flex p-4 w-75 justify-content-around">
            <Link
              className="badge text-white border-hover article-link new-article"
              to={{
                pathname: '/create',
                state: {
                  article: { article }
                }
              }}
            >
              <AddIcon style={{ fontSize: '3.5vw' }} className="m-3 icon" />
              <p className="link-text">Create New Article</p>
            </Link>
            <Button className="badge text-white border-hover article-link new-article">
              <AddIcon style={{ fontSize: '3.5vw' }} className="m-3 icon" />
              <p className="link-text newsletter-copy-display">Add Existing Article</p>
            </Button>
          </div>
        </Card>
        <br />
        <Button
          onClick={e => this.handleCopyNewsletterClick(e)}
          className="btn newsletter-copy-display"
          variant="primary"
          type="button"
          hidden={this.state.hideButton}
          style={{ float: 'right' }}
        >
          Copy Newsletter
        </Button>
        <Button
          onClick={this.togglePreviewModal}
          className="btn newsletter-preview-display"
          id="preview-newsletter"
          hidden={this.state.hideButton}
        >
          Preview Newsletter
        </Button>
        <br />
      </div>
    )
  }
}
