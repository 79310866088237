import { StatusChip } from 'components/statusChip/StatusChip'
import React from 'react'

function ArticleStatus({ status }) {
  return (
    <div className="form-group">
      <label>Article status:</label> <StatusChip status={status} />
    </div>
  )
}

export default ArticleStatus
