import React, { useCallback, useEffect, useState, useContext } from 'react'
import { isEmpty } from 'lodash'
import { getUsers } from 'apis/admin.service'
import { getVideoId } from 'apis/vimeo.service'
import { cloneArticle, deleteArticle, getArticles, getArticlesByArticleIds, getArticle } from 'apis/article.service'
import { getNewslettersByArticleId, updateNewsletter } from 'apis/newsletter.service'
import { useError } from 'context/error-context'
import { UserContext } from 'context/user-context'
import { useHistory } from 'react-router-dom'
import ArticlesGrid from './article-grid'
import Loader from '../../loader/loader.component'
import toaster from 'components/toast/toast.component'
import Preview from 'components/preview/preview.component'
import UpdateArticleModal from '../update/update-article-modal.component'
import DateFromToFilter from 'components/filter/date-from-to-filter.component'
import { handleGettingArticleError } from 'utils/errorHandling.js'
import '../../FontStyle.css'
import { Col } from 'react-bootstrap'
import { Save } from '@material-ui/icons'
import { Button } from '@material-ui/core'
import { Backdrop } from '../../backdrop'
const invertDirection = {
  asc: 'desc',
  desc: 'asc'
}

const MyArticleList = ({ calledFromEditNewsletter, addArticlesToNewsletter, showAll = false, handleCancel }) => {
  const { user } = useContext(UserContext)
  const [previewedArticle, setPreviewedArticle] = useState({ loading: false, data: null })
  const [articles, setArticles] = useState([])
  const [actionOnArticle, setActionOnArticle] = useState(null)
  const [filter, setFilter] = useState('')
  const [isFiltering, setIsFiltering] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [columnToSort, setColumnToSort] = useState('modifiedOn')
  const [sortDirection, setSortDirection] = useState('desc')
  const [totalArticles, setTotalArticles] = useState(0)
  const [selectedArticlesIds, setSelectedArticlesIds] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [refresh, setRefresh] = useState(false)
  const [dateFrom, setDateFrom] = useState(null)
  const [dateTo, setDateTo] = useState(null)
  const { setError } = useError()
  const [titleList, setTitleList] = useState('My Articles')
  const history = useHistory()
  const isArticlesPage = window.location.pathname === '/myarticles' || window.location.pathname === '/allarticles'
  const grabArticles = useCallback(async params => {
    params = params ? params : {}

    if (params.sort === undefined) {
      params.sort = {
        sortBy: `${columnToSort}`,
        orderBy: `${sortDirection}`
      }
    }
    params.withoutNewsletters = calledFromEditNewsletter

    try {
      let tempArticles = []

      if (showAll) {
        setTitleList('All Articles')
        tempArticles = (
          await getArticles(
            params.pageNumber,
            params.rowsPerPage,
            params.sort,
            params.filter,
            params.dateFrom,
            params.dateTo,
            (params.ownerId = ''),
            params.withoutNewsletters
          )
        ).data
      } else {
        tempArticles = (
          await getArticles(
            params.pageNumber,
            params.rowsPerPage,
            params.sort,
            params.filter,
            params.dateFrom,
            params.dateTo,
            (params.ownerId = user._id),
            params.withoutNewsletters
          )
        ).data
      }
      const { data, totalArticles } = tempArticles
      if (totalArticles > 0) {
        const tempUsers = (await getUsers([...new Set(data.map(article => article.owner))])).data
        setArticles(
          data.map(article => {
            if (!article.owner) article.owner = ''
            if (!article.title) article.title = ''
            if (article.tags.length === 0) {
              article.tags.push('Unassigned')
            }

            const matchingUser = tempUsers.find(user =>
              new RegExp(`^${article.owner.split('@')[0].toUpperCase()}@DAUGHERTY.(COM|PA|PL)$`).test(
                user._id.toUpperCase()
              )
            ) ?? { name: '' }

            return { ...article, name: matchingUser.name }
          })
        )
        setTotalArticles(tempArticles.totalArticles)
      }
      setTotalArticles(totalArticles)
      setIsLoading(false)

      //if pagination was clicked, scroll to the bottom of the screen
      if (params.pagination) {
        window.scrollTo(0, document.body.scrollHeight)
      }

      setIsFiltering(false)
    } catch (error) {
      handleGettingArticleError(error)
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // This setTimeout is a debouncing function used to limit calls to grabArticles while the filter is being updated
    const filterAction = setTimeout(() => {
      if (!isEmpty(user)) {
        const params = {
          pageNumber: page,
          rowsPerPage: rowsPerPage,
          sort: {
            sortBy: `${columnToSort}`,
            orderBy: `${sortDirection}`
          },
          filter: filter,
          dateFrom: dateFrom,
          dateTo: dateTo
        }
        grabArticles(params)
      }
    }, 400)
    return () => {
      clearTimeout(filterAction)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, dateFrom, dateTo, refresh, user, grabArticles])

  const removeArticleFromNewsletter = async (article, newsletter) => {
    try {
      const res = await getArticlesByArticleIds(newsletter.articles.filter(a => a !== article._id))
      return {
        articles: [...new Set(res.data.map(a_1 => a_1._id))],
        sections: [...new Set(res.data.map(a_2 => a_2.section))]
      }
    } catch (err) {
      return setError(err)
    }
  }

  const startCopy = id => {
    let copiedArticle
    cloneArticle(id)
      .then(res => {
        copiedArticle = res.data
        setArticles(prevArticles => [copiedArticle, ...prevArticles])
        toaster('SUCCESS', 'Article copied!')
      })
      .catch(err => setError(err))
  }

  const cancelEdit = () => {
    setActionOnArticle(null)
  }

  const handleUpdate = () => {
    setIsLoading(true)
    setRefresh(!refresh)
    cancelEdit()
    setIsLoading(false)
  }

  const handleChange = e => {
    e.preventDefault()
    setIsFiltering(true)
    setPage(0)
    setFilter(e.target.value.toLowerCase())
  }

  const handleDelete = article => {
    setIsLoading(true)
    getNewslettersByArticleId(article._id)
      .then(res => {
        res.data.forEach(newsletter => {
          removeArticleFromNewsletter(article, newsletter)
            .then(obj => {
              updateNewsletter(newsletter._id, obj)
            })
            .catch(err => setError(err))
        })
      })
      .catch(err => setError(err))

    deleteArticle(article._id)
      .then(() => {
        handleUpdate()
      })
      .catch(err => setError(err))
  }

  const handleSort = ({ column, page, rowsPerPage }) => {
    if (column.id !== 'tags') {
      setIsLoading(true)
      const order = sortDirection === invertDirection.desc ? invertDirection.asc : invertDirection.desc
      setSortDirection(order)
      setColumnToSort(column.id)
      const params = {
        pageNumber: page,
        rowsPerPage: rowsPerPage,
        sort: {
          sortBy: column.id === 'name' ? `owner` : `${column.id}`,
          orderBy: `${order}`
        },
        filter: filter,
        dateFrom: dateFrom,
        dateTo: dateTo
      }
      grabArticles(params)
    }
  }

  const getFilteredArticles = async () => {
    try {
      let tempArticles = []

      const params = {
        pageNumber: page,
        rowsPerPage: rowsPerPage,
        sort: {
          sortBy: `${columnToSort}`,
          orderBy: `${sortDirection}`
        },
        filter: filter,
        pagination: true,
        dateFrom: dateFrom,
        dateTo: dateTo
      }
      if (showAll) {
        setTitleList('All Articles')
        tempArticles = (
          await getArticles(
            params.pageNumber,
            params.rowsPerPage,
            params.sort,
            params.filter,
            params.dateFrom,
            params.dateTo,
            (params.ownerId = ''),
            params.withoutNewsletters
          )
        ).data
      } else {
        tempArticles = (
          await getArticles(
            params.pageNumber,
            params.rowsPerPage,
            params.sort,
            params.filter,
            params.dateFrom,
            params.dateTo,
            (params.ownerId = user._id),
            params.withoutNewsletters
          )
        ).data
      }

      const { data, totalArticles } = tempArticles

      if (totalArticles > 0) {
        const tempUsers = (await getUsers([...new Set(data.map(article => article.owner))])).data
        return data.map(article => {
          if (!article.owner) article.owner = ''
          if (!article.title) article.title = ''
          const matchingUser = tempUsers.find(user =>
            new RegExp(`^${article.owner.split('@')[0].toUpperCase()}@DAUGHERTY.(COM|PA|PL)$`).test(
              user._id.toUpperCase()
            )
          ) ?? { name: '' }
          return { ...article, name: matchingUser.name }
        })
      }
    } catch (error) {
      setError(error)
    }
  }

  const tablePaginationHandle = (newPage, rowsPerPage) => {
    //show loading bar while grabbing articles from the database on pagination change
    setIsLoading(true)
    const params = {
      pageNumber: newPage,
      rowsPerPage: rowsPerPage,
      sort: {
        sortBy: `${columnToSort}`,
        orderBy: `${sortDirection}`
      },
      filter: filter,
      pagination: true,
      dateFrom: dateFrom,
      dateTo: dateTo
    }
    grabArticles(params)
  }

  /**
   * Triggered when date from field was changed
   * @param {Date} date - date passed into Date From field
   */
  const changeDateFromHandler = date => {
    setDateFrom(date)
  }

  /**
   * Triggered when date to field was changed
   * @param {Date} date - date passed into Date To field
   */
  const changeDateToHandler = date => {
    setIsLoading(true)
    setDateTo(date)
  }

  //function required for preview, taken from article-form.component
  const handleCopyArticleClick = async () => {
    window.getSelection().removeAllRanges()
    const originalArticle = document.getElementsByClassName('article-content-nl')[0]

    const contentDiv = document.createElement('div')
    contentDiv.id = 'articleClone'
    const articleClone = await handleVideoThumbnails(originalArticle)
    contentDiv.append(articleClone)

    originalArticle.append(contentDiv)
    const selection = window.getSelection()
    const range = document.createRange()
    range.selectNodeContents(document.getElementById('articleClone'))
    selection.addRange(range)
    document.execCommand('copy')

    toaster('SUCCESS', 'Copied Article to clipboard!')
    window.getSelection().removeAllRanges()
    document.getElementById('articleClone').remove()
  }

  //function required for preview, taken from article-form.component
  const handleVideoThumbnails = async articleClone => {
    const articleCopy = articleClone.cloneNode(true)
    for await (const iframe of Array.from(articleCopy.getElementsByTagName('iframe'))) {
      if (
        iframe.src.substring(0, 24) === 'https://player.vimeo.com' ||
        iframe.src.substring(0, 17) === 'https://vimeo.com'
      ) {
        const videoInfo = await getVideoId(iframe.src)
        const { width, height, thumbnail_url_with_play_button } = videoInfo.data
        const link = document.createElement('a')
        link.href = iframe.src
        const img = document.createElement('img')
        img.width = width
        img.height = height
        img.src = thumbnail_url_with_play_button
        link.appendChild(img)
        iframe.replaceWith(link)
      } else if (iframe.src.substring(0, 23) === 'https://www.youtube.com') {
        const link = document.createElement('a')
        link.href = iframe.src
        const img = document.createElement('img')
        const url = new URL(iframe.src)
        const pathName = url.pathname
        img.src = `https://i.ytimg.com/vi/${pathName.replace('/embed/', '')}/hqdefault.jpg`
        link.appendChild(img)
        iframe.replaceWith(link)
      }
    }
    return articleCopy
  }

  const createArticleRedirect = () => {
    history.push('/create')
  }

  useEffect(() => {
    let isMounted = true
    if (actionOnArticle?.type === 'preview') {
      isMounted && setPreviewedArticle({ loading: true, data: null })

      getArticle(actionOnArticle._id)
        .then(res => {
          setPreviewedArticle({ loading: false, data: res.data })
        })
        .catch(err => {
          if (isMounted) {
            setError(err)
            setPreviewedArticle({ loading: false, data: null })
          }
        })
    }
    return () => {
      isMounted = false
    }
  }, [actionOnArticle, setError])

  const handleCancelPreview = () => {
    setActionOnArticle(null)
    setPreviewedArticle({ loading: false, data: null })
  }

  const handleSelectArticle = article => {
    if (!selectedArticlesIds.find(id => id === article._id)) {
      setSelectedArticlesIds(selectedArticlesIds => [...selectedArticlesIds, article._id])
    } else {
      const newArticlesIds = selectedArticlesIds.filter(id => id !== article._id)
      setSelectedArticlesIds(newArticlesIds)
    }
  }

  return (
    <div className="newsletter p-4 mx-0 my-auto">
      {actionOnArticle?.type === 'edit' && (
        <UpdateArticleModal
          articleId={actionOnArticle._id}
          setActionOnArticle={setActionOnArticle}
          handleUpdate={handleUpdate}
          handleCancel={cancelEdit}
          showAll={showAll}
        />
      )}
      {previewedArticle.loading && <Backdrop open={previewedArticle.loading} />}
      {!previewedArticle.loading && actionOnArticle?.type === 'preview' && (
        <Preview
          show={actionOnArticle.type === 'preview'}
          articles={[previewedArticle.data]}
          handleCancel={handleCancelPreview}
          buttons={[{ name: 'Copy Article', icon: <Save />, handler: handleCopyArticleClick }]}
        />
      )}

      <h1 className="text-center text-dark p-4">{titleList}</h1>

      <div className="row align-items-center">
        <Col xs="auto mb-2">
          <h5 className="text-align-center">Filter</h5>
          <input id="filter-articles" onChange={e => handleChange(e)} className="form-control shadow-none" />
        </Col>

        <DateFromToFilter
          values={{ dateFrom: dateFrom, dateTo: dateTo }}
          onDateFromChange={e => changeDateFromHandler(e)}
          onDateToChange={e => changeDateToHandler(e)}
        />

        <Col className="text-right">
          {calledFromEditNewsletter && !isLoading && (
            <>
              <Button variant="contained" color="primary" onClick={() => addArticlesToNewsletter(selectedArticlesIds)}>
                Add All Selected
              </Button>
              <Button className="ml-2" variant="contained" color="primary" onClick={handleCancel}>
                Cancel
              </Button>
            </>
          )}

          {isArticlesPage && (
            <Button variant="contained" color="primary" onClick={createArticleRedirect}>
              Create Article
            </Button>
          )}
        </Col>
      </div>

      <hr />

      {isLoading || isFiltering ? (
        <Loader />
      ) : (
        <div>
          {totalArticles > 0 ? (
            <ArticlesGrid
              sortedArticles={articles}
              handleDelete={handleDelete}
              columnToSort={columnToSort}
              sortDirection={sortDirection}
              handleSort={handleSort}
              addArticlesToNewsletter={addArticlesToNewsletter}
              calledFromEditNewsletter={calledFromEditNewsletter}
              startCopy={startCopy}
              totalArticles={totalArticles}
              selectedArticlesIds={selectedArticlesIds}
              handleSelectArticle={handleSelectArticle}
              tablePaginationHandle={tablePaginationHandle}
              getFilteredArticles={getFilteredArticles}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              showAll={showAll}
              user={user}
              setActionOnArticle={setActionOnArticle}
            />
          ) : (
            <div>
              <h1 className="text-center">Sorry, no results for this search.</h1>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default MyArticleList
