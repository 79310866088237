import React, { useState } from 'react'
import { Toolbar, Box, Button, Checkbox } from '@material-ui/core'

import { GetApp } from '@material-ui/icons'
import { downloadCSV } from '../../../utils/helpers'
import PropTypes from 'prop-types'
import Loader from 'components/loader/loader.component'

/**
 * Component placed above header of articles and newsletters tables, usually containing Export button
 * Allows to pass checkboxes and buttons to render on the right side as parameters. Checkboxes are printed before buttons
 */
export const GridToolbar = props => {
  const rightSideButtons = props.rightSideButtons || []
  const rightSideCheckboxes = props.rightSideCheckboxes || []
  const [isLoading, setIsLoading] = useState(false)
  const isArticlesPage = window.location.pathname === '/myarticles' || window.location.pathname === '/allarticles'

  const styles = {
    exportBox: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1
    },
    rightSideElements: {
      display: 'flex',
      gap: '2em'
    },
    toolbar: {
      paddingLeft: '16px',
      paddingRight: '16px'
    }
  }

  return (
    <Toolbar style={styles.toolbar}>
      <Box sx={styles.exportBox}>
        {isArticlesPage && isLoading ? (
          <Loader id="exportButtonLoader" />
        ) : isArticlesPage ? (
          <Button
            color="primary"
            endIcon={<GetApp />}
            onClick={() => {
              setIsLoading(true)
              props.getArrayForCSV().then(res => {
                downloadCSV({ data: res })
                setIsLoading(false)
              })
            }}
          >
            Export
          </Button>
        ) : null}
      </Box>
      <Box sx={styles.rightSideElements}>
        {rightSideCheckboxes.map((el, index) => {
          return (
            <div key={`div${index}`}>
              {el.label}
              <Checkbox key={`checkbox${index}`} checked={el.value} onClick={el.handler} />
            </div>
          )
        })}
        {rightSideButtons.map((el, index) => {
          return (
            <Button data-cy={el.label} key={index} variant="contained" color="primary" onClick={el.handler}>
              {el.label}
            </Button>
          )
        })}
      </Box>
    </Toolbar>
  )
}

/**
 * Prop-types description
 *    getArrayForCSV - retrieves data to place into CSV
 *    rightSideCheckboxes - definition of checkboxes to be placed on right side of the toolbar
 *    rightSideButtons - definition of buttons to be placed on right side of the toolbar
 */
GridToolbar.propTypes = {
  getArrayForCSV: PropTypes.func,
  rightSideCheckboxes: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.bool, handler: PropTypes.func })
  ),
  rightSideButtons: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, handler: PropTypes.func }))
}
