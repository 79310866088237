import React, { useState } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'

import ArticleCard from './article-card.component'
import CustomToggle from './custom-toggle.component'

const SectionCard = ({ articles, setArticles, sections, setSections, setUnsavedChanges, isReadOnly }) => {
  const [draggedSection, setDraggedSection] = useState()
  const [draggingArticle, setDraggingArticle] = useState(false)
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(
    sections.reduce((prevValue, currentValue, index) => {
      return {
        ...prevValue,
        [`section${index}`]: false
      }
    }, {})
  )

  const sectionDragStart = (sectionDivToDrag, index) => {
    setDraggedSection(sections[index])
    sectionDivToDrag.dataTransfer.effectAllowed = 'move'
    sectionDivToDrag.dataTransfer.setData('text/html', sectionDivToDrag.target.parentNode)
    sectionDivToDrag.dataTransfer.setDragImage(sectionDivToDrag.target.parentNode, 20, 20)
  }

  const sectionDragOver = index => {
    const draggedOverSection = sections[index]
    let sectionsArray = sections

    if (draggedSection !== draggedOverSection && !draggingArticle) {
      sectionsArray = sectionsArray.filter(currentSection => currentSection !== draggedSection)
      sectionsArray.splice(index, 0, draggedSection)
      setSections(sectionsArray)
      setUnsavedChanges(true)
    }
  }

  const sectionDragEnd = () => {
    setDraggedSection()
  }

  return sections.map((currentSection, index) => {
    if (articles.some(article => article && article.section === currentSection)) {
      // Dont want to display sections for header and footer, since they're not really articles
      if (currentSection === 'Header' || currentSection === 'Footer') {
        return null
      }
      return (
        <div key={currentSection} onDragOver={() => sectionDragOver(index)}>
          <div
            className="drag-section"
            draggable
            onDragStart={sectionDivToDrag => sectionDragStart(sectionDivToDrag, index)}
            onDragEnd={sectionDragEnd}
          >
            <Accordion data-cy="card--accordion">
              <Card className="sections-card">
                <Card.Header>
                  <CustomToggle
                    eventKey={`section${index}`}
                    onClickHandler={() =>
                      setIsAccordionExpanded(prevObj => ({
                        ...prevObj,
                        [`section${index}`]: !prevObj[`section${index}`]
                      }))
                    }
                  >
                    <div title="Expand/Collapse Section">
                      <br />
                      <div>
                        <h2 style={{ color: '#5480C0', fontSize: 24, textAlign: 'center' }}>
                          <span style={{ display: 'inline' }}>{currentSection ? currentSection : 'No Section'}</span>
                          <span style={{ display: 'inline' }}>
                            {isAccordionExpanded[`section${index}`] ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                          </span>
                        </h2>
                      </div>
                    </div>
                  </CustomToggle>
                </Card.Header>

                <Accordion.Collapse eventKey={`section${index}`}>
                  <Card.Body>
                    {/* if the isReadOnly prop does not exist, pass it to ArticleCard. otherwise, pass it */}
                    <div>
                      {!isReadOnly ? (
                        <ArticleCard
                          currentSection={currentSection}
                          articles={articles}
                          setArticles={setArticles}
                          setDraggingArticle={setDraggingArticle}
                          setSections={setSections}
                          setUnsavedChanges={setUnsavedChanges}
                        />
                      ) : (
                        <ArticleCard
                          currentSection={currentSection}
                          articles={articles}
                          setArticles={setArticles}
                          setDraggingArticle={setDraggingArticle}
                          setSections={setSections}
                          setUnsavedChanges={setUnsavedChanges}
                          isReadOnly={isReadOnly}
                        />
                      )}
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <br />
          </div>
        </div>
      )
    }
    return null
  })
}

export default SectionCard
