import React from 'react'
import { CoverImage } from '../CoverImage/CoverImage'
import { FloatingCTA } from '../FloatingCTA/FloatingCTA'
import { Page } from '../Page/Page'

import './Body.css'

export const Body = ({user}) => {
    return (
        <div className='body-container'>
            <div className='body-content'>
                <CoverImage />
                <FloatingCTA user={user}/>
                <h1 style={{backgroundColor: 'whitesmoke', margin: 0, paddingTop: '40px', paddingBottom: '20px', textAlign: 'center', color: '#344348'}}>Recent activity and quick links:</h1>
                <Page user={user} />
            </div>
        </div>
    )
}
