import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import './../update-usermodal.component.css'
import '../../FontStyle.css'
import { Button } from 'react-bootstrap'
import Checkbox from '@material-ui/core/Checkbox'
import { duplicateName } from 'utils/audience-validation.js'
import InfoIcon from '@material-ui/icons/Info'
import Tooltip from '@material-ui/core/Tooltip'
import ActiveAudienceTable from '../tables/active-audience-table.component.js'
import { withStyles } from '@material-ui/core/styles'
import toaster from 'components/toast/toast.component'
import { getInactiveAudiences } from '../../../apis/audience.service'
import { Audience } from 'models/audience'
import { TagProps } from 'models/tag'
import { TAGS } from 'consts'

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 15
  }
}))(Tooltip)

type Props = {
  showModal: boolean
  setShowModal: (showModal: boolean) => void
  submitAudience: (audienceName: string, tagList: string[], audiencesToReactivate: string[]) => void
  audiences: Audience[]
  tags: TagProps[]
}

const AddAudienceModal = ({ showModal, setShowModal, submitAudience, audiences, tags }: Props) => {
  const [inactiveAudiences, setInactiveAudiences] = useState<Audience[]>([])
  const [tagList, setTagList] = useState<string[]>([])
  const [checkedMap, setChecked] = useState<Map<string, boolean>>(new Map())
  const [audiencesToReactivate, setAudiencesToReactive] = useState<string[]>([])

  useEffect(() => {
    setChecked(() => {
      const newMap = new Map()
      tags.forEach(({ name }) => {
        newMap.set(name, false)
      })
      return newMap
    })
  }, [tags])

  useEffect(() => {
    getInactiveAudiences().then(({ data: inactiveAudiencesData }) => {
      setInactiveAudiences(inactiveAudiencesData)
    })
  }, [audiencesToReactivate])

  const handleTagChange = (tagValue: string, e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.checked) {
      setTagList(tagList.concat(tagValue))
      setChecked(prevChecked => new Map(prevChecked).set(tagValue, true))
    } else {
      let copiedArray = [...tagList]
      copiedArray.splice(copiedArray.indexOf(tagValue), 1)
      setTagList(copiedArray)
      setChecked(prevChecked => new Map(prevChecked).set(tagValue, false))
    }
  }

  const handleActiveSwitchChange = (audienceId: string, checkedValue: boolean) => {
    if (checkedValue) {
      setAudiencesToReactive(audiencesToReactivate.concat(audienceId))
    } else {
      setAudiencesToReactive(audiencesToReactivate.splice(audiencesToReactivate.indexOf(audienceId), 1))
    }
  }

  const cancelInterceptor = () => {
    setShowModal(false)
    flipAllCheckboxSelect()
    setTagList([])
    setAudiencesToReactive([])
  }

  const handleSubmitAudience = () => {
    const audienceName = (document.getElementById('audienceName') as HTMLInputElement).value
    if (duplicateName(audienceName, audiences)) {
      toaster('WARNING', 'Audience name already exists. Please select unique name.')
    } else if (tagList.length === 0 && audiencesToReactivate.length === 0) {
      toaster('WARNING', 'An audience must have tags. Please select tags.')
    } else if (!audienceName && audiencesToReactivate.length === 0) {
      alert('An audience must have a name. Please select unique name.')
    } else {
      submitAudience(audienceName, tagList, audiencesToReactivate)
      setTagList([])
      setAudiencesToReactive([])
      flipAllCheckboxSelect()
    }
  }

  const flipAllCheckboxSelect = () => {
    const copiedMap = new Map(checkedMap)
    copiedMap.forEach((val, key) => {
      copiedMap.set(key, false)
    })

    setChecked(copiedMap)
  }
  const renderInactiveAudience = () => {
    if (inactiveAudiences.length > 0) {
      return <ActiveAudienceTable audiences={inactiveAudiences} handleSwitchChange={handleActiveSwitchChange} />
    }
    return <React.Fragment></React.Fragment>
  }

  return (
    <Modal show={showModal} onHide={cancelInterceptor} enforceFocus={false} backdrop="static">
      <Modal.Header>
        <div className="modal-title h4">Add New Audience</div>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label>
            <b>Audience Name:</b>
          </label>
          <br />
          <input className="form-control" type="text" name="title" id="audienceName" />
        </div>
        <div id="checkList">
          <label className="label">
            <b>Audience's Tags:</b>
          </label>
          <LightTooltip
            title="Compile selection of tags to create targeted audience "
            placement="right"
            data-multiline="true"
          >
            <InfoIcon fontSize="small" />
          </LightTooltip>
          <form id="checkList">
            <table className="d-inline mr-5" id="tag-table-add">
              <tbody>
                <tr key="client">
                  <th>{TAGS.CLIENT}</th>
                </tr>
                {tags
                  .filter(tag => tag.type === 'CLIENT')
                  .map(tag => (
                    <tr key={tag.name}>
                      <td> 
                        <Checkbox
                          checked={checkedMap.get(tag.name)}
                          value={checkedMap.get(tag.name)}
                          onChange={e => handleTagChange(tag.name, e)}
                        />
                        {tag.name}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <table className="d-inline mr-5">
              <tbody>
                <tr key="bizUnit">
                  <th>{TAGS.BIZ}</th>
                </tr>

                {tags
                  .filter(tag => tag.type === 'BIZ')
                  .map(tag => (
                    <tr key={tag.name}>
                      <td>
                        <Checkbox
                          checked={checkedMap.get(tag.name)}
                          value={checkedMap.get(tag.name)}
                          onChange={e => handleTagChange(tag.name, e)}
                        />
                        {tag.name}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <table className="d-inline mr-5">
              <tbody>
                <tr key="LOS">
                  <th>{TAGS.LOS}</th>
                </tr>
                {tags
                  .filter(tag => tag.type === 'LOS')
                  .map(tag => (
                    <tr key={tag.name}>
                      <td>
                        <Checkbox
                          checked={checkedMap.get(tag.name)}
                          value={checkedMap.get(tag.name)}
                          onChange={e => handleTagChange(tag.name, e)}
                        />
                        {tag.name}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {renderInactiveAudience()}
            <div className="button-container">
              <Button variant="primary" data-cy="submitAudience" onClick={handleSubmitAudience}>
                Submit Audience
              </Button>
              <Button variant="secondary" onClick={cancelInterceptor}>
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default AddAudienceModal
