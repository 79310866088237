import React from 'react'
import { PageContent } from './PageStyles'
import { InfoCard } from '../InfoCard/InfoCard'
import { MENU_LIST_ITEMS, ROLES } from 'consts'

export const Page = ({ user }) => {
  return (
    <PageContent className="page-content">
      <InfoCard
        category="Tips"
        title="Role permissions"
        subtitle="How can I check my permissions?"
        description='Click your email address in the menu bar and hover with the cursor on top of "Role: (e.g. Admin)" to see your permissions list'
      />
      <InfoCard
        category="Tips"
        title="Cloning articles"
        subtitle="Can I clone an article into a new one?"
        description="Absolutely! If you would like to speed up your workflow and copy the content of an article into a new one, you may do so by clicking the clone icon available in My Articles and All Articles pages"
      />
      {user.role !== ROLES.USER && (
      <InfoCard
        category="Quick Links"
        title="My Articles"
        subtitle="All the articles you have written"
        description="Click the button below to visit your articles"
        buttonLink={{ path: `${MENU_LIST_ITEMS.ARTICLES.MY.PATH}`, label: `${MENU_LIST_ITEMS.ARTICLES.MY.LABEL_NAME}` }}
      />
      )}
      {user.role !== ROLES.USER && (
        <InfoCard
          category="Quick Links"
          title="My Newsletters"
          subtitle="All the newsletters you have created"
          description="Click the button below to visit your newsletters"
          buttonLink={{
            path: `${MENU_LIST_ITEMS.NEWSLETTERS.MY.PATH}`,
            label: `${MENU_LIST_ITEMS.NEWSLETTERS.MY.LABEL_NAME}`
          }}
        />
      )}
    </PageContent>
  )
}
