import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom'

  interface InfoCardProps {
    category: string
    title: string
    subtitle: string
    description: string
    buttonLink?: { path: string; label: string }
  }

const useStyles = makeStyles({
  root: {
    maxWidth: 300,
    margin: '1rem',
    boxShadow: '6px 6px 9px rgba(0,0,0,0.1)'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export const InfoCard = ({ category, title, subtitle, description, buttonLink }: InfoCardProps) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {category || `Word of the day`}
        </Typography>
        <Typography variant="h5" component="h2">
          {title || `News•let•ter`}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          <em>{subtitle || `noun`}</em>
        </Typography>
        <Typography variant="body2" component="p">
          <br />
          {description || '"A bulletin issued periodically to the members of a society or other organization."'}
        </Typography>
      </CardContent>
      {buttonLink && (
        <CardActions>
          <Button size="small" color="primary" onClick={() => history.push(`${buttonLink.path}`)}>
            Go to {buttonLink.label}
          </Button>
        </CardActions>
      )}
    </Card>
  )
}