import React, { useState } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { DateUtils } from 'react-day-picker'
import dateFnsFormat from 'date-fns/format'
import dateFnsParse from 'date-fns/parse'
import PropTypes from 'prop-types'

/**
 * Provides functionality to pick a date from calendar or provide it manually
 *
 * Its state variables are:
 *    @property {Boolean} isInvalid - whether input provided manually is invalid
 */
const DatePicker = props => {
  const [isInvalid, setIsInvalid] = useState(false)

  const styles = {
    invalidInput: {
      borderColor: 'red'
    },
    formControl: {
      width: '100%',
      height: 'calc(1.5em + 0.75rem + 2px)',
      padding: '0.375rem 0.75rem',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5,
      color: '#495057',
      backgroundColor: '#fff',
      backgroundClip: 'padding-box',
      border: '1px solid #ced4da',
      borderRadius: '0.25rem',
      outline: 'none',
      transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out'
    }
  }

  const DATE_FORMAT = 'MM/dd/yyyy'

  /** Specific for react-day-picker, parses string to date */
  const parseDate = (str, format, locale) => {
    const parsed = dateFnsParse(str, format, new Date(), { locale })
    if (DateUtils.isDate(parsed)) {
      return parsed
    }
    return undefined
  }

  /** Specific for react-day-picker, formats date to string */
  const formatDate = (date, format, locale) => {
    return dateFnsFormat(date, format, { locale })
  }

  /**
   * Triggered on picker change, handles time (only date is selectable)
   * Defines whether provided value is invalid or not - if anything is provided into the field, but date was not parsed, then it's marked as invalid
   * @param {Date} date - already parsed date provided into picker
   * @param {?} dayModifiers - not used
   * @param {?} event - context of date input picker
   */
  const dayChangeHandler = (date, dayModifiers, event) => {
    if (date) {
      if (props.type === 'dateFrom') {
        date.setHours(0, 0, 0)
      } else {
        date.setHours(23, 59, 59)
      }
    }

    const inputValue = event.input.value
    setIsInvalid(!date && inputValue !== '')
    return props.onChange(date)
  }

  return (
    <DayPickerInput
      formatDate={formatDate}
      format={DATE_FORMAT}
      parseDate={parseDate}
      placeholder={DATE_FORMAT}
      selected={props.selected}
      onDayChange={dayChangeHandler}
      dayPickerProps={{ showOutsideDays: true }}
      inputProps={isInvalid ? { style: styles.invalidInput } : { style: styles.formControl }}
    />
  )
}

DatePicker.propTypes = {
  selected: PropTypes.object,
  onChange: PropTypes.func,
  type: PropTypes.string
}

export default DatePicker
