import React, { createContext, useCallback, useState } from 'react'
import Auth from './auth'

import { getUserRole } from 'apis/admin.service'
import { useError } from './error-context'

export const UserContext = createContext({
  user: {},
  getUser: () => ({})
})

/**
 * User context for whole application
 *
 * Its state variables are:
 * @property {object} user - user data object
 * @property {Boolean} isAuthenticated - indicates if user is currently authenticated
 * */

const UserContextProvider = props => {
  const [user, setUser] = useState({})
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const auth = Auth
  const { setError } = useError()

  /** Sign in using AD */
  const login = () => {
    auth.federatedSignIn()
  }

  /** Sign out and clean user state */
  const logout = useCallback(() => {
    auth.signOut()
    setUser({})
    setIsAuthenticated(false)
  }, [auth])

  /** Using aws amplify instance checks if user is authenticated */
  const checkIsAuthenticated = async () => {
    try {
      if (process.env.REACT_APP_MOCK_SSO === 'true') {
        return true
      }
      await auth.currentAuthenticatedUser()
      return true
    } catch (err) {
      return false
    }
  }

  /** Using token gets user role from database */
  const getUser = async () => {
    try {
      const response = await getUserRole()
      if (response === 'Insufficient Permissions') {
        alert('You do not have permissions to view this page. Redirecting to home page.')
        setIsAuthenticated(false)
        return
      }
      if (response.success === false) {
        alert('Your token is expired, invalid, or missing.  Please log in again.')
        setUser({})
        setIsAuthenticated(false)
        return
      }
      if (!response) {
        setUser({})
        setIsAuthenticated(false)
        return
      }

      setUser(response.data)
      setIsAuthenticated(true)
    } catch (error) {
      setError(error)
      setUser({})
      setIsAuthenticated(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  const userContext = {
    getUser,
    isAuthenticated,
    setIsAuthenticated,
    logout,
    user,
    login,
    checkIsAuthenticated,
    setUser
  }

  return <UserContext.Provider value={userContext}>{props.children}</UserContext.Provider>
}

export default UserContextProvider
