import React from 'react'
import './article.component.css'
import parse from 'html-react-parser'

const ArticleComponent = ({ article, type }) => {
  const { content, title = '' } = article

  return (
    <>
      <div className="article-content-nl">
        {type === 'no-header' ? null : (
          <div>
            <h2>{title}</h2>
            <br />
          </div>
        )}
        <div data-cy="article-content">{parse(content)}</div>
      </div>
    </>
  )
}

export default ArticleComponent
