import React from 'react'

import ContentItem from './contentItem/contentItem.component'

const Content = props => {
  let curSection = null
  /**
   * creates an array of the sections for the newsletter then kicks off
   * the construction of the newsletter by calling createSections
   */
  const constructContent = () => {
    if (props.sections) {
      return createSections(props.sections, props.articles)
    }
    const articles = props.articles
    const sectionSet = []

    articles.forEach(article => {
      if (article.section && !sectionSet.includes(article.section)) {
        sectionSet.push(article.section)
      }
    })
    if (sectionSet.length) {
      return createSections(sectionSet, articles)
    }
    return props.articles.map((article, index) => (
      <React.Fragment key={index}>
        <ContentItem article={article} />
      </React.Fragment>
    ))
  }
  /**
   * makes the sections of the newsletter based on sectionSet array
   */
  const createSections = (sectionSet, articles) => {
    if (!sectionSet.includes('Header')) {
      sectionSet.unshift('Header')
      sectionSet.push('Footer')
    }
    return sectionSet.map((section, index) => {
      if (articles.some(article => article.section === section)) {
        return (
          <React.Fragment key={index}>
            {createSectionHeader(section ? section : '')}
            {createArticles(section, articles)}
          </React.Fragment>
        )
      }
      return null
    })
  }

  /**
   * determines when to add another section header to the newsletter
   */
  const createSectionHeader = section => {
    // We don't want a section title for headers and footers
    if (section === 'Header' || section === 'Footer') {
      return null
    }
    if (section !== curSection) {
      curSection = section
      return (
        <div style={{ textAlign: 'center' }}>
          <h2 style={{ color: '#5480C0', fontSize: 24 }}>{section}</h2>
          <br />
        </div>
      )
    }
  }

  /**
   * creates articles for each article belonging to current section
   */
  const createArticles = (section, articles) => {
    return articles.map((currentarticle, index) => {
      if (currentarticle.section === section) {
        return (
          <div key={index}>
            <div key={currentarticle._id}>
              <React.Fragment key={currentarticle._id}>
                <ContentItem article={currentarticle} />
              </React.Fragment>
            </div>
            <hr />
            <br />
          </div>
        )
      }
      return null
    })
  }

  return <>{constructContent()}</>
}

export default Content
