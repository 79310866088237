import 'bootstrap/dist/css/bootstrap.min.css'
import {
  CreateArticle,
  LoginComponent,
  AllArticleList,
  MyArticleList,
  Admin,
  CreateNewsletter,
  MyNewsletters,
  EditNewsletter,
  AllNewsletters,
  RetrieveVideo,
  EditNewsletterReadOnly,
} from 'components'
import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import toaster from 'components/toast/toast.component'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'
import { UserContext } from 'context/user-context'
import { useError } from 'context/error-context'
import HeaderAndFooter from 'components/headerAndFooter/HeaderAndFooter'
import { getUserRole } from 'apis/admin.service'
import Error from './components/Error'
import ConfirmDialog from './components/confirmDialog/confirm-dialog'
import ErrorAlert from './components/error-alert/ErrorAlert'
import { Home } from 'components/NewDesign/Home/Home'
import { MenuBar } from 'components/NewDesign/MenuBar/MenuBar'
import { ArticleEditor } from 'components/NewDesign/ArticleEditor/ArticleEditor'

require('dotenv').config()

const instanceEnv = process.env.REACT_APP_ENV

/**
 * It is a main container for whole application
 *
 * Its state variables are:
 * @property {Boolean} isLoading - indicates pending state of component
 * */
const App = () => {
  /** useNavbar - boolean state variable used to determine whether navbar should be visible or not */
  const [useNavbar, setUseNavbar] = useState(true)
  const { isAuthenticated, setUser, setIsAuthenticated, login, logout, checkIsAuthenticated } = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(true)

  const { setError } = useError()

  useEffect(() => {
    async function checkAuth() {
      setIsLoading(true)
      const isAuth = await checkIsAuthenticated()
      if (isAuth) {
        try {
          const response = await getUserRole()
          if (response === 'Insufficient Permissions') {
            toaster('ERROR', 'You do not have permissions to view this page. Redirecting to home page.')
            logout()
          } else if (response.success === false) {
            toaster('ERROR', 'Your token is expired, invalid, or missing.  Please log in again.')
            logout()
          } else if (!response) {
            logout()
          }
          setUser(response.data)
          setIsAuthenticated(true)
        } catch (err) {
          setError(err)
        }
      }
      setIsLoading(false)
    }
    checkAuth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /** HOC for protected routes */
  const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={props => {
          if (isAuthenticated) {
            return (
              <>
                {useNavbar && <MenuBar instance={instanceEnv} />}
                <Component {...props} />
              </>
            )
          }
          // Not authenticated, redirect to /login
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location }
              }}
            />
          )
        }}
      />
    )
  }

  return (
    <>
      <ConfirmDialog />
      <ErrorAlert />
      {!isLoading ? (
        <Router>
          <Switch>
            <PrivateRoute exact path="/" component={Home} />
            <Route
              path="/login"
              exact
              render={props => <LoginComponent {...props} isAuthenticated={isAuthenticated} login={login} />}
            />
            <PrivateRoute path="/create" component={CreateArticle} />
            <PrivateRoute path="/createnewsletter" exact component={CreateNewsletter} />
            <PrivateRoute path="/allarticles" exact component={AllArticleList} />
            <PrivateRoute path="/myarticles" exact component={MyArticleList} />
            <PrivateRoute path="/mynewsletters" exact component={MyNewsletters} />
            <PrivateRoute path="/allnewsletters" exact component={AllNewsletters} />
            <PrivateRoute path="/admin" exact component={Admin} />
            <PrivateRoute path="/headerandfooter" exact component={HeaderAndFooter} />
            <PrivateRoute path="/editnewsletter/:id" exact component={EditNewsletter} />
            <PrivateRoute path="/editnewsletterreadonly/:id" exact component={EditNewsletterReadOnly}/>
            {/* This need to be removed when the article editor is implemented */}
            {instanceEnv === 'dev' && <PrivateRoute exact path="/articleEditor" component={ArticleEditor} />}
            <Route
              path="/retrieve-video/:id"
              exact
              render={props => (
                <RetrieveVideo {...props} setUseNavbar={useNavbarValue => setUseNavbar(useNavbarValue)} />
              )}
            />
            <Route component={Error} />
          </Switch>
          <ToastContainer autoclose={8000} />
        </Router>
      ) : null}
    </>
  )
}

export default App
