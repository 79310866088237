export const duplicateName = (name, audienceObject) => {
  for (let i = 0; i < audienceObject.length; i++) {
    JSON.stringify(audienceObject[i].name)
    if (JSON.stringify(name) === JSON.stringify(audienceObject[i].name)) {
      return true
    }
  }
  return false
}

export const duplicateTags = (selectedTag, currentTags) => {
  if (currentTags.includes(selectedTag.tagList.client)) {
    return true
  } else if (currentTags.includes(selectedTag.tagList.bizUnit)) {
    return true
  } else if (currentTags.includes(selectedTag.tagList.losTag)) {
    return true
  }

  return false
}
