import axios, { AxiosPromise } from 'axios'
import { VimeoVideo } from 'models/vimeo-video'

const VimeoAxios = axios.create({
  baseURL: `https://api.vimeo.com`,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_VIMEO_TOKEN}`
  }
})

export const getVideo = (vimeoId: string): AxiosPromise<VimeoVideo> => VimeoAxios.get(`/videos/${vimeoId}`)

export const getVideoId = (video_url: string): AxiosPromise<Object> =>
  axios.get(`https://vimeo.com/api/oembed.json?url=${video_url}`)
