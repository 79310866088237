import React, { useCallback, useContext, useEffect, useState } from 'react'
import orderBy from 'lodash/orderBy'

import EditUserModal from './update/edit-user-modal.component'
import EditAudienceModal from './update/edit-audience-modal.component'
import AddUserModal from './create/add-user-modal.component'
import AddAudienceModal from './create/add-audience-modal.component.tsx'
import AdminTable from './tables/admin-table.component'
import AudienceTable from './tables/audience-table.component'
import TagsGrid from './tables/tags-grid.component'
import { checkForUserDataConsistency, checkForNewsletterDataConsistency } from 'utils/dataConsistency'
import './adminComponent.css'
import {
  createAudience,
  deleteAudience as axiosDeleteAudience,
  getActiveAudiences,
  patchAudience,
  patchAudienceFromAudienceTable
} from 'apis/audience.service'
import { getUsers, patchUser } from 'apis/admin.service'
import { useError } from 'context/error-context'
import { createUser, resetUser as handleResetUser, deleteUser as axiosDeleteUser } from 'apis/user.service'
import { getAllTags } from 'apis/article.service'
import { UserContext } from 'context/user-context'
import { Backdrop } from './../backdrop'
import { ROLES } from '../../consts'
import { Paper, Typography, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import toaster from 'components/toast/toast.component'
import { useConfirmDialog } from 'context/confirm-dialog-context'

const invertDirection = {
  asc: 'desc',
  desc: 'asc'
}
const Admin = () => {
  const { user } = useContext(UserContext)
  const [showEditUserModal, setShowEditUserModal] = useState(false)
  const [showAddUserModal, setShowAddUserModal] = useState(false)
  const [sortedAudiences, setSortedAudiences] = useState([])
  const [audienceSort, setAudienceSort] = useState({ column: 'name', order: 'asc' })
  const [showAddAudienceModal, setShowAddAudienceModal] = useState(false)
  const [showEditAudienceModal, setShowEditAudienceModal] = useState(false)
  const [currentAudience, setCurrentAudience] = useState({})
  const [userToEdit, setUserToEdit] = useState({})
  const [userColumnToSort, setUserColumnToSort] = useState('_id')
  const [userSortDirection, setUserSortDirection] = useState('asc')
  const [sortedUsers, setSortedUsers] = useState([])
  const [userIsLoading, setUserIsLoading] = useState(false)
  const [audienceIsLoading, setAudienceIsLoading] = useState(false)
  const [tags, setTags] = useState([])
  const [isPending, setIsPending] = useState(false)
  const [userFilter, setUserFilter] = useState('')
  const [audiencesFilter, setAudiencesFilter] = useState('')
  const { getUser } = useContext(UserContext)
  const { setConfirmDialogSettings, setOpenConfirmDialog } = useConfirmDialog()

  const { setError } = useError()

  const history = useHistory()

  const grabUsers = useCallback(async () => {
    setUserIsLoading(true)
    try {
      const { data: usersData } = await getUsers()
      if (usersData.success === false) {
        toaster('ERROR', 'Your token is expired, invalid, or missing.  Please log in again.')
      }
      if (usersData !== 'Insufficient Permissions') {
        setSortedUsers(orderBy(usersData, [userColumnToSort], [userSortDirection]))
      }
    } catch (error) {
      setError(error)
    } finally {
      setUserIsLoading(false)
    }
  }, [userColumnToSort, userSortDirection, setError])

  const grabAudiences = useCallback(async () => {
    setAudienceIsLoading(true)
    try {
      const sort = {
        sortBy: audienceSort.column,
        orderBy: audienceSort.order
      }

      const { data: activeAudiencesData } = await getActiveAudiences(sort)
      setSortedAudiences(activeAudiencesData)
    } catch (error) {
      if (sortedAudiences.success === false) {
        setError('Your token is expired, invalid, or missing.  Please log in again.')
      } else {
        setError(error)
      }
    } finally {
      setAudienceIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audienceSort.order])

  const addUser = useCallback(
    async (userId, userName, userRole, userAudience) => {
      const duplicateUser = sortedUsers.find(u => u._id === userId)

      if (duplicateUser) {
        toaster('ERROR', 'Duplicate User. User was not added.')
        return
      }

      try {
        setIsPending(true)
        const userCreatedResponse = await createUser({
          _id: userId,
          role: userRole,
          audience: userAudience,
          name: userName,
          lastLogin: ''
        })
        grabUsers()
        setShowAddUserModal(false)
        return userCreatedResponse
      } catch (error) {
        setError(error)
      } finally {
        setIsPending(false)
      }
    },
    [sortedUsers, grabUsers, setError]
  )

  useEffect(() => {
    if (!sortedUsers.length) {
      grabUsers()
    }
  }, [grabUsers, sortedUsers])

  useEffect(() => {
    const getTags = async () => {
      const { data } = await getAllTags()
      setTags(data)
    }
    try {
      getTags()
    } catch (error) {
      setError(error)
    }
  }, [setError])

  const handleEditUser = async (userRole, finalAudienceList, userName) => {
    if (userRole !== ROLES.ADMIN) {
      if (finalAudienceList.filter(audience => audience.currentAudience).length === 0) {
        toaster(
          'WARNING',
          'Audience is required for User Roles. Please select an audience to add user.'
        )
        return
      }
    }
    try {
      const { data: updatedUser } = await patchUser(userToEdit._id, {
        name: userName,
        role: userRole,
        audience: finalAudienceList
      })

      checkForNewsletterDataConsistency(updatedUser)
      grabUsers()
      getUser()
      setShowEditUserModal(false)
    } catch (err) {
      setError(err)
    }
  }

  const handleDeleteUser = userSelected => {
    if (user._id === userSelected._id) {
      toaster('ERROR', 'You cannot delete your own user profile.')
    } else {
      setConfirmDialogSettings({
        handleClose: () => {
          setOpenConfirmDialog(false)
        },
        handleConfirm: async () => {
          try {
            setOpenConfirmDialog(false)
            await axiosDeleteUser(userSelected._id)
            await grabUsers()
          } catch (error) {
            setError(error)
          } finally {
            setUserFilter('')
          }
        },
        title: 'Delete user',
        text: 'Are you sure you would like to delete the selected user?'
      })
      setOpenConfirmDialog(true)
    }
  }

  const handleDeleteAudience = currentAudience => {
    const usersWithAudienceToDelete = sortedUsers.filter(
      user => !!user.audience.find(audience => audience._id === currentAudience._id && audience.currentAudience)
    )

    if (usersWithAudienceToDelete.length === 0) {
      setConfirmDialogSettings({
        handleClose: () => {
          setOpenConfirmDialog(false)
        },
        handleConfirm: async () => {
          try {
            setOpenConfirmDialog(false)
            const { data } = await axiosDeleteAudience(currentAudience._id)
            /*eslint-disable-next-line no-warning-comments*/
            // TODO: Fix this in NT-839
            checkForUserDataConsistency(data)
            await grabAudiences()
          } catch (error) {
            setError(error)
          } finally {
            setAudiencesFilter('')
          }
        },
        title: 'Delete audience',
        text: 'Are you sure you would like to delete the selected Audience?'
      })
      setOpenConfirmDialog(true)
    } else {
      toaster(
        'ERROR',
        `You cannot delete this audience until it has been removed from the following users:\n${usersWithAudienceToDelete
          .map(user => user._id)
          .join('\n')}`
      )
    }
  }

  const addAudience = async (audienceName, tagList, reactivateList) => {
    if (reactivateList.length > 0) {
      reactivateList.forEach(audienceId => {
        patchAudience(audienceId, { active: true }).then(() => {
          grabAudiences()
        })
      })
    }
    if (tagList.length > 0) {
      try {
        await createAudience({
          name: audienceName,
          tags: tagList,
          active: true
        })
        setShowAddAudienceModal(false)
        await grabAudiences()
      } catch (error) {
        if (error.response.status === 409) {
          toaster('WARNING', 'Audience name already exists. Please select unique name.')
          setError(error.response.data)
          return
        }
        setError(error)
      }
    }
  }

  const handleEditAudience = (name, tagList) => {
    let usersWithAudienceToEditName
    let newName = currentAudience.name
    let newTags = currentAudience.tags
    const date = new Date()
    if (tagList !== null && tagList !== currentAudience.tags) {
      newTags = tagList
    }
    if (name !== '' && name !== currentAudience.name) {
      newName = name
      usersWithAudienceToEditName = sortedUsers.filter(
        user =>
          user.audience.find(audience => audience._id === currentAudience._id && audience.currentAudience) !== undefined
      )
      if (usersWithAudienceToEditName.length > 0) {
        setConfirmDialogSettings({
          handleClose: () => {
            setOpenConfirmDialog(false)
          },
          handleConfirm: async () => {
            try {
              setOpenConfirmDialog(false)
              try {
                const { data: audienceData } = await patchAudienceFromAudienceTable(currentAudience._id, {
                  name: newName,
                  tags: newTags,
                  modifiedBy: user._id,
                  dateModified: date
                })
                const newAudience = audienceData
                for (let i = 0; i < usersWithAudienceToEditName.length; ++i) {
                  patchUser(usersWithAudienceToEditName[i]._id, {
                    audience: usersWithAudienceToEditName[i].audience.map(audience =>
                      currentAudience._id === audience._id ? { ...audience, name: name } : audience
                    )
                  }).then(({ data: updatedUser }) => {
                    checkForNewsletterDataConsistency(updatedUser)
                    grabUsers()
                  })
                }
                setShowEditAudienceModal(false)
                checkForUserDataConsistency(newAudience, grabUsers)
                await grabAudiences()
              } catch (error) {
                setError(error)
              }
            } catch (error) {
              setError(error)
            }
          },
          title: 'Edit audience',
          text: `${
            'Are you sure you would like to change the name of the selected Audience?\n' +
            'This will update the audience name for the following users:\n'
          }${usersWithAudienceToEditName.map(user => user._id).join('\n')}`
        })
        setOpenConfirmDialog(true)
        return
      }
    }

    patchAudienceFromAudienceTable(currentAudience._id, {
      name: newName,
      tags: newTags,
      modifiedBy: user._id,
      dateModified: date
    })
      .then(response => {
        const newAudience = response.data
        setShowEditAudienceModal(false)
        checkForUserDataConsistency(newAudience, grabUsers)
        grabAudiences()
      })
      .catch(err => setError(err))
  }

  const validationCheck = (userId, userName, userRole, finalAudienceList) => {
    finalAudienceList.forEach(audience => {
      audience.currentAudience = true
    })

    const emailCheck = /^[a-zA-Z0-9._-]+@DAUGHERTY.(COM|PA|PL)$/
    if (userRole !== ROLES.ADMIN) {
      if (finalAudienceList.length === 0) {
        toaster(
          'WARNING',
          'Audience is required for User Roles. Please select an audience to add user.'
        )
        return
      }
    }

    if (!emailCheck.test(userId.toUpperCase())) {
      toaster('WARNING', 'Please use valid Daugherty email.')
      return
    }

    const email = userId.split('@')
    const newUserId = `${email[0].toUpperCase()}@${email[1].toLowerCase()}`

    return addUser(newUserId, userName, userRole, finalAudienceList)
  }

  const startEdit = editUser => {
    setUserToEdit(editUser)
    setShowEditUserModal(true)
  }

  const startEditAudience = audience => {
    setCurrentAudience(audience)
    setShowEditAudienceModal(true)
  }

  const handleUserSort = column => {
    if (column.id === '_id') {
      const columnName = column.id
      setUserColumnToSort(columnName)
      const newSortDirection = userColumnToSort === columnName ? invertDirection[userSortDirection] : 'asc'
      setUserSortDirection(newSortDirection)
      setSortedUsers(orderBy(sortedUsers, [columnName], [newSortDirection]))
    }
  }

  const handleAudienceSort = column => {
    if (column.id === 'name') {
      setAudienceSort(prev => {
        if (prev.column === column.id) {
          return {
            ...prev,
            order: invertDirection[prev.order]
          }
        }
        return {
          column,
          order: 'asc'
        }
      })
    }
  }

  useEffect(() => {
    grabAudiences()
  }, [grabAudiences, audienceSort.order, audienceSort.column])

  return (
    <>
      <Backdrop open={isPending} />
      {user.role === ROLES.ADMIN ? (
        <>
          <EditUserModal
            setShowModal={setShowEditUserModal}
            showModal={showEditUserModal}
            user={userToEdit}
            handleEditUser={handleEditUser}
            audiences={sortedAudiences}
          />
          <AddUserModal
            setShowModal={setShowAddUserModal}
            showModal={showAddUserModal}
            handleUserValidation={validationCheck}
            audiences={sortedAudiences}
          />

          <AdminTable
            users={sortedUsers}
            startEdit={startEdit}
            delete={handleDeleteUser}
            reset={handleResetUser}
            setShowModal={setShowAddUserModal}
            handleUserSort={handleUserSort}
            columnToSort={userColumnToSort}
            sortDirection={userSortDirection}
            filterKey={userFilter}
            handleFilterChange={setUserFilter}
            isLoading={userIsLoading}
          />

          <AudienceTable
            audiences={sortedAudiences}
            setShowModal={setShowAddAudienceModal}
            startEdit={startEditAudience}
            delete={handleDeleteAudience}
            handleAudienceSort={handleAudienceSort}
            audienceSort={audienceSort}
            filterKey={audiencesFilter}
            handleFilterChange={setAudiencesFilter}
            isLoading={audienceIsLoading}
          />

          <AddAudienceModal
            showModal={showAddAudienceModal}
            setShowModal={setShowAddAudienceModal}
            submitAudience={addAudience}
            audiences={sortedAudiences}
            tags={tags}
          />

          <EditAudienceModal
            show={showEditAudienceModal}
            setShowModal={setShowEditAudienceModal}
            audience={currentAudience}
            handleCloseOnSubmit={handleEditAudience}
            audiences={sortedAudiences}
          />
          <TagsGrid />
        </>
      ) : (
        <>
          <Paper style={{ padding: '2rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography data-cy="permission-notification" variant="h5" style={{ marginBottom: '1rem' }}>
              You don't have permission to view this page.
            </Typography>
            <Button
              onClick={() => history.push('/')}
              variant="contained"
              style={{ backgroundColor: '#538b3f', color: 'white', fontWeight: '600' }}
            >
              Home
            </Button>
          </Paper>
        </>
      )}
    </>
  )
}

export default Admin
