import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import './../update-usermodal.component.css'
import { Chip } from '@material-ui/core'
import '../../FontStyle.css'
import { Button } from 'react-bootstrap'
import InfoIcon from '@material-ui/icons/Info'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import toaster from './../../toast/toast.component'

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 15
  }
}))(Tooltip)

/**
 * Provides a modal with edit user form.
 *
 * Its state variables are:
 *    @property {String} selectedAudienceId - ID of audience selected in Add Audience combobox
 *    @property {User.audience[]} currentUserAudiences - audiences assigned to the edited user
 *    @property {String} currentUserName - name of edited user
 *    @property {Boolean} openChangeDialog - whether change password dialog should be visible or not
 */
const EditUserModal = props => {
  const [selectedAudienceId, setSelectedAudienceId] = useState()
  const [currentUserAudiences, setCurrentUserAudiences] = useState([])
  const [currentUserName, setCurrentUserName] = useState('')

  /** Retrieves user audiences and sets it to currentUserAudiences */
  const grabCurrentAudiences = useCallback(() => {
    setCurrentUserAudiences(props.user.audience)
  }, [props.user.audience])

  useEffect(() => {
    if (props.showModal) {
      grabCurrentAudiences()
      setCurrentUserName(props.user.name)
    }
  }, [props.showModal, props.user.name, grabCurrentAudiences])

  /** Executed when user audience is being added, and removes added audience from currentUserAudiences */
  const handleSubmitAudience = () => {
    if (!selectedAudienceId) {
      alert('Please select an audience')
      return
    }

    const matchingAudience = currentUserAudiences.filter(audience => audience._id === selectedAudienceId)
    if (matchingAudience.length) {
      if (matchingAudience[0].currentAudience) {
        alert('You may not have duplicate audiences for a user.')
        return
      }

      return setCurrentUserAudiences(
        currentUserAudiences.map(audience =>
          audience._id === selectedAudienceId ? { ...audience, currentAudience: true } : audience
        )
      )
    }

    setCurrentUserAudiences(currentUserAudienceArray => [
      ...currentUserAudienceArray,
      { ...props.audiences.find(audience => audience._id === selectedAudienceId), currentAudience: true }
    ])
  }

  const cancelInterceptor = () => {
    props.setShowModal(false)
    setCurrentUserAudiences([])
    setSelectedAudienceId()
  }

  const handleDelete = audienceId => {
    setCurrentUserAudiences(currentUserAudiences.filter(audience => audience._id !== audienceId))
  }

  const handleCloseOnSubmit = async () => {
    if (currentUserName === '') {
      toaster('ERROR', "Please enter user's name")
    } else {
      try {
        await props.handleEditUser(document.getElementById('userRole').value, currentUserAudiences, currentUserName)
        setCurrentUserAudiences([])
        setSelectedAudienceId()
      } catch (error) {
        console.log(error)
      }
    }
  }

  /** Updates Add Audience combobox selection to be consistent with available audiences */
  const makeSureSelectedAudienceIsStillSelectable = selectableAudiences => {
    if (selectedAudienceId && !selectableAudiences.length) {
      setSelectedAudienceId('')
    } else if (
      selectableAudiences.length &&
      !selectableAudiences.find(audience => audience._id === selectedAudienceId)
    ) {
      setSelectedAudienceId(selectableAudiences[0]._id)
    }
  }

  /** Renders user audiences */
  const renderCurrentUserAudiences = () => {
    if (currentUserAudiences.find(audience => audience.currentAudience)) {
      return currentUserAudiences.map((audience, index) => (
        <React.Fragment key={index}>
          {audience.currentAudience ? (
            <Chip
              className="audience-chip"
              label={audience.name}
              onDelete={() => handleDelete(audience._id)}
              value={audience._id}
            ></Chip>
          ) : (
            <p></p>
          )}
        </React.Fragment>
      ))
    }

    return (
      <React.Fragment>
        <p className="audience">User Has No Current Audience</p>
      </React.Fragment>
    )
  }

  /** Renders audience options for Add Audience combobox */
  const renderAddAudienceOptions = () => {
    const userAudiencesIds = currentUserAudiences
      .filter(audience => audience.currentAudience)
      .map(audience => audience._id)

    const selectableAudiences = props.audiences.filter(audience => userAudiencesIds.indexOf(audience._id) === -1)

    makeSureSelectedAudienceIsStillSelectable(selectableAudiences)

    return selectableAudiences.map(audience => (
      <option key={audience.name} value={audience._id}>
        {audience.name}
      </option>
    ))
  }

  return (
    <Modal show={props.showModal} onHide={cancelInterceptor} enforceFocus={false} backdrop="static">
      <Modal.Header>Edit User</Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label>User ID:</label>
          <h3 data-cy="userId">{props.user._id}</h3>
        </div>
        <div className="form-group">
          <label>User Name:</label>
          <br />
          <input
            className="form-control"
            data-cy="tag-new-name"
            type="text"
            id="userName"
            value={currentUserName}
            onChange={event => {
              setCurrentUserName(event.target.value)
            }}
          ></input>
        </div>
        <div className="form-group">
          <label>Role:</label>
          <LightTooltip
            title={
              <React.Fragment>
                <p>
                  {' '}
                  <b>Admin</b>: CRUD newsletters and assign roles, and view all articles. <br />
                  <b>Publisher</b>: CRUD newsletters and publish articles. <br />
                  <b>Author</b>: View and create articles. <br />
                  <b>CRUD</b>: C='Create', R='Read', U='Update', D='Delete'{' '}
                </p>
              </React.Fragment>
            }
            place="right"
            data-multiline="true"
          >
            <InfoIcon fontSize="small" />
          </LightTooltip>
          <select className="form-control" defaultValue={props.user.role} id="userRole">
            <option key="default" value="" disabled>
              Select an Option
            </option>
            <option key="admin" value="admin">
              Admin
            </option>
            <option key="user" value="user">
              User
            </option>
          </select>
        </div>
        <div>
          <label className="label">Add Audience: </label>
          <LightTooltip
            title="You may select multiple audiences for a user. After selecting an audience press 'Submit Audience' "
            place="right"
            data-multiline="true"
          >
            <InfoIcon fontSize="small" />
          </LightTooltip>
          <select
            className="form-control"
            id="addAudience"
            defaultValue={''}
            onChange={e => setSelectedAudienceId(e.target.value)}
          >
            <option key="default" value="" disabled>
              Select an Option
            </option>
            {renderAddAudienceOptions()}
          </select>
        </div>
        <div className="mr-auto submit-button">
          <Button variant="primary" id="submitAudience" onClick={handleSubmitAudience}>
            Submit Audience
          </Button>
        </div>
        <div className="audience-list">
          <h2>User Audience(s) (click to delete):</h2>
          <div className="chip">{renderCurrentUserAudiences()}</div>
        </div>
        <div className="button-container">
          <Button variant="primary" onClick={handleCloseOnSubmit}>
            Save Changes
          </Button>
          <Button variant="secondary" onClick={cancelInterceptor}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

/**
 * Prop-types description
 *    showModal - whether should be visible or not
 *    user - edited user
 *    audiences - all audiences from the database
 *    setShowModal
 *    handleEditUser - saves user
 */
EditUserModal.propTypes = {
  showModal: PropTypes.bool,
  user: PropTypes.object,
  audiences: PropTypes.arrayOf(PropTypes.object),
  setShowModal: PropTypes.func,
  handleEditUser: PropTypes.func
}

export default EditUserModal
