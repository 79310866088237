import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import 'typeface-roboto'
import '@fontsource/montserrat'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import UserContextProvider from './context/user-context'
import { ErrorContextProvider } from './context/error-context'
import { ConfirmDialogProvider } from './context/confirm-dialog-context'
import { BrowserRouter } from 'react-router-dom'

ReactDOM.render(
  <ErrorContextProvider>
    <ConfirmDialogProvider>
      <UserContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </UserContextProvider>
    </ConfirmDialogProvider>
  </ErrorContextProvider>,

  document.getElementById('root')
)
