import axios from 'axios'

import Auth from '../context/auth'

export const AxiosWithToken = (base_url: string, tokenName = 'x-access-token') => {
  const axiosObject = axios.create({
    baseURL: base_url
  })
  if (process.env.REACT_APP_MOCK_SSO !== 'true') {
    axiosObject.interceptors.request.use(
      async config => {
        try {
          const session = await Auth.currentSession()
          config.headers = {
            [tokenName]: `${session.getIdToken().getJwtToken()}`
          }
        } catch (err) {
          config.headers = {
            [tokenName]: `${sessionStorage.getItem('id_token')}`
          }
        }

        return config
      },
      error => {
        Promise.reject(error)
      }
    )
  }
  return axiosObject
}
