import { Chip, ChipProps, styled } from '@material-ui/core'
import React from 'react'
import { NEWSLETTER_STATUSES } from 'consts'
import { capitalizeEveryWord } from 'utils/helpers'

interface StatusChipsProps extends ChipProps {
  status: typeof NEWSLETTER_STATUSES[keyof typeof NEWSLETTER_STATUSES]
}

const StyledChip = styled(Chip)(({ status }: StatusChipsProps) => {
  return {
    ...{
      draft: { color: '#2c3c66', backgroundColor: '#9db8ff' },
      edited: { color: '#7e470d', backgroundColor: '#fcb150' },
      published: { color: '#467901', backgroundColor: '#a9da60' }
    }[status.toLowerCase()],
    borderRadius: '8px'
  }
})

export const StatusChip = (props: StatusChipsProps) => (
  <StyledChip {...props} label={capitalizeEveryWord(props.status) || props.label} />
)
