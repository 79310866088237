import React, { useEffect, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import DeleteIcon from '@material-ui/icons/Delete'
import VisibilityIcon from '@material-ui/icons/Visibility'
import CreateIcon from '@material-ui/icons/Create'
import { useHistory } from 'react-router-dom'
import { GridBody, GridCell, GridRow, HeaderGridCell, HeaderGridRow, GridToolbar } from 'components/Grid'
import { theme } from '../../../../utils/themeSettings'
import { newsletterCellStyles, newsletterRowTemplate } from './newsletterMediaQueryStyles'
import PropTypes from 'prop-types'
import { useError } from 'context/error-context'
import { getActiveAudiencesForNewsletterGrid } from 'apis/audience.service'
import { NEWSLETTER_STATUSES, ROLES } from 'consts'
import { StatusChip } from 'components/statusChip/StatusChip'

const useStyles = makeStyles({
  root: {
    width: '100%'
  }
})

/** Component used to represent newsletters table on My Newsletters and Newsletters by category views */
const NewsletterGrid = props => {
  const [activeAudiences, setActiveAudiences] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const classes = useStyles()
  const page = props.page
  const rowsPerPage = props.rowsPerPage

  const { setError } = useError()

  const mediaQueries = {
    tablet: useMediaQuery('(max-width:991px)'),
    tablet_sm: useMediaQuery('(max-width:600px)')
  }

  const gridTemplate = [
    {
      query: mediaQueries.tablet_sm,
      template: newsletterRowTemplate.tablet_sm,
      cell: {
        padding: '10px',
        ...newsletterCellStyles.tablet_sm
      }
    },
    {
      query: mediaQueries.tablet,
      template: newsletterRowTemplate.tablet,
      cell: {
        padding: '10px',
        ...newsletterCellStyles.tablet
      }
    }
  ]

  const history = useHistory()
  const isPrivilegedUser =
    props.user.role === ROLES.USER || props.user.role === ROLES.ADMIN

  let tableRowStyles = {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr) repeat(4, 40px)',
    minHeight: 73,
    alignItems: 'center',
    fontSize: '0.875em',
    fontWeight: 500
  }

  let tableCellStyles = { padding: 16 }
  for (let index = 0; index < gridTemplate.length; index++) {
    if (gridTemplate[index].query) {
      tableRowStyles = { ...tableRowStyles, ...gridTemplate[index].template }
      tableCellStyles = { ...tableCellStyles, ...gridTemplate[index].cell }
      break
    }
  }

  const handleChangePage = (_, newPage) => {
    props.setPage(newPage)
    props.onTablePaginationChange({
      page: newPage,
      limit: rowsPerPage,
      sort: {
        sortBy: `${props.columnToSort}`,
        orderBy: `${props.sortDirection}`
      }
    })
  }

  const handleChangeRowsPerPage = event => {
    props.setRowsPerPage(event.target.value)
    props.setPage(0)
    props.onTablePaginationChange({
      page: page,
      limit: event.target.value,
      sort: {
        sortBy: `${props.columnToSort}`,
        orderBy: `${props.sortDirection}`
      }
    })
  }

  const createNewsletterRedirect = () => {
    history.push('/createnewsletter')
  }

  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }

  /** Definition of columns to be displayed in newsletter grid */
  const columns = [
    {
      id: 'datePublished',
      label: 'Publish Date',
      content: currentNewsletter => {
        const currentPublishDate = currentNewsletter.datePublished
          ? new Date(currentNewsletter.datePublished).toLocaleDateString([], dateOptions)
          : undefined
        return currentPublishDate
      },
      tooltip: ''
    },
    {
      id: 'audience',
      label: 'Audience',
      content: currentNewsletter => {
        if (activeAudiences.length > 0) {
          return audienceNameByIdMatch(currentNewsletter)
        }
        return isLoading && 'Loading..'
      },
      tooltip: '',
      format: value => value.toLocaleString('en-US')
    },
    {
      id: 'dateCreated',
      label: 'Date Created',
      content: currentNewsletter => {
        const currentCreationDate = new Date(currentNewsletter.dateCreated).toLocaleDateString([], dateOptions)
        return currentCreationDate
      },
      tooltip: ''
    },
    {
      id: 'numberOfArticles',
      label: 'Article Count',
      styles: { justifyContent: 'center' },
      content: currentNewsletter => currentNewsletter.numberOfArticles,
      tooltip: ''
    },
    {
      id: 'publishStatus',
      label: 'Status',
      content: currentNewsletter => <StatusChip status={currentNewsletter.publishStatus} />
    },
    {
      id: 'owner',
      label: 'Owner',
      styles: { textAlign: 'left' },
      content: currentNewsletter => currentNewsletter.owner?.split('@')[0]?.replace('.', ' ') ?? 'No assigned owner'
    },
    {
      id: 'edit',
      label: '',
      styles: { justifyContent: 'center' },
      icon: () => <CreateIcon />,
      link: currentNewsletter => `/editnewsletter/${currentNewsletter._id}`,
      tooltip: isPrivilegedUser ? 'Edit the content of this newsletter' : 'Preview the content of this newsletter'
    },
    {
      id: 'delete',
      label: '',
      styles: { justifyContent: 'center' },
      icon: () => <DeleteIcon data-cy="delete" />,
      tooltip: 'Delete this newsletter',
      clickHandler: currentNewsletter => props.delete(currentNewsletter),
      format: value => value.toFixed(2)
    },
    {
      id: 'view',
      label: '',
      styles: { justifyContent: 'center' },
      icon: () => <VisibilityIcon />,
      link: currentNewsletter => `/editnewsletterreadonly/${currentNewsletter._id}`,
      tooltip: 'Preview the content of this newsletter'
    }
  ]

  const getArrayForCSV = async () => {
    try {
      const { data } = await props.getAllNewsletters()
      const newRes = data.map(el => {
        const newEl = {}
        columns.forEach(col => {
          if (col.id !== 'edit' && col.id !== 'delete' && col.id !== 'view') {
            newEl[col.label] = String(col.content(el)).replace(/[,]+/g, ';').replace(/[#]+/g, ' ')
          }
        })
        return newEl
      })
      return newRes
    } catch (e) {
      setError(e)
    }
  }

  const audienceNameByIdMatch = currentNewsletter => {
    const audienceMatch = activeAudiences.find(audience => audience._id === currentNewsletter.audience)

    if (audienceMatch && audienceMatch.name) {
      return audienceMatch.name
    }

    return 'Audience inactive'
  }

  useEffect(() => {
    setIsLoading(true)
    getActiveAudiencesForNewsletterGrid()
      .then(response => {
        setActiveAudiences(response.data)
      })
      .catch(() => {
        setError('ERROR', `Couldn't fetch the active audiences, try re-authenticating`)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [setError])

  return (
    <ThemeProvider theme={theme}>
      <Paper className={classes.root}>
        <GridToolbar
          getArrayForCSV={getArrayForCSV}
          rightSideButtons={[{ label: '+ Newsletter', handler: createNewsletterRedirect }]}
        />
        <HeaderGridRow tableRowStyles={tableRowStyles}>
          {columns.map(column => (
            <HeaderGridCell
              column={column}
              key={column.id}
              data-cy={column.id}
              handleSort={() => props.handleNewsletterSort({ column, page, rowsPerPage })}
              columnToSort={props.columnToSort}
              sortDirection={props.sortDirection}
              cellStyles={tableCellStyles}
            />
          ))}
        </HeaderGridRow>
        <GridBody>
          {props.newsletters.map((currentNewsletter, rowIndex) => {
            return (
              <GridRow tableRowStyles={tableRowStyles} key={currentNewsletter._id} id={`newsletterRow${rowIndex}`}>
                {columns.map((column, index) => {
                  // is edit or delete cell
                  if (column?.id === 'edit' || column?.id === 'delete') {
                    // Do not show edit and delete icons for newsletters in published status
                    if (
                      currentNewsletter?.publishStatus.toLocaleLowerCase() ===
                      NEWSLETTER_STATUSES.PUBLISHED.toLocaleLowerCase()
                    ) {
                      return null
                    }
                  }
                  return (
                    <GridCell
                      data-cy={column.id}
                      key={`${column.id}${index}`}
                      column={column}
                      link={column.link ? column.link(currentNewsletter) : null}
                      clickHandler={column.clickHandler ? () => column.clickHandler(currentNewsletter) : null}
                      content={column.content ? () => column.content(currentNewsletter) : () => null}
                      tooltip={
                        typeof column.tooltip === 'function' ? column.tooltip(currentNewsletter) : column.tooltip
                      }
                      cellStyles={tableCellStyles}
                      iconSize={mediaQueries.tablet ? 'small' : 'medium'}
                    />
                  )
                })}
              </GridRow>
            )
          })}
        </GridBody>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={props.totalNewsletters}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          id="newsletterTablePagination"
          labelRowsPerPage={mediaQueries.tablet_sm ? null : 'Rows per page:'}
        />
      </Paper>
    </ThemeProvider>
  )
}

/**
 * Prop-types description
 *    newsletters - newsletters to display
 *    totalNewsletters - number of total newsletters to display in pagination
 *    page - current page in the pagination
 *    rowsPerPage - how many rows are displayed at once
 *    user - logged in user
 *    columnToSort - by which column sorting is currently done
 *    sortDirection - determines sorting direction, asc or desc
 *    isShowingAllNewsletters - whether Show All Newsletter checkbox is selected
 *    getAllNewsletters - used for exporting to CSV
 *    id - deprecated
 */
NewsletterGrid.propTypes = {
  newsletters: PropTypes.arrayOf(PropTypes.object),
  totalNewsletters: PropTypes.number,
  page: PropTypes.number,
  setPage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  setRowsPerPage: PropTypes.func,
  user: PropTypes.object,
  columnToSort: PropTypes.string,
  sortDirection: PropTypes.string,
  isShowingAllNewsletters: PropTypes.bool,
  toggleShowAllNewsletters: PropTypes.func,
  getAllNewsletters: PropTypes.func,
  delete: PropTypes.func,
  handleNewsletterSort: PropTypes.func,
  onTablePaginationChange: PropTypes.func,
  id: PropTypes.string
}

export default NewsletterGrid
