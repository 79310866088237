import React, { useState, useEffect } from 'react'
import { getAudienceById } from 'apis/audience.service'
import Preview from 'components/preview/preview.component'
import { Typography } from '@material-ui/core'
import ContentEditor from '../controls/content-editor.component'
import PreviewButton from '../controls/previewButton.component'
import { getOldImages, convertOldImagesToHtml } from '../utils/oldPicturesHandler'
import PropTypes from 'prop-types'

import { useError } from 'context/error-context'

/**
 * Used in HeaderAndFooter component to display header
 *
 * Its state variables are:
 *    @property {Boolean} showHeaderPreviewModal - whether to show preview modal
 */
const Header = ({ audienceId, setAudience, headerContent, setHeaderContent, headerImgs, setHeaderImgs }) => {
  const [showHeaderPreviewModal, setHeaderShowPreviewModal] = useState(false)
  const { setError } = useError()

  /**
   * Returns content of the footer from first audience passed as input
   * @param {Audience[]} res - audiences retrieved from backend
   * @returns {String} content of the footer, or empty string for empty response from backend
   */
  const getHeaderContent = res => {
    if (res.length > 0 && res[0] && res[0].header.content) {
      return res[0].header.content
    }
    return ''
  }

  /**
   * Retrieves images saved in the old way using imgs field and converts them to HTML
   * @param {Audience[]} audiences - audiences retrieved from backend
   * @returns {String} HTML containing old images, or empty string in case of no old images found
   */
  const getOldImagesHtml = async data => {
    if (data[0] && data[0].header.imgs.length) {
      const oldImages = await getOldImages(data[0].header.imgs)
      return convertOldImagesToHtml(oldImages)
    }
    return ''
  }

  /**
   * Executed when audience is changed in HeaderAndFooter parent component
   * Refreshes footer content
   */
  useEffect(() => {
    const handleNewAudience = async () => {
      if (!audienceId) {
        setHeaderContent('')
        setHeaderImgs([])
        return
      }
      try {
        const res = await getAudienceById(audienceId)
        setAudience(res.data[0])

        const content = await getHeaderContent(res.data)
        const oldImagesHtml = await getOldImagesHtml(res.data)

        setHeaderContent(oldImagesHtml + content)
        setHeaderImgs([])
      } catch (err) {
        setError(err)
      }
    }
    handleNewAudience()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audienceId])

  /**
   * Function to be executed in Content component when content has changed
   * @param {String} content - new content from the Content component
   */
  const handleHeaderEditorChange = content => {
    setHeaderContent(content)
  }

  /** Function to be executed when Preview button is clicked */
  const toggleHeaderPreviewModal = () => {
    setHeaderShowPreviewModal(!showHeaderPreviewModal)
  }

  /** Function to be executed when exiting preview modal */
  const handleCancel = () => {
    setHeaderShowPreviewModal(false)
  }

  return (
    <>
      <Preview
        show={showHeaderPreviewModal}
        handleCancel={handleCancel}
        articles={[{ imgs: headerImgs, content: headerContent }]}
      />
      <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <Typography variant="h5">Header</Typography>
      </div>
      <ContentEditor id="HeaderContent" handleChange={handleHeaderEditorChange} content={headerContent} />
      <PreviewButton key="HeaderPreviewButton" isActive={!!audienceId} onClick={toggleHeaderPreviewModal} />
    </>
  )
}

/**
 * Prop-types description
 *    audienceId - ID of currently selected audience
 *    headerContent - content of the header
 *    headerImgs - deprecated
 *    setAudience - redundant, already called in parent component
 */
Header.propTypes = {
  audienceId: PropTypes.string,
  headerContent: PropTypes.string,
  setHeaderContent: PropTypes.func,
  headerImgs: PropTypes.arrayOf(PropTypes.string),
  setHeaderImgs: PropTypes.func,
  setAudience: PropTypes.func
}

export default Header
