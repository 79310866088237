import React, { useEffect, useState, useCallback, useContext } from 'react'
import { Save } from '@material-ui/icons'

import Preview from 'components/preview/preview.component'
import { getImageById } from 'apis/image.service'
import { getAudienceById } from 'apis/audience.service'
import { getVideoId } from 'apis/vimeo.service'
import { UserContext } from 'context/user-context'
import toaster from '../../toast/toast.component'
import { useError } from 'context/error-context'

const PreviewContainer = props => {
  const { user } = useContext(UserContext)
  const [articles, setArticles] = useState([])
  const isPrivilegedUser = user.role === 'publisher' || user.role === 'author' || user.role === 'admin'
  const { setError } = useError()

  const handleVideoThumbnails = async newsLetterContent => {
    const newsLetterCopy = newsLetterContent.cloneNode(true)
    for await (const iframe of Array.from(newsLetterCopy.getElementsByTagName('iframe'))) {
      if (
        iframe.src.substring(0, 24) === 'https://player.vimeo.com' ||
        iframe.src.substring(0, 17) === 'https://vimeo.com'
      ) {
        const videoInfo = await getVideoId(iframe.src)
        const { width, height, thumbnail_url_with_play_button } = videoInfo.data
        const link = document.createElement('a')
        link.href = iframe.src
        const img = document.createElement('img')
        img.width = width
        img.height = height
        img.src = thumbnail_url_with_play_button
        link.appendChild(img)
        iframe.replaceWith(link)
      } else if (iframe.src.substring(0, 23) === 'https://www.youtube.com') {
        const link = document.createElement('a')
        link.href = iframe.src
        const img = document.createElement('img')
        const url = new URL(iframe.src)
        const pathName = url.pathname
        img.src = `https://i.ytimg.com/vi/${pathName.replace('/embed/', '')}/hqdefault.jpg`
        link.appendChild(img)
        iframe.replaceWith(link)
      }
    }

    return newsLetterCopy
  }

  const getImages = article => {
    if (article.imgs) {
      return Promise.all(article.imgs.map(img => (img.split(':')[0] === 'data' ? img : getImageById(img)))).catch(
        error => {
          setError(error)
          console.error('Failed to retreive image. Error: ', error)
          return []
        }
      )
    }
    return Promise.all([])
  }

  const addHeaderFooter = useCallback(
    async articles => {
      try {
        const audience = await getAudienceById(props.audienceId)
        const header = audience.data[0].header
        const footer = audience.data[0].footer
        header.section = 'Header'
        footer.section = 'Footer'
        articles.unshift(header)
        articles.push(footer)
      } catch (err) {
        setError(err)
      }

      return articles
    },
    [props.audienceId, setError]
  )

  const getArticles = useCallback(
    async articles => {
      const newArticles = await addHeaderFooter(articles)

      return await Promise.all(
        newArticles.map(article =>
          getImages(article).then(res => {
            const imgs = res.map(el => el.data || el)
            return { ...article, imgs }
          })
        )
      )
    },
    // eslint-disable-next-line
    [addHeaderFooter]
  )

  useEffect(() => {
    getArticles(props.articles.filter(article => article))
      .then(newArticles => {
        setArticles(newArticles)
      })
      .catch(err => {
        console.log(err)
      })
  }, [getArticles, props.articles])

  const handleCopyNewsletterClick = async () => {
    window.getSelection().removeAllRanges()
    const originalNewsLetter = document.getElementsByClassName('content')[0]

    const contentDiv = document.createElement('div')
    contentDiv.id = 'newsLetterClone'
    const newsLetterClone = await handleVideoThumbnails(originalNewsLetter)
    contentDiv.append(newsLetterClone)

    originalNewsLetter.lastChild.append(contentDiv)
    const selection = window.getSelection()
    const range = document.createRange()
    range.selectNodeContents(document.getElementById('newsLetterClone'))
    selection.addRange(range)
    document.execCommand('copy')
    toaster('SUCCESS', 'Copied Newsletter to clipboard!')
    window.getSelection().removeAllRanges()
    document.getElementById('newsLetterClone').remove()
  }

  if (isPrivilegedUser) {
    return (
      <Preview
        show={props.show}
        sections={props.sections}
        handleCancel={props.handleCancel}
        articles={articles}
        download={true}
        buttons={[{ name: 'Copy Newsletter', icon: <Save />, handler: handleCopyNewsletterClick }]}
      />
    )
  }
  return (
    <Preview
      show={props.show}
      sections={props.sections}
      handleCancel={props.handleCancel}
      articles={articles}
      buttons={[{ name: 'Copy Newsletter', icon: <Save />, handler: handleCopyNewsletterClick }]}
    />
  )
}

export default PreviewContainer
