import { Footer } from './footer'
import { Header } from './header'

export interface Owner {
  _id: string
  role: string
  audience: Audience[]
}

export interface Audience {
  _id: string
  name: string
  header: Header
  footer: Footer
  currentAudience: boolean
}

export interface NewsletterProps {
  _id: string
  title: string
  articles: string[]
  sections: string[]
  dateCreated: Date
  datePublished: Date
  audience: string
  owner: Owner
  publishStatus: string
}

export interface NewsletterListItem {
  _id: string
  title: string
  numberOfArticles: number
  dateCreated: Date
  datePublished: Date
  audience: string
  owner: string
  publishStatus: 'published' | 'draft' | 'edited'
}

export class Newsletter implements NewsletterProps {
  _id: string
  title: string
  articles: string[]
  sections: string[]
  dateCreated: Date
  datePublished: Date
  audience: string
  owner: Owner
  publishStatus: string

  constructor(props: Partial<NewsletterProps>) {
    this._id = props?._id
    this.title = props?.title ?? ''
    this.articles = props?.articles ?? []
    this.sections = props?.sections ?? []
    this.dateCreated = props?.dateCreated
    this.datePublished = props?.datePublished
    this.audience = props?.audience ?? ''
    this.owner = props?.owner
    this.publishStatus = props?.publishStatus ?? ''
  }
}
