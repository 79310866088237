import React, { useCallback, useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import Loader from '../../loader/loader.component'
import NewsletterForm from './newsletter-form.component'
import toaster from '../../toast/toast.component'
import '../../FontStyle.css'
import './newsletter-form.component.css'
import { createNewsletter } from 'apis/newsletter.service'
import { getAllSections } from 'apis/section.service'
import { UserContext } from 'context/user-context'
import { useHistory } from 'react-router-dom'
import { useConfirmDialog } from 'context/confirm-dialog-context'
import { ROLES } from 'consts'

import { useError } from 'context/error-context'

const CreateNewsletter = props => {
  const { user } = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(true)
  const [sections, setSections] = useState([])
  const [resetForm, setResetForm] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hasAccess, setHasAccess] = useState(false)
  const [owner, setOwner] = useState({})
  const history = useHistory()
  const { setError } = useError()
  const { setConfirmDialogSettings, setOpenConfirmDialog } = useConfirmDialog()

  const getSections = useCallback(() => {
    getAllSections()
      .then(response => {
        setSections(response.data.map(section => section.sectionName))
      })
      .catch(() => setError({ title: `Could not get the newsletter's sections `, description: `Try to log in again` }))
    // eslint-disable-next-line
  }, [])

  const checkAccess = useCallback(() => {
    if (user && user.audience) {
      let currentAudience = false
      for (let x = 0; x < user.audience.length; x++) {
        if (user.audience[x].currentAudience === true) {
          currentAudience = true
          break
        }
      }
      if (user.role === ROLES.ADMIN || user.role === ROLES.USER) {
        if (user.audience && currentAudience === true) {
          setOwner(user)
          setHasAccess(true)
          setIsLoading(false)
        } else {
          setOwner(user)
          setHasAccess(false)
          setIsLoading(false)
          return hasNoAudience()
        }
      } else {
        setHasAccess(false)
        setIsLoading(false)
        setError({
          title: 'You are not authorized to create a newsletter',
          description: 'Redirecting to the home page'
        })
        history.push('/')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    checkAccess()
    getSections()
    return () => {
      setIsLoading(false)
      setSections([])
      setResetForm(0)
      setIsSubmitting(false)
      setHasAccess(false)
      setOwner({})
    }
  }, [checkAccess, getSections])

  const hasNoAudience = () => {
    setError({ title: 'No audiences have been assigned to you', description: 'Redirecting you to the homepage' })
    history.push('/')
  }

  const cancelCreate = () => {
    setConfirmDialogSettings({
      handleClose: () => {
        setOpenConfirmDialog(false)
      },
      handleConfirm: async () => {
        try {
          setOpenConfirmDialog(false)
          setResetForm(prevResetForm => prevResetForm + 1)
          history.goBack()
        } catch (error) {
          setError(error)
        }
      },
      title: 'Discard changes',
      text: 'Changes will be discarded. Continue?'
    })
    setOpenConfirmDialog(true)
  }

  const createNewsletterHandler = newsletter => {
    setIsSubmitting(true)
    createNewsletter(newsletter)
      .then(res => {
        toaster('SUCCESS', 'Newsletter Created!')
        setIsSubmitting(false)
        props.history.push({
          pathname: `/editnewsletter/${res.data._id}`,
          state: { newsletter: res.data, user: owner , firstTimeCreated: true}
        })
      })
      .catch(() => setError({ title: 'Could not create the newsletter', description: 'Try logging in again' }))
  }

  const createNewsletterForm = () => {
    if (hasAccess) {
      return (
        <div className="text-center p-3 px-5">
          <h1 className="p-3 text-dark" data-cy="header">
            Create a Newsletter
          </h1>

          <NewsletterForm
            calledFromCreate={true}
            key={resetForm}
            isSubmitting={isSubmitting}
            returnFunction={createNewsletterHandler}
            handleCancel={cancelCreate}
            owner={owner}
            isDraft={true}
            sections={sections}
          />
        </div>
      )
    } else if (!hasAccess) {
      return (
        <div className="text-center p-3 px-5">
          <h1>No Access: Redirecting</h1>
        </div>
      )
    }
  }

  return <div className="container rounded newsletter">{isLoading ? <Loader /> : createNewsletterForm()}</div>
}

export default withRouter(CreateNewsletter)
