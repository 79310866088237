import React, { createContext, useState, useContext, useEffect } from 'react'

type ErrorContextProps = {
  error: any
  setError: React.Dispatch<any>
  errorMes: string
}

export const ErrorContext = createContext<ErrorContextProps>({} as ErrorContextProps)

/**
 * Error context for whole application
 *
 * Its state variables are:
 * @property {object|string|null} error - error object or just string message
 * */

const ErrorContextProvider = props => {
  const [error, setError] = useState(null)
  const [errorMes, setErrorMes] = useState('')
  useEffect(() => {
    if (error) {
      if (error.response && error.response.data) {
        setErrorMes(error.response.data)
      } else if (error.message) {
        setErrorMes(error.message)
      } else if (error.request) {
        setErrorMes(error.request)
      } else setErrorMes(error)
    }
  }, [error, setErrorMes])

  const errorContext = {
    error,
    setError,
    errorMes
  }

  return <ErrorContext.Provider value={errorContext}>{props.children}</ErrorContext.Provider>
}

function useError() {
  const context = useContext<ErrorContextProps>(ErrorContext)
  if (context === undefined) {
    throw new Error('useError must be used within a ErrorProvider')
  }
  return context
}

export { ErrorContextProvider, useError }
