import { AxiosPromise } from 'axios'
import { Article } from 'models/article'
import { AxiosWithToken } from './axios-helpers'

export type ArchiveModel = [{ boolean }, { Article }]

const ArticleAxios = AxiosWithToken(`${process.env.REACT_APP_API_URL}/article`)

export const getAllTags = (): AxiosPromise<string[]> => ArticleAxios.get('/allTags')

export const getTags = (): AxiosPromise<string[]> => ArticleAxios.get('/tags')

export const getClientTags = (): AxiosPromise<string[]> => ArticleAxios.get('/clientTags')

export const getBizTags = (): AxiosPromise<string[]> => ArticleAxios.get('/bizTags')

export const getLosTags = (): AxiosPromise<string[]> => ArticleAxios.get('/losTags')

export const getArticles = (
  page = 0,
  limit = 10,
  sort: {
    sortBy: '_id'
    orderBy: -1
  },
  filter = '',
  dateFrom = null,
  dateTo = null,
  ownerId = '',
  withoutNewsletters = false
): AxiosPromise<Article[]> =>
  ArticleAxios.get(
    `/?page=${page}&limit=${limit}&sortBy=${sort.sortBy}&orderBy=${sort.orderBy}&filter=${filter}&dateFrom=${dateFrom}&dateTo=${dateTo}&ownerId=${ownerId}&withoutNewsletters=${withoutNewsletters}`
  )

export const getArticlesByIds = (ids: string[]) => ArticleAxios.get(`/`, { params: { ids } })

export const getArticle = (id: string): AxiosPromise<Article> => ArticleAxios.get(`/${id}`)

export const getArticlesByTag = (tag: string): AxiosPromise<Article[]> => ArticleAxios.get(`/tag/${tag}`)

export const publishArticle = (article: Article): AxiosPromise<Article> => ArticleAxios.post('/publish', article)

export const cloneArticle = (id: Pick<Article, '_id'>): AxiosPromise<Article> => ArticleAxios.post(`/clone/${id}`)

export const updateArticle = (article: Article): AxiosPromise<string> =>
  ArticleAxios.post(`/update/${article._id}`, article)

export const deleteArticle = (articleId): AxiosPromise<string> => ArticleAxios.delete(`delete/${articleId}`)

export const setArticlePublished = (articleId): AxiosPromise<Article> =>
  ArticleAxios.patch(`edit/${articleId}`, { status: 'published' })

/**
 * @deprecated
 */
export const reorderArticles = (articles: Article[]): AxiosPromise<Article[]> =>
  ArticleAxios.post('/reorder', { articles: [...articles] })

export const getArticlesByArticleIds = (articleIds: string[]): AxiosPromise<Article[]> =>
  ArticleAxios.get(`articles/?articles=${articleIds}`)

export const getArticleByTagVolumeIssue = (tag: string, volume: string, issue: string): AxiosPromise<ArchiveModel> =>
  ArticleAxios.get(`/${tag}/${volume}/${issue}`)

export const getArticleByTagUnassigned = (tag: string): AxiosPromise<ArchiveModel> =>
  ArticleAxios.get(`/${tag}/unassigned`)
