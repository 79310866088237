import React, { useContext } from 'react'
import './Home.css'
import { UserContext } from 'context/user-context'
import { Footer } from '../Footer/Footer'
import { Body } from '../Body/Body'


export const Home = () => {
  const { user } = useContext(UserContext)

    return (
        <div className='background'>
            <div className='components-container'>
                <Body user={user} />
                <Footer user={user} />
            </div>
        </div>
    )
}
