import React from 'react'
import { Newsletters } from 'components'

/**
 * Used to display all newsletters.
 */
const AllNewsletters = () => {
  return <Newsletters showAll={true} />
}

export default AllNewsletters
