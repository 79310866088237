import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from '@material-ui/core'
import ArticleComponent from 'components/article/article.component'
import toaster from '../../toast/toast.component'
import './preview-newsletter.component.css'
import { setArticlePublished } from 'apis/article.service'

export default class PreviewNewsletter extends Component {
  /**
   * creates an array of the sections for the newsletter then kicks off
   * the construction of the newsletter by calling createSections
   */
  constructPreview = () => {
    if (this.props.sections) {
      return this.createSections(this.props.sections, this.props.articles)
    }
    const articles = this.props.articles
    const sectionSet = []
    articles.forEach(article => {
      if (!sectionSet.includes(article.section)) {
        sectionSet.push(article.section)
      }
    })
    return this.createSections(sectionSet, articles)
  }
  /**
   * makes the sections of the newsletter based on sectionSet array
   */
  createSections = (sectionSet, articles) => {
    return sectionSet.map(section => {
      if (articles.some(article => article.section === section)) {
        return (
          <React.Fragment key={section.value}>
            {this.createSectionHeader(section ? section : '')}
            {this.createArticles(section, articles)}
          </React.Fragment>
        )
      }
      return null
    })
  }

  /**
   * determines when to add another section header to the newsletter
   */
  createSectionHeader = section => {
    if (section !== this.curSection) {
      this.curSection = section
      return (
        <div style={{ textAlign: 'center' }}>
          <h2 style={{ color: '#5480C0', fontSize: 24 }}>{section}</h2>
          <br />
        </div>
      )
    }
  }

  /**
   * creates articles for each article belonging to current section
   */
  createArticles = (section, articles) => {
    return articles.map(currentarticle => {
      if (currentarticle.section === section) {
        return (
          <div key={currentarticle._id}>
            <div>
              <React.Fragment key={currentarticle._id}>
                <ArticleComponent article={currentarticle} />
              </React.Fragment>
            </div>
            <hr />
            <br />
          </div>
        )
      }
      return null
    })
  }

  /**
   * Selects everything within modal, copies it to clipboard
   */

  handleCopyNewsletterClick = async () => {
    const confirmPublish = window.confirm(
      "Would you like to set all articles in this Newsletter to a status of 'Published'?" +
        ' Select ok to update status and cancel to continue without changing the status.'
    )
    if (confirmPublish) {
      this.updateArticlesToPublished()
    }
    const newsletterToBeCopied = document.getElementsByClassName('panel-body')[0]
    toaster('SUCCESS', 'Copied Newsletter to clipboard!')
    return await navigator.clipboard.writeText(newsletterToBeCopied.innerText)
  }

  updateArticlesToPublished = () => {
    this.props.articles.forEach(article => {
      setArticlePublished(article._id)
    })
  }
  render() {
    return (
      <>
        <Modal show={this.props.show} backdrop="static">
          <Modal.Body>
            <div className="panel-body">{this.constructPreview()}</div>
            <br />
            <Button onClick={this.props.handleCancel} className="btn close-button">
              Close
            </Button>
            <Button
              onClick={e => this.handleCopyNewsletterClick(e)}
              className="btn copy-button"
              hidden={this.props.hidden}
            >
              Copy Newsletter
            </Button>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}
