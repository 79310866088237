import toaster from 'components/toast/toast.component'
import React from 'react'

const HTTP_FORBIDDEN = 403

//maybe rename? can be used other places
export const handleGettingArticleError = error => {
  console.error(JSON.stringify(error))

  //check undefined before running isAuthError() to avoid a potential second error
  if (error.response !== undefined && isAuthError(error)) {
    const loggedOutContent = (
      <>
        <p>The session token has expired.</p>
        <a href={process.env.REACT_APP_SSO_URL}>Click here to log back in</a>
      </>
    )

    toaster('LOGGED_OUT', () => loggedOutContent)
  } else {
    toaster('ERROR', 'Something went wrong.')
  }
}

export const handleNewsletterUpdateAndPublishError = error => {
  console.error(error.message)

  // error handling template for whatever code you expect
  if (error.message.includes('413')) {
    // payload too big
    toaster('ERROR', 'Selected image is too large!')
  } else {
    // default
    toaster('ERROR', 'Something went wrong adding this post!')
  }
}

//error.response.status === HTTP_FORBIDDEN
const isAuthError = error => {
  if (error.response.status) {
    return error.response.status === HTTP_FORBIDDEN
  }
  return false
}

export const handleArticleUpdateAndPublishError = error => {
  console.error(error.message)
  // error handling template for whatever code you expect
  if (error.message.includes('413')) {
    // payload too big
    toaster('ERROR', 'Selected image is too large!')
  } else {
    // default
    toaster('ERROR', 'Something went wrong adding this post!')
  }
}
