import styled from 'styled-components'

export const Jumbotron = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.369);
  text-align: center;
  padding: 0px 32px 128px 32px;
  && .jumbo-search {
    width: 480px;
    margin-top: 24px;
  }
  @media (max-width: 729px) {
    && .jumbo-search {
      width: 100%;
    }
  }
`
export const Title = styled.h1`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 62px;
  line-height: 76px;
  text-align: center;
  color: #fff;
  margin: 71px 0 0 0;
  && em {
    font-style: italic;
    font-weight: 700;
  }
  @media (max-width: 729px) {
    margin: 16px 0 0 0;
  }
`

export const Subtitle = styled.h3`
  margin: 10px 0 0 0;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.24px;
  color: #fff;
`
