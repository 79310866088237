import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react'

type ConfirmDialogSettings = {
  handleClose: () => void
  handleConfirm: () => void
  text: string
  title: string
  cancelText?: string
  submitText?: string
}

type DialogContextProps = {
  openConfirmDialog: boolean
  confirmDialogSettings: ConfirmDialogSettings
  setOpenConfirmDialog: Dispatch<SetStateAction<boolean>>
  setConfirmDialogSettings: Dispatch<SetStateAction<ConfirmDialogSettings>>
}

type ProviderProps = { children?: JSX.Element | JSX.Element[] }

export const ConfirmDialogContext = createContext({} as DialogContextProps)

const ConfirmDialogProvider = ({ children }: ProviderProps) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [confirmDialogSettings, setConfirmDialogSettings] = useState<ConfirmDialogSettings>({} as ConfirmDialogSettings)

  const confirmDialogContext = {
    openConfirmDialog,
    confirmDialogSettings,
    setOpenConfirmDialog,
    setConfirmDialogSettings
  } as DialogContextProps

  return <ConfirmDialogContext.Provider value={confirmDialogContext}>{children}</ConfirmDialogContext.Provider>
}

const useConfirmDialog = (): DialogContextProps => {
  const context = useContext(ConfirmDialogContext)
  if (context === undefined) {
    throw new Error('useCount must be used within a CountProvider')
  }
  return context
}

export { ConfirmDialogProvider, useConfirmDialog }
