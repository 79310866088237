import React, { memo, useEffect, useState } from 'react'
import Select from 'react-select'
import { handleGettingArticleError } from 'utils/errorHandling'
import { getAllSections } from 'apis/section.service'
import { mapStringsToSelectOptions } from 'utils/mapStringsToSelectOptions'

const Section = props => {
  const [sectionOptions, setSectionOptions] = useState([])

  const getSections = () => {
    getAllSections().then(response => {
      try {
        const sectionArray = response.data.map(section => section.sectionName)
        setSectionOptions(mapStringsToSelectOptions(sectionArray))
      } catch (error) {
        handleGettingArticleError(error)
      }
    })
  }
  useEffect(() => getSections(), [])
  return (
    <div className="form-group">
      <label>Section: (Optional)</label>
      <Select
        id="section"
        isClearable
        options={sectionOptions}
        onChange={option => props.handleChange(prevState => ({ ...prevState, section: option?.value ?? '' }))}
        value={{ value: props.section, label: props.section }}
      />
    </div>
  )
}

export default memo(Section)
