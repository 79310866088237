import React, { useState } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import './../update-usermodal.component.css'
import { Chip } from '@material-ui/core'
import '../../FontStyle.css'
import { Button } from 'react-bootstrap'
import InfoIcon from '@material-ui/icons/Info'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 15,
    maxWidth: 'none'
  }
}))(Tooltip)

const AddUserModal = props => {
  const [currentAudienceIndex, setCurrentAudienceIndex] = useState()
  const [currentUserAudience, setCurrentUserAudience] = useState([])

  const cancelInterceptor = () => {
    props.setShowModal(false)
    setCurrentUserAudience([])
    setCurrentAudienceIndex()
  }

  const handleSubmitAudience = () => {
    if (isNaN(currentAudienceIndex)) {
      alert('Please select an audience')
      return
    }

    const audienceId = props.audiences[currentAudienceIndex]._id
    if (currentUserAudience.filter(audience => audience._id === audienceId && audience.currentAudience).length) {
      alert('You may not have duplicate audiences for a user.')
      return
    }

    setCurrentUserAudience(currentUserAudienceArray => [
      ...currentUserAudienceArray,
      { ...props.audiences[currentAudienceIndex], currentAudience: true }
    ])
  }

  const renderSelectedAudience = () => {
    if (currentUserAudience.length > 0) {
      return currentUserAudience.map((tag, index) => (
        <React.Fragment key={index}>
          <Chip className="audience-chip" label={tag.name} onDelete={() => handleDelete(tag.name)} value={tag.name} />
        </React.Fragment>
      ))
    }
    return (
      <React.Fragment>
        <p className="audience">No Audience Selected</p>
      </React.Fragment>
    )
  }

  const handleDelete = audienceName => {
    setCurrentUserAudience(currentUserAudience.filter(audience => audience.name !== audienceName))
  }

  const handleCloseOnSubmit = async () => {
    const userName = document.getElementById('userName').value
    const userRole = document.getElementById('userRole').value
    const userId = document.getElementById('userId').value
    const userLocation = document.getElementById('userLocation').value

    if (userName === '') {
      alert("Please enter user's name")
    } else if (userRole === '') {
      alert('Please select a role')
    } else {
      try {
        const response = await props.handleUserValidation(
          userId.trim() + userLocation.trim(),
          userName,
          userRole,
          currentUserAudience
        )

        if (response && response.status === 201) {
          setCurrentUserAudience([])
          setCurrentAudienceIndex()
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  return (
    <Modal show={props.showModal} onHide={cancelInterceptor} enforceFocus={false} backdrop="static">
      <Modal.Header>
        <Modal.Title>Add New User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label>User ID:</label>
          <LightTooltip
            title="Field requires Domain ID (ie. JXD1201) if user is John Doe with December 1st birthday."
            place="right"
            data-multiline="true"
          >
            <InfoIcon fontSize="small" />
          </LightTooltip>
          <br />
          <Row>
            <Col>
              <input className="form-control" type="text" name="title" id="userId" />
            </Col>
            <Col>
              <select className="form-control" defaultValue="@daugherty.com" id="userLocation">
                <option key="@daugherty.com" value="@daugherty.com">
                  @daugherty.com
                </option>
                <option key="@daugherty.pl" value="@daugherty.pl">
                  @daugherty.pl
                </option>
                <option key="@daugherty.pa" value="@daugherty.pa">
                  @daugherty.pa
                </option>
              </select>
            </Col>
          </Row>
        </div>
        <div className="form-group">
          <label>User Name:</label>
          <LightTooltip
            title="Display name visible within application (ie. John Doe)."
            place="right"
            data-multiline="true"
          >
            <InfoIcon fontSize="small" />
          </LightTooltip>
          <br />
          <input className="form-control" type="text" id="userName"></input>
        </div>

        <div className="form-group">
          <label className="label">Role:</label>
          <LightTooltip
            title={
              <React.Fragment>
                <p>
                  {' '}
                  <b>Admin</b>: CRUD newsletters and assign roles, and view all articles. <br />
                  <b>User</b>: CRUD newsletters and publish articles. <br />
                  <b>CRUD</b>: C='Create', R='Read', U='Update', D='Delete'{' '}
                </p>
              </React.Fragment>
            }
            place="right"
            data-multiline="true"
          >
            <InfoIcon fontSize="small" />
          </LightTooltip>
          <select className="form-control" defaultValue={''} id="userRole">
            <option key="default" value="" disabled>
              Select an Option
            </option>
            <option key="admin" value="admin">
              admin
            </option>
            <option key="user" value="user">
              user
            </option>
          </select>
        </div>
        <div>
          <label className="label">Add Audience: </label>
          <LightTooltip
            title="You may select multiple audiences for a user. After selecting audience press 'Submit Audience' "
            place="right"
            data-multiline="true"
          >
            <InfoIcon fontSize="small" />
          </LightTooltip>
          <select
            className="form-control"
            data-cy="audience-select"
            defaultValue={''}
            onChange={e => setCurrentAudienceIndex(e.target.value)}
          >
            <option key="default" value="" disabled>
              Select an Option
            </option>
            {props.audiences.map((audience, index) => (
              <option key={audience.name} value={index}>
                {audience.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mr-auto submit-button">
          <Button variant="primary" onClick={handleSubmitAudience}>
            Submit Audience
          </Button>
        </div>
        <div className="audience-list">
          <h2>User Audience(s):</h2>
          <div className="chip">{renderSelectedAudience()}</div>
        </div>
        <div className="button-container">
          <Button variant="primary" onClick={handleCloseOnSubmit}>
            Add User
          </Button>
          <Button variant="secondary" onClick={cancelInterceptor}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default AddUserModal
