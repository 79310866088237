import React from 'react'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'

const CustomToggle = ({ className, children, eventKey, onClickHandler }) => {
  const decoratedOnClick = useAccordionButton(eventKey, onClickHandler)

  return (
    <div className={className} onClick={decoratedOnClick}>
      {children}
    </div>
  )
}

export default CustomToggle
