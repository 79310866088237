import React, { useEffect } from 'react'
import { Card, CardContent, makeStyles, Typography, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  welcomeHeader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    color: 'white'
  },
  customTextField: {
    '& label.Mui-focused': {
      color: '#538b3f'
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#538b3f'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#538b3f'
      }
    },
    marginBottom: '2rem',
    maxWidth: '600px',
    alignSelf: 'center',
    width: '-webkit-fill-available'
  },
  loginCardContainer: {
    marginTop: '1rem',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center'
  },
  loginCard: {
    paddingLeft: '2.5rem',
    paddingRight: '2.5rem',
    paddingTop: '1rem',
    maxWidth: '47.5rem',
    width: '-webkit-fill-available'
  },
  loginContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center'
  },
  customButton: {
    maxWidth: '25rem',
    alignSelf: 'center',
    width: '-webkit-fill-available',
    color: 'white',
    fontWeight: '600'
  }
})

/** Allows user to log in to application */
const LoginComponent = ({ isAuthenticated, login }) => {
  const history = useHistory()
  const classes = useStyles()

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/')
    }
  }, [isAuthenticated, history])

  const onSubmit = event => {
    event.preventDefault()
    login()
  }

  return (
    <>
      <Card id="intro" style={{ backgroundColor: '#538b3f' }}>
        <CardContent>
          <Typography id="home-header" variant="h3" className={classes.welcomeHeader}>
            Welcome to Daugherty Newsletter Network
          </Typography>
          <Typography id="description" variant="body1" style={{ color: 'white' }}>
            An application that can aid delivering informative weekly newsletters to each Daugherty LOS, on time, while
            also being able to track reader engagement. This would provide valuable insight into what readers deem as
            interesting and relevant information, specific to them individually, in the ways that the analytics gathered
            by the application reflects that they prefer to be delivered.
          </Typography>
        </CardContent>
      </Card>
      <form onSubmit={onSubmit} className={classes.loginCardContainer}>
        <Card className={classes.loginCard}>
          <CardContent className={classes.loginContent}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <Button
                type="submit"
                variant="contained"
                className={classes.customButton}
                style={{ backgroundColor: '#538b3f' }}
              >
                Login
              </Button>
            </div>
          </CardContent>
        </Card>
      </form>
    </>
  )
}

LoginComponent.propTypes = {
  isAuthenticated: PropTypes.bool,
  login: PropTypes.func
}

export default LoginComponent
