import React, { FormEvent, useCallback, useContext, useEffect, useState } from 'react'
import './article-form.component.css'
import { handleArticleUpdateAndPublishError } from 'utils/errorHandling'
import Tag from './controls/tag.component'
import Title from './controls/title.component'
import ContentEditor from './controls/content-editor.component'
import Section from './controls/section.component'
import ArticleStatus from './controls/article-status.component'
import SaveControls from './controls/save-controls.component'
import { getArticlesByArticleIds, publishArticle, updateArticle } from 'apis/article.service'
import { getImageById } from 'apis/image.service'
import ArticleToNewsletter from './controls/article-newsletter.component'
import { updateNewsletter, getNewslettersValid } from 'apis/newsletter.service'
import toaster from 'components/toast/toast.component'
import { UserContext } from 'context/user-context'
import { mapStringsToSelectOptions } from 'utils/mapStringsToSelectOptions'
import Preview from 'components/preview/preview.component'
import { useHistory } from 'react-router-dom'
import { getVideoId } from 'apis/vimeo.service'
import { Save } from '@material-ui/icons'
import { useError } from 'context/error-context'
import { SIZE_CONSTANTS, validateArticleSize, objectsAreEqual } from '../utils/article-utils'
import { NEWSLETTER_STATUSES } from '../../../consts'
import Loader from '../../loader/loader.component'
import { ARTICLE_STATUSES } from 'consts'
import { Article } from 'models/article'
import { Newsletter } from 'models/newsletter'

type ArticleFormProps = {
  article: Article
  defaultArticle?: Article
  setArticle: (args: any) => void
  isSubmitting: boolean
  autoSave: boolean
  isCreating?: boolean
  isFromNewsletter?: boolean
  showAll?: boolean
  handleSubmit: (args: any) => void
  handleCancel: () => void
  handleDelete?: () => void
  toogleShowCreateArticleModal?: () => void,
  setNewArticleCreated: (args: any)=> void
}

const ArticleForm = ({
  article,
  setArticle,
  isSubmitting,
  handleSubmit,
  handleCancel,
  autoSave,
  handleDelete,
  defaultArticle = {} as Article,
  isCreating = false,
  showAll = false,
  isFromNewsletter,
  toogleShowCreateArticleModal,
  setNewArticleCreated
}: ArticleFormProps): JSX.Element => {
  const { user } = useContext(UserContext)
  const [imageFiles, setImageFiles] = useState(article.imgs || [])
  const history = useHistory()
  const [newsletters, setNewsletters] = useState<Newsletter[]>([])
  const [selectedNewsletterId, setSelectedNewsletterId] = useState<string>()
  // eslint-disable-next-line
  const [defaultNewsletterId, setDefaultNewsletterId] = useState<string>()
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [loadingImages, setLoadingImages] = useState(false)
  const [loadingNewsletters, setLoadingNewsletters] = useState(false)
  const { setError } = useError()


  const saveArticle = useCallback(async () => {
    let response
    try {
      if (isCreating) {
        response = await publishArticle({ ...article });
        toaster('SUCCESS', 'Article saved!');
        (isFromNewsletter) ? setNewArticleCreated(response.data) : history.push("/myarticles");
      } else {
        response = await updateArticle({ ...article })
        toaster('SUCCESS', 'Article Updated!')
      }
      return response.data
    } catch (error) {
      handleArticleUpdateAndPublishError(error)
    }
  }, [article, isCreating, setNewArticleCreated, isFromNewsletter, history])

  useEffect(() => {
    const getImages = async () => {
      if (article.imgs && article.imgs.length && !article.imgs.filter(img => img.split(':')[0] === 'data').length) {
        try {
          const imagePromises = article.imgs.map(img => getImageById(img))
          setLoadingImages(true)

          const allImages = (await Promise.all(imagePromises)).map(image => image.data)
          setImageFiles(allImages)
        } catch (error) {
          setError(error)
        }
        setLoadingImages(false)
      }
    }
    getImages()
  }, [setArticle, article.imgs, setError])

  useEffect(() => {
    if (user && user.audience.length > 0) {
      const usersAudiences = user.audience.map(audience => audience._id)
      setLoadingNewsletters(true)
      const getNewslettersByAudience = async () => {
        try {
          const { data } = await getNewslettersValid({
            audience: usersAudiences,
            statuses: [NEWSLETTER_STATUSES.DRAFT, NEWSLETTER_STATUSES.EDITED]
          })
          if (data !== 'Insufficient Permissions') {
            const newsletterData = data as Newsletter[]
            setNewsletters(newsletterData)
          } 
        } catch (error) {
          setError(error)
        } finally {
          setLoadingNewsletters(false)
        }
      }
      getNewslettersByAudience()
    }
  }, [user, article._id, setError])

  const saveNewsletter = async (newsletter: Newsletter, isNew: boolean, newArticle: Article = null) => {
    const articles = isNew
      ? [...newsletter.articles, newArticle._id]
      : newsletter.articles.filter(item => item !== article._id)

    const sections = isNew
      ? newsletter.sections.includes(article.section)
        ? [...newsletter.sections]
        : [...newsletter.sections, article.section]
      : await getArticlesByArticleIds(articles)
          .then(res => {
            return [...new Set(res.data.map(a => a.section))]
          })
          .catch(error => setError(error))

    updateNewsletter(newsletter._id, {
      articles: articles,
      sections: sections as string[],
      title: newsletter.title,
      audience: newsletter.audience,
      datePublished: newsletter.datePublished
    }).catch(error => {
      setError(error)
      toaster('ERROR', `Newsletter failed to save: ${error}`)
    })
  }

 

  const handleSubmitForm = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (validateArticleSize({ ...article }) === SIZE_CONSTANTS.MESSAGES.NOT_VALID) {
      toaster('ERROR', `${SIZE_CONSTANTS.MESSAGES.NOT_VALID}`)
      return
    }

    if (article?.title.trim() === '') {
      toaster('ERROR', `The article title is required.`)
      return
    }

    if ((!objectsAreEqual(defaultArticle, article) || defaultNewsletterId !== selectedNewsletterId) && !isCreating) {
      article.status = ARTICLE_STATUSES.EDITED
    }

    handleSubmit(article)

    if (article.title) {
      const newArticle = await saveArticle()
      if (defaultNewsletterId !== selectedNewsletterId) {
        await saveNewsletter(
          newsletters.find(newsletter => newsletter._id === selectedNewsletterId),
          true,
          newArticle
        )
        if (defaultNewsletterId) {
          await saveNewsletter(
            newsletters.find(newsletter => newsletter._id === defaultNewsletterId),
            false,
            newArticle
          )
        }
      }
      if (isFromNewsletter) {
        toogleShowCreateArticleModal()
      }
      if (showAll) {
          history.push('/myarticles')
      }
    }
  }

  const togglePreviewModal = () => {
    setShowPreviewModal(prevState => !prevState)
  }

  const handleVideoThumbnails = async (articleClone: Element) => {
    const articleCopy = articleClone.cloneNode(true) as HTMLElement
    for await (const iframe of Array.from(articleCopy.getElementsByTagName('iframe'))) {
      if (
        iframe.src.substring(0, 24) === 'https://player.vimeo.com' ||
        iframe.src.substring(0, 17) === 'https://vimeo.com'
      ) {
        const videoInfo = await getVideoId(iframe.src)
        const { width, height, thumbnail_url_with_play_button } = videoInfo.data as {
          width: number
          height: number
          thumbnail_url_with_play_button: string
        }
        const link = document.createElement('a')
        link.href = iframe.src
        const img = document.createElement('img')
        img.width = width
        img.height = height
        img.src = thumbnail_url_with_play_button
        link.appendChild(img)
        iframe.replaceWith(link)
      } else if (iframe.src.substring(0, 23) === 'https://www.youtube.com') {
        const link = document.createElement('a')
        link.href = iframe.src
        const img = document.createElement('img')
        const url = new URL(iframe.src)
        const pathName = url.pathname
        img.src = `https://i.ytimg.com/vi/${pathName.replace('/embed/', '')}/hqdefault.jpg`
        link.appendChild(img)
        iframe.replaceWith(link)
      }
    }
    return articleCopy
  }

  const handleCopyArticleClick = async () => {
    window.getSelection().removeAllRanges()
    const originalArticle = document.getElementsByClassName('article-content-nl')[0]

    const contentDiv = document.createElement('div')
    contentDiv.id = 'articleClone'
    const articleClone = await handleVideoThumbnails(originalArticle)
    contentDiv.append(articleClone)

    originalArticle.append(contentDiv)
    const selection = window.getSelection()
    const range = document.createRange()
    range.selectNodeContents(document.getElementById('articleClone'))
    selection.addRange(range)
    document.execCommand('copy')

    toaster('SUCCESS', 'Copied Article to clipboard!')
    window.getSelection().removeAllRanges()
    document.getElementById('articleClone').remove()
  }

  return (
    <form onSubmit={handleSubmitForm} className="container text-left p-4 rounded" id="form-container">
      <ArticleStatus status={article.status} />
      <Tag handleChange={setArticle} tags={mapStringsToSelectOptions(article.tags)} />
      <Section handleChange={setArticle} section={article.section} />
      <Title handleChange={setArticle} title={article.title} />
      {loadingImages ? (
        <Loader id="articleFormLoadingImages" />
      ) : (
        <ContentEditor handleChange={setArticle} content={article.content} images={imageFiles || []} />
      )}
      
      <ArticleToNewsletter
        role={user.role}
        loadingNewsletters={loadingNewsletters}
        newsletters={newsletters}
        defaultNewsletterId={defaultNewsletterId}
        handleNewsletterChange={setSelectedNewsletterId}
      />
      {showPreviewModal && (
        <Preview
          show={showPreviewModal}
          articles={[article]}
          handleCancel={togglePreviewModal}
          buttons={[{ name: 'Copy Article', icon: <Save />, handler: handleCopyArticleClick }]}
        />
      )}
      <SaveControls
        handleDelete={handleDelete}
        isSubmitting={isSubmitting}
        handleCancel={handleCancel}
        autoSave={autoSave}
        togglePreviewModal={togglePreviewModal}
        status={article.status}
      />
    </form>
  )
}

export default ArticleForm
